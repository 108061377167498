 	import React, { useRef } from 'react'
import ReCaptchaV2 from 'react-google-recaptcha'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'

import Widget from '../../../../types/Widget'
import { IButtonSettings } from '../../../../models/ButtonSettings'
import { useHandleAction } from '../../../../lib/widgets/useButtonAction'
import { Toast } from '../../../../helpers/Toast'
import { submitButtonState } from '../../../../stores/widgets/buttons/submit'
import { useTemplate } from '../../../Templates/Custom/CustomTemplate'
import { rewardState } from '../../../../stores/widgets/game/state'
import { liveCampaignState } from '../../../../stores/campaign/live/state'
import { claimReward } from '../../../../lib/reward/claimReward'
import ButtonSpinner from '../../../ButtonSpinner'
import { clearFormFields } from '../../../../lib/forms/clearFormFields'
import { formFieldCheckRequired } from '../../../../lib/forms/formFieldCheckRequired'
import { formFileUpload } from '../../../../lib/forms/formFileUpload'
import { submitForm } from '../../../../lib/forms/submitForm'
import { handleRecaptchaToken } from '../../../../lib/recaptcha/handleRecaptchaToken'
import { useFormContext } from '../../Form/FormContainer/FormContainer'
import { sendFormEmailPost } from '../../../../lib/email/sendFormEmailPost'
import { IFormEmail } from '../../../../models/Forms/FormEmail'
import { returnRewardHTML } from '../../../../lib/email/returnRewardHTML'
import { updateRewardFields } from '../../../../lib/reward/updateRewardFields'
import {
    FormFields,
    widgetEmailState,
    widgetFormState,
} from '../../../../stores/widgets/forms/state'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget: Widget
    action: IButtonSettings
    widgetName: string
    onEditMode: (e) => void
    isActiveButton: boolean
    isPreview: boolean
}

const SubmitButton = ({
    widget,
    action,
    widgetName,
    onEditMode,
    isActiveButton,
    isPreview,
}: Props) => {
    const { formId } = useFormContext()
    const { uid, campaignId } = useTemplate()
    const recaptchaRef = useRef(null)
    const handleAction = useHandleAction(action)
    const campaign = useRecoilValue(liveCampaignState)
    const [formState, setFormState] = useRecoilState(widgetFormState(formId))
    const email = useRecoilValue(widgetEmailState(formId))
    const [reward, setReward] = useRecoilState(rewardState(widget.settings.gameId))
    const [loading, setLoading] = useRecoilState(submitButtonState(widget.id))

    let emailSettings: IFormEmail = { ...widget.settings.email }

    let appearanceSettings = widget.appearanceSettings
    let colourSettings = widget.colourSettings

    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget.settings,
        }
    }

    if (!colourSettings) {
        colourSettings = {
            'text colour': widget.settings.textColour,
            'button colour': widget.settings.buttonColour,
            'border colour': widget.settings.borderColour,
        }
    }

    const width = appearanceSettings.width + '%'
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontSize = appearanceSettings ? appearanceSettings.font.size + 'px' : '14px'
    const fontUrl = appearanceSettings?.font?.url

    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'

    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`

    const color = Array.isArray(colourSettings['text colour'])
        ? colourSettings['text colour'][0]
        : colourSettings['text colour']
    const backgroundColor = Array.isArray(colourSettings['button colour'])
        ? colourSettings['button colour'][0]
        : colourSettings['button colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']
    const weight = appearanceSettings?.font?.weight

    const handleSubmit = async (formState: FormFields): Promise<void> => {
        setLoading(true)

        try {
            //
            // Recaptcha stopped working. Maybe some had the API key in their personal account and they deleted it?
            // Remove recaptcha for now.
            //
            // await handleRecaptchaToken(recaptchaRef, formState.formId)

            const errors = formFieldCheckRequired(formState)

            if (errors.length > 0) {
                errors.forEach((error) => {
                    Toast(error, 'error')
                })
                setLoading(false)
                return
            }

            await handleRecaptchaToken(recaptchaRef, formState.formId)

            let filesToUpload = []

            formState.fields.map((field) => {
                // check if is file upload type - upload separately
                if (field.fieldType === 'upload') {
                    filesToUpload.push({
                        fieldName: field.fieldName,
                        files: field.value.files,
                        fileType: field.value.fileType,
                    })
                }
            })

            let userId = campaign.uid
            if (!userId) {
                userId = campaign.creatorUID
            }

            const uploadData = await formFileUpload(
                filesToUpload,
                userId,
                campaignId,
                formState.formId
            )

            if (filesToUpload.length > 0 && uploadData.length === 0) {
                Toast('Please select file.', 'error', widget.id)
                setLoading(false)
                return
            }

            let fields = formState.fields

            uploadData.forEach((data) => {
                const index = formState.fields.findIndex(
                    (field) => field.fieldName === data.fieldName
                )

                if (index === -1) {
                    return
                }

                const newField = { ...formState.fields[index], value: data.data }

                fields = [
                    ...formState.fields.slice(0, index),
                    newField,
                    ...formState.fields.slice(index + 1),
                ]
            })

            if (widget.settings?.actions?.isClaimPrize) {
                if (
                    !reward ||
                    Object.keys(reward).length === 0 ||
                    reward.reward.allocatedQuantity === 0
                ) {
                    resetFormState()
                    setLoading(false)
                    Toast('Unable to submit form due to no reward.', 'error', widget.id)
                    return
                }

                let rewardResult = await claimReward(
                    campaignId,
                    campaign.campaignName,
                    widget.settings,
                    reward.reward
                )

                if (rewardResult.message === 'success') {
                    const updatedFields = updateRewardFields(rewardResult, fields)

                    fields = updatedFields

                    const rewardHTMLContent = returnRewardHTML(rewardResult)

                    emailSettings = {
                        ...emailSettings,
                        content: emailSettings.content + rewardHTMLContent,
                        submissionId: rewardResult.data._id,
                    }
                } else {
                    setReward(null)
                    resetFormState()
                    setLoading(false)

                    if (rewardResult.message === 'no more prize') {
                        Toast('Your reward can no longer be claimed.', 'error', widget.id)
                    }
                    return
                }
            }

            await submitForm(fields, userId, campaignId, formState.formId, formState.formName)

            if (widget.settings.actions && widget.settings.actions.isSendEmail) {
                if (!email || email === '') {
                    throw new Error('Please provide valid email')
                }

                await sendFormEmailPost(emailSettings)
            }

            resetFormState()
            setLoading(false)
            Toast(widget.settings.toastMessage || 'Form submitted!', 'success', widget.id)

            handleAction.action()
        } catch (error) {
            resetFormState()
            setLoading(false)
            Toast(error.message, 'error', widget.id)
        }
    }

    const resetFormState = (): void => {
        recaptchaRef.current.reset()
        const emptyFields = clearFormFields(formState.fields)
        setFormState({
            ...formState,
            fields: emptyFields,
        })
        setReward(null)
    }

    const handleClickOn = () => {
        handleSubmit(formState)
    }

    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    return (
        <Container
            isActiveButton={isActiveButton}
            width={width}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            borderTopLeftRadius={borderTopLeftRadius}
            borderBottomLeftRadius={borderBottomLeftRadius}
            borderTopRightRadius={borderTopRightRadius}
            borderBottomRightRadius={borderBottomRightRadius}
            onClick={onEditMode}
            isPreview={isPreview}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      * {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <ReCaptchaV2
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LcqQH8cAAAAAF47PggfN8nbSBURCxKXtxM2q8UW"
            />
            <button
                type="button"
                onClick={handleClickOn}
                style={{
                    paddingTop,
                    paddingBottom,
                    paddingLeft,
                    paddingRight,
                    fontFamily,
                    fontWeight: weight,
                    backgroundColor,
                    color,
                    margin: `0 0`,
                    borderWidth,
                    borderColor,
                    fontSize,
                    letterSpacing,
                    lineHeight,
                }}
                className="focus:outline-none"
            >
                {loading ? (
                    <span className="flex items-center justify-center ">
                        <ButtonSpinner color={color} height="1.5em" width="1.5em" />
                        <p className="ml-4">Submitting Details...</p>
                    </span>
                ) : (
                    action.linkTitle
                )}
            </button>
            {isPreview && <EditingIndicator widgetName={widgetName} />}
        </Container>
    )
}

export default SubmitButton

const Container = styled.div.attrs((props) => ({}))<{
    isActiveButton: boolean
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    isPreview: boolean
}>`
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: ${(props) => props.width};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    button {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActiveButton && '1px solid rgb(58, 226, 155)'};
        }
    }

    .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActiveButton ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActiveButton ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
