import { IWidget } from '../../models/Widget'
import FormContainer from '../../models/Forms/FormContainer'
import Spinwheel from '../../models/Games/Spinwheel'
import Scratchcard from '../../models/Games/Scratchcard'
import Flipcard from '../../models/Games/Flipcard'

export const addAdditionalWidgets = (
    currentList: IWidget[],
    addItem: { type: string; insertIndex: number }
): IWidget[] => {
    let newList: IWidget[] = currentList
    const colorSettings = {
        'border colour': ['rgba(0,0,0,0)', '#FFA7E1', '#0426FF'],
        'background colour': ['rgba(0,0,0,0)', '#FFA7E1', '#0426FF'],
    }

    if (addItem.type === 'spinwheel') {
        const form = new FormContainer()
        ;(form.widgets = [new Spinwheel()]),
            (form.colourSettings = colorSettings),
            (form.appearanceSettings = { ...form.appearanceSettings, width: 100 })

        newList = [
            ...currentList.slice(0, addItem.insertIndex),
            form,
            ...currentList.slice(addItem.insertIndex + 1),
        ]
    }

    if (addItem.type === 'scratchcard') {
        const form = new FormContainer()
        ;(form.widgets = [new Scratchcard()]), (form.colourSettings = colorSettings)

        newList = [
            ...currentList.slice(0, addItem.insertIndex),
            form,
            ...currentList.slice(addItem.insertIndex + 1),
        ]
    }

    if (addItem.type === 'flipcard') {
        const form = new FormContainer()
        ;(form.widgets = [new Flipcard()]), (form.colourSettings = colorSettings)

        newList = [
            ...currentList.slice(0, addItem.insertIndex),
            form,
            ...currentList.slice(addItem.insertIndex + 1),
        ]
    }

    return newList
}
