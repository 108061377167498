import { nanoid } from "nanoid"
import { ILayer } from "./Layer"
import { IWidget } from "./Widget"

export interface IView {
    name: string
    id: string
    backgroundColour: string
    backgroundImageUrl: string
    backgroundFile: File | undefined
    backgroundFileName: string
    widgets: IWidget[]
    layers: ILayer[]
}


class View implements IView  {
    name: string
    id: string
    backgroundColour: string
    backgroundImageUrl: string
    backgroundFile: File | undefined
    backgroundFileName: string
    widgets: IWidget[]
    layers: ILayer[]

    constructor(name:string = "custom view", id:string = nanoid(), backgroundColor: string, backgroundImageUrl: string, backgroundFile, backgroundFileName, widgets: IWidget[], layers ){
        this.name = name
        this.id = id
        this.backgroundColour = backgroundColor
        this.backgroundImageUrl = backgroundImageUrl
        this.backgroundFile = backgroundFile
        this.backgroundFileName = backgroundFileName
        this.widgets = widgets
        this.layers = layers
    }

}

export default View