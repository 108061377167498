import React, { useState, useEffect, Dispatch, SetStateAction, FC } from 'react'
import { useRecoilValue } from 'recoil'
import styled, { keyframes } from 'styled-components'
import { delay } from '../../../../helpers/delay'
import { IAnswer, IQuestion } from '../../../../models/Games/QuestionsQuiz'
import { gameErrors } from '../../../../stores/widgets/game/state'

interface Props {
    data: IQuestion[]
    setStop: Dispatch<SetStateAction<boolean>>
    questionNumber: number
    setQuestionNumber: Dispatch<SetStateAction<number>>
    color: string
}

const Questions: FC<Props> = ({ data, setStop, questionNumber, setQuestionNumber, color }) => {
    const [question, setQuestion] = useState(null)
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [className, setClassName] = useState('answer')
    const error = useRecoilValue(gameErrors)

    useEffect(() => {
        setQuestion(data[questionNumber - 1])
    }, [data, questionNumber])

    const handleClick = (answer: IAnswer) => {
        setSelectedAnswer(answer)
        setClassName('answer_active')
        delay(3000, () => setClassName(answer.correct ? 'answer_correct' : 'answer_wrong'))

        delay(6000, () => {
            if (questionNumber === data.length) {
                setStop(true)
            }
            if (answer.correct) {
                setQuestionNumber((prev) => prev + 1)
                setSelectedAnswer(null)
            } else {
                setStop(true)
            }
        })
    }

    const handleChooseAnswer = (answer: IAnswer) => () => {
        handleClick(answer)
    }

    return (
        <QuestionsContainer color={color} error={error}>
            <QuestionCount>{`Question ${questionNumber}/${data.length}`}</QuestionCount>
            <Question>{question?.question}</Question>
            <Answers>
                {question?.answers.map((answer: IAnswer, index: number) => (
                    <div
                        key={answer.text + index}
                        className={selectedAnswer === answer ? className : 'answer'}
                        onClick={handleChooseAnswer(answer)}
                    >
                        {answer.text}
                    </div>
                ))}
            </Answers>
        </QuestionsContainer>
    )
}

export default Questions

const QuestionsContainer = styled.div<{
    color: string
    error: string
}>`
    pointer-events: ${(props) => (props.error ? 'none' : 'auto')};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: ${(props) => props.color};
`

const QuestionCount = styled.span`
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #717f95;
    margin-top: 15px;
`
const Question = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
`
const gradient = keyframes`
    0% {
        background-position: 80% 0%;
    }
    50% {
        background-position: 20% 100%;
    }
    100% {
        background-position: 80% 0%;
    }
`
const Answers = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    gap: 15px;
    font-size: 13px;
    margin-bottom: 18px;
    width: 90%;

    & > div {
        width: 100%;
    }

    .answer {
        border: 1px solid #000;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
    }

    .answer_active {
        border: 1px solid #000;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        background: linear-gradient(149deg, #3779f5 0%, #a7bbff 42%, #1887bb 72%, #ffffff 100%);
        animation: ${gradient} 2s infinite linear;
        background-size: 400%;
    }

    .answer_correct {
        border: 1px solid #000;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        border: 1px solid #059c00;
        background-color: #9ff89c;
    }

    .answer_wrong {
        border: 1px solid #000;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        border: 1px solid #e00808;
        background-color: #f89c9c;
    }
`
