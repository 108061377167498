import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div<{
    fontFamily: string
    buttonTextColor: string
    buttonColor: string
    width: string
}>`
    width: 100%;
    display: flex;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .CardFront {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: inherit;
        font-family: ${(props) => props.fontFamily};
        font-display: block;
    }

    .react-card-flip {
        width: ${(props) => props.width};
    }

    .MuiButtonBase-root {
        width: 80%;
        margin: 1em auto;
        outline: none;
        text-transform: none;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 29px;
        gap: 10px;
        height: 44px;
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: ${(props) => props.buttonTextColor};
        background-color: ${(props) => props.buttonColor};
        font-family: ${(props) => props.fontFamily} !important;
        font-display: block;
    }
    .MuiButtonBase-root:hover,
    .MuiButton-containedPrimary:hover {
        background-color: ${(props) => props.buttonColor};
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .animated {
        animation-duration: 0.6s;
        animation-delay: 700ms;
        animation-fill-mode: both;
        -webkit-animation-duration: 0.6s;
        -webkit-animation-delay: 700ms;
        -webkit-animation-fill-mode: both;
    }

    .fadeInUp {
        opacity: 0;
        animation-name: fadeInUp;
        -webkit-animation-name: fadeInUp;
    }

    section {
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    section > div {
        display: flex;
        width: 4.5rem;
        height: 4.5rem;
        justify-content: center;
        align-items: center;
    }
`
export const Container = styled.div<{
    width: string
    paddingTop?: string
    paddingBottom?: string
    paddingLeft?: string
    paddingRight?: string
    backgroundImage?: string
    backgroundColor?: string
    borderTopLeftRadius: string
    borderTopRightRadius: string
    borderBottomLeftRadius: string
    borderBottomRightRadius: string
    marginTop?: string
    marginBottom?: string
    marginRight?: string
    marginLeft?: string
    fontFamily: string
    isActive: boolean
    isShadow: boolean
    shadowColor: string
    isPreview?: boolean
    border: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${(props) => props.fontFamily};
    font-display: block;
    width: ${(props) => props.width};
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: ${(props) => props.border};
    box-shadow: ${(props) => (props.isShadow ? `0px 0px 14px 0px ${props.shadowColor}` : 'none')};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    padding-top: ${(props) => props.paddingTop};
    padding-left: ${(props) => props.paddingLeft};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-right: ${(props) => props.paddingRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    background-image: ${(props) => props.backgroundImage};
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fff')};
    transition: ease 0.4s;

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
export const Username = styled.h1<{
    textColor: string
    fontSize: string
    fontWeight: number
    lineHeight: number
    letterSpacing: string
}>`
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.textColor};
    font-size: ${(props) => props.fontSize};
    text-align: center;
    margin-bottom: 3px;
    letter-spacing: ${(props) => props.letterSpacing};
    line-height: ${(props) => props.lineHeight};
`
export const UserRole = styled.h4<{
    textColor: string
    fontSize: string
    fontWeight: number
    lineHeight: number
    letterSpacing: string
}>`
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.textColor};
    font-size: ${(props) => props.fontSize};
    text-align: center;
    margin-bottom: 19px;
    opacity: 0.7;
    letter-spacing: ${(props) => props.letterSpacing};
    line-height: ${(props) => props.lineHeight};
`
export const TextContent = styled.p<{
    textColor: string
    fontSize: string
    lineHeight: number
    letterSpacing: string
}>`
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: ${(props) => props.textColor};
    font-size: ${(props) => props.fontSize};
    line-height: 22px;
    text-align: center;
    padding: 0 1em;
    margin: 1em 0;
    margin-bottom: 19px;
    letter-spacing: ${(props) => props.letterSpacing};
    line-height: ${(props) => props.lineHeight};
    & > a {
        text-decoration: underline;
    }
    & > a:not(:last-child) {
        margin-bottom: 18px;
    }
`

export const SocialWrapper = styled.div<{
    textColor: string
    fontSize: string
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    & > span {
        width: 100%;
        text-align: center;
        color: ${(props) => props.textColor};
        font-size: ${(props) => props.fontSize};
    }
`

export const SocialItems = styled.div`
    display: flex;

    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1em;
    & > svg:not(:last-child) {
        margin-right: 10px;
    }
`
export const Back = styled.div<{
    height: string
}>`
    height: ${(props) => props.height};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`
export const QRButton = styled.div<{
    textColor: string
}>`
    font-size: 14px;
    margin-bottom: 1.5em;
    text-decoration: underline;
    cursor: pointer;
    color: ${(props) => props.textColor};
`

export const ShareButton = styled.div<{
    showSharringSocial: boolean
}>`
    position: absolute;
    top: 20px;
    right: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    z-index: 8;
    transform: ${(props) => (props.showSharringSocial ? 'rotate(270deg)' : 'rotate(0)')};
    transition: transform 0.2s ease-in-out;

    & > div {
        background-color: red;
        border-radius: 20px;
    }
`

export const SocialContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 22px;
    top: 60px;
    z-index: 10;

    & > div:not(:last-child) {
        margin-bottom: 7px;
    }
`

const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`

export const NavIcon = styled.span`
    width: 35px;
    height: 35px;
    background: #000;
    cursor: pointer;
    border: none;
    outline: none;
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const Line = styled.span<{
    open: boolean
}>`
    display: block;
    border-radius: 50px;
    width: 20px;
    height: 2px;
    margin: 2px;
    background-color: #fff;
    transition: width 0.2s ease-in-out;

    :nth-child(odd) {
        width: ${(props) => (props.open ? '40%' : '20px')};
    }
`
export const Overlay = styled.div<{
    open: boolean
}>`
    position: absolute;
    height: ${(props) => (props.open ? '220px' : 0)};
    width: 101%;
    top: -2px;
    background: #fff;
    transition: height 0.4s ease-in-out;
    z-index: 9;
    color: #000;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`

export const OverlayContent = styled.div<{
    open: boolean
}>`
    padding: 25px;
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    animation: ${fadeIn} 0.8s ease-in-out;
    animation-duration: 0.6s;

    & > img {
        margin-top: 50px !important;
    }

    a:not(:last-child) {
        border-bottom: 1px solid #cacaca;
    }
`

export const Link = styled.a`
    color: #000;
    text-decoration: none;
    font-size: 16px;
    padding: 18px 0;
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: translateX(5px);
    }
`

export const Logo = styled.img`
    margin-top: 18px;
`

export const CustomWalletButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    height: 50px;
`
