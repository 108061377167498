import { nanoid } from 'nanoid'
import { Reward } from './Reward'

export type PostGame = {
    viewIndex: number
    layerIndex: number
    sendEmail: boolean
    sendSMS: boolean
}

export interface ISegment {
    id: string
    image: string
    imageName: string
    text: string
    reward?: Reward
    prize?: string | null
    postGame?: {
        viewIndex: number
        layerIndex: number
    }
}

export class Segment implements ISegment {
    id = nanoid()
    image = ''
    imageName = ''
    text = ''
    prize = null
    reward = {
        _id: '',
        rewardType: '',
        name: '',
        allocatedQuantity: 0,
        quantity: 0,
        isReward: false,
        probability: 0,
        digitalSettings: {
            file: null,
            fileName: '',
            fileUrl: '',
            type: '',
            url: '',
        },
    }
    postGame = {
        viewIndex: 0,
        layerIndex: -1,
    }

    constructor(text: string) {
        this.text = text
    }
}
