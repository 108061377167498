import { nanoid } from "nanoid"



class BaseInput {
    id = nanoid()
    name = ''
    type = ''
    icon = ''
    category = 'form'
    colourSettings = {}
    appearanceSettings = {}
    settings = {}
    widgets = []
}

export default BaseInput