/* eslint-disable @next/next/no-img-element */
import React, { memo, MouseEvent } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { useButtonAction } from '../../../../lib/hooks/useButtonAction'
import { IWidget } from '../../../../models/Widget'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget?: IWidget
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
}

const Image = ({ widget, isPreview = false, updateActiveWidget, index, parentIndex }: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const action = widget.settings.action
    const handleAction = useButtonAction(action)

    const handleClickOn = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        } else {
            handleAction.action()
        }
    }

    let appearanceSettings = widget?.appearanceSettings
    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget.settings,
        }
    }

    const width = appearanceSettings?.width + '%'
    const align = appearanceSettings?.align
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'

    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const src = widget.settings.src || '/images/default_image.png'

    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius / 10 + 'em'

    const shiftRight = appearanceSettings?.boxShadow?.shiftRight
    const shiftDown = appearanceSettings?.boxShadow?.shiftDown
    const spread = appearanceSettings?.boxShadow?.spread
    const blur = appearanceSettings?.boxShadow?.blur
    const shadowColor = appearanceSettings?.boxShadow?.colour[0]
    const boxShadow = `${shiftRight}px ${shiftDown}px ${spread}px ${blur}px ${shadowColor}`

    const generateShape = (frame: string) => {
        switch (frame) {
            case 'None':
                return 'none'
                break
            case 'Circle':
                return 'circle()'
                break
            case 'Triangle':
                return 'polygon(50% 0, 100% 100%, 0 100%)'
                break
            case 'Pentagon':
                return 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)'
                break
            case 'Ellipse':
                return 'ellipse()'
                break
            case 'Octagon':
                return 'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)'
                break
            case 'Star':
                return 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 86% 100%, 50% 70%, 17% 100%, 32% 57%, 2% 35%, 39% 35%)'
                break
            case 'Diamond':
                return 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)'
                break
            default:
            // code block
        }
    }

    const frame = generateShape(widget?.settings.frame) || 'none'

    return (
        <Wrapper align={align} onClick={handleClickOn}>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                boxShadow={boxShadow}
                frame={frame}
            >
                <img aria-labelledby="image-label" tabIndex={0} src={src} alt="" />
                <p id="image-label">{widget.settings.name}</p>
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Image)

const Wrapper = styled.div.attrs((props) => ({}))<{
    align: string
}>`
    display: flex;
    justify-content: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    width: 100%;
`

const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    boxShadow: string
    frame: string
}>`
    position: relative;
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    img {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        cursor: pointer;
        box-shadow: ${(props) => props.boxShadow};
        border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
        border-top-left-radius: ${(props) => props.borderTopLeftRadius};
        border-top-right-radius: ${(props) => props.borderTopRightRadius};
        border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
        -webkit-clip-path: ${(props) => props.frame};
        clip-path: ${(props) => props.frame};
    }

    p {
        display: none;
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: none;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
