import React, { FC, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../../stories/Button/Button'
import Modal from './Modal'

interface Props {
    children: ReactNode
    color?: string
}

const GameRules: FC<Props> = ({ children, color = '#717f95' }) => {
    const [openRulls, setOpenRulls] = useState(false)

    const toggleVisible = () => {
        setOpenRulls((prev) => !prev)
    }
    return (
        <RulesContainer color={color}>
            <Button type="button" variant="invert" onClick={toggleVisible}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                    style={{ marginRight: '10px' }}
                    width={20}
                    height={20}
                    src="/icons/rulles.png"
                    alt="rules"
                />
                Game rules
            </Button>
            <Modal isOpen={openRulls}>
                <ModalContent>
                    {children}

                    <ErrorsDescription>
                        <hr />
                        <p>
                            During the game, you may receive some informational notifications. What
                            do they mean?
                        </p>
                        <h2>1. No more attempts for game</h2>
                        <span>
                            This means that you have exhausted your number of attempts to play. You
                            will also see an information window after which time you can return and
                            play this game again.
                        </span>
                        <h2>2. Your reward can no longer be claimed</h2>
                        <span>
                            This means that you or other participants in the game have already
                            exhausted the prize pool and there are no more rewards left. But don`t
                            despair. Stay tuned for new prize draws and play again!
                        </span>
                        <h2>3. Thanks! We will contact you shortly</h2>
                        <span>
                            It means that the company that draws the prizes received your game data
                            in order to send you the details of receiving the prizes.
                        </span>
                    </ErrorsDescription>
                    <hr />
                    <h3>GOOD LUCK!</h3>
                    <p onClick={toggleVisible}>OK</p>
                </ModalContent>
            </Modal>
        </RulesContainer>
    )
}

export default GameRules

const RulesContainer = styled.div<{
    color: string
}>`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > button {
        font-size: 12px;
        border: 0px solid #dfe2f2;
        background: none;
        color: ${(props) => props.color};
    }
`

const ModalContent = styled.div`
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    hr {
        width: 80%;
        margin: 10px auto;
        color: #b8bdc5;
    }

    & > h1 {
        font-size: 20px;
        font-weight: 700;
        margin: 10px 0;
    }

    & > h3 {
        font-size: 20px;
        font-weight: 700;
        margin: 10px 0;
        color: #3a3a3a;
    }

    img {
        margin: 10px auto;
        width: 60%;
        height: 60%;
    }

    & > span {
        margin: 10px 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        color: #717f95;
        line-height: 1.2;
        text-align: justify;
    }

    & > p {
        width: 40%;
        background-color: #000;
        padding: 15px;
        border-radius: 10px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        margin: 18px 0;
    }
`

const ErrorsDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 13px;
        font-weight: 700;
        margin: 10px 0;
        color: #717f95;
        width: 100%;
        text-align: justify;
    }

    h2 {
        font-size: 14px;
        font-weight: 700;
        margin: 10px 0;
        color: #33363b;
    }

    span {
        margin: 10px 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        color: #717f95;
        line-height: 1.2;
        text-align: justify;
    }
`
