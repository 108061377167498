import React from 'react'
import { Toast } from '../../../../helpers/Toast'
import SocialIcons from '../../../Templates/Custom/Icons/SocialIcons'
import { SocialItems, SocialWrapper } from './styles'

interface Props {
    socialLinks: { [key: string]: string }
    iconColor: string
    textColor: string
    fontSize: string
}

export const SocialLinksBlock = ({ socialLinks, iconColor, textColor, fontSize }: Props) => {
    console.log(socialLinks)

    if (!socialLinks || !Object.keys(socialLinks).length) {
        Toast('Please add social link in brand settings', 'error')
    }

    return (
        <SocialWrapper textColor={textColor} fontSize={fontSize}>
            <span>SOCIAL MEDIA</span>
            <SocialItems>
                {Object.keys(socialLinks).length !== 0 &&
                    Object.keys(socialLinks)?.map((key) => {
                        if (socialLinks[key] !== '' && typeof socialLinks[key] === 'string') {
                            return (
                                <SocialIcons
                                    key={key}
                                    platform={key}
                                    height={'2.5em'}
                                    width={'2.5em'}
                                    color={iconColor}
                                    link={socialLinks[key]}
                                />
                            )
                        }
                    })}
            </SocialItems>
        </SocialWrapper>
    )
}
