import { nanoid } from 'nanoid'
import { Widget } from '../Widget'
import IWidget from '../../types/Widget'
import WheelSegment, { IWheelSegment } from './WheelSegment'

export enum ImageDirection {
    N = 'N',
    E = 'E',
    S = 'S',
    W = 'W',
}

export enum DrawMode {
    CODE = 'code',
    IMAGE = 'image',
    SEGMENTIMAGE = 'segmentImage',
}

export enum TextAlignment {
    INNER = 'inner',
    OUTER = 'outer',
    CENTER = 'center',
}

export enum TextOrientation {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
    CURVED = 'curved',
}

export enum TextDirection {
    NORMAL = 'normal',
    REVERSED = 'reversed',
}

export interface ISpinwheel extends IWidget {
    settings: {
        name: string
        losingText: string
        winnerText: string
        segmentData: IWheelSegment[]
        drawMode: DrawMode
        drawText: boolean
        imageDirection: ImageDirection
        redirect: string
        attempts: {
            count: number
            period: string
            totalAttempts: number | string
        }
        resetAttempt: {
            enabled: boolean
            time: number
            period: string
            day: number
            date: number | string
        }
        probabilityTotal: number
    }
    appearanceSettings: {
        width: number
        outerRadius: number
        innerRadius: number
        marginTop: number
        marginBottom: number
        marginLeft: number
        marginRight: number
        borderTopLeftRadius: number
        borderTopRightRadius: number
        borderBottomLeftRadius: number
        borderBottomRightRadius: number
        borderTopWidth: number
        borderBottomWidth: number
        borderLeftWidth: number
        borderRightWidth: number
        font: {
            family: string
            weight: number
        }
        fontSize: number
        textMargin: number
        textAlignment: TextAlignment
        textLineWidth: number
        textOrientation: TextOrientation // horizontal, vertical, curved
        textDirection: TextDirection // normal, reversed
        segmentLineWidth: number
        strokeStyle: string
        backgroundImage: string
        backgroundFile: File | null
        backgroundFileName: string
        wheelImage: string
        wheelImageName: string
        pinImage: string
        pinImageName: string
    }
    colourSettings: {
        'background colour': string[]
        'text colour': string[]
        'border colour': string[]
        'pin colour': string[]
        'stroke colour': string[]
    }
}

class Spinwheel extends Widget implements ISpinwheel {
    id = nanoid()
    name = 'spin wheel'
    type = 'spinwheel'
    icon = '/icons/widgets/spinwheel.svg'
    category = 'game'
    settings = {
        redirect: '',
        name: 'spin wheel',
        losingText: 'Sorry but better luck next time',
        winnerText: 'Congratulations you’re a winner!',
        segmentData: [
            new WheelSegment('1', '#0D56A6', '#aab5f8', '/icons/widgets/spinwheel/custom1.svg'),
            new WheelSegment('2', '#0D56A6', '#fff', '/icons/widgets/spinwheel/custom2.svg'),
            new WheelSegment('3', '#0D56A6', '#aab5f8', '/icons/widgets/spinwheel/custom1.svg'),
            new WheelSegment('4', '#0D56A6', '#fff', '/icons/widgets/spinwheel/custom2.svg'),
            new WheelSegment('5', '#0D56A6', '#aab5f8', '/icons/widgets/spinwheel/custom1.svg'),
            new WheelSegment('6', '#0D56A6', '#fff', '/icons/widgets/spinwheel/custom2.svg'),
            new WheelSegment('7', '#0D56A6', '#aab5f8', '/icons/widgets/spinwheel/custom1.svg'),
            new WheelSegment('8', '#0D56A6', '#fff', '/icons/widgets/spinwheel/custom2.svg'),
        ],
        drawMode: DrawMode.CODE, // code, image, segmentImage,
        drawText: true,
        imageDirection: ImageDirection.S, // N, S, E, W
        attempts: {
            count: 1,
            period: 'hour',
            totalAttempts: 'unlimited',
        },
        resetAttempt: {
            enabled: false,
            time: 0,
            period: 'day',
            day: 1,
            date: 'start',
        },
        probabilityTotal: 0,
    }
    appearanceSettings = {
        width: 100,
        outerRadius: 100,
        innerRadius: 0,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 0,
        marginRight: 0,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        font: {
            family: 'Poppins',
            weight: 600,
        },
        fontSize: 34,
        textMargin: 10,
        textAlignment: TextAlignment.CENTER, // inner, outer, center
        textLineWidth: 0,
        textOrientation: TextOrientation.CURVED, // horizontal, vertical, curved
        textDirection: TextDirection.NORMAL, // normal, reversed
        segmentLineWidth: 10,
        strokeStyle: '#000',
        backgroundImage: '',
        backgroundFile: null,
        backgroundFileName: '',
        wheelImage: '/icons/widgets/spinwheel/spinwheel.svg',
        wheelImageName: '',
        pinImage: '',
        pinImageName: '',
    }
    colourSettings = {
        'background colour': ['rgba(0,0,0,0)', '#FFA7E1', '#0426FF'],
        'text colour': ['rgba(0,0,0,0.5)', '#FFA7E1', '#0426FF'],
        'border colour': ['rgba(0,0,0,0)', '#FFA7E1', '#0426FF'],
        'pin colour': ['#6478F9', '#FFA7E1', '#0426FF'],
        'stroke colour': ['#6478F9', '#FFA7E1', '#0426FF'],
    }
}

export default Spinwheel
