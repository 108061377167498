import React, { memo, MouseEvent } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { useButtonAction } from '../../../../lib/hooks/useButtonAction'
import { LinkAction } from '../../../../models/General/LinkSettings'

import { IWidget } from '../../../../models/Widget'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget?: IWidget
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    isPreview?: boolean
}

const Linktext = ({ widget, updateActiveWidget, index, parentIndex, isPreview = false }: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)
    const handleAction = useButtonAction(widget?.settings?.link)
    const handleClickOn = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        } else {
            handleAction.action()
        }
    }

    let appearanceSettings = widget.appearanceSettings
    let colourSettings = widget.colourSettings
    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget.settings,
        }
    }
    if (!colourSettings) {
        colourSettings = {
            textColour: widget.settings.textColour,
        }
    }

    const getContent = (type: string) => {
        if (type === LinkAction.GO_TO_EMAIL) {
            return `<a href=mailto:${widget.settings.link.linkUrl}>${widget.settings.link.linkTitle}</a>`
        } else if (type === LinkAction.GO_TO_LINK) {
            return `<a href=${widget.settings.link.linkUrl} target='_blank'>${widget.settings.link.linkTitle}</a>`
        } else {
            return `<a>${widget.settings.link.linkTitle}</a>`
        }
    }

    const width = appearanceSettings.width + '%'
    const align = appearanceSettings.align
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings.marginRight / 10 + 'em'
    const content = getContent(widget?.settings?.link?.type)

    const fontFamily = appearanceSettings.font.family || 'Poppins'
    const fontUrl = appearanceSettings?.font?.url
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const color = Array.isArray(colourSettings.textColour)
        ? colourSettings.textColour[0]
        : colourSettings.textColour
    const letterSpacing = appearanceSettings.font.spacing + 'px'
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'
    const weight = appearanceSettings?.font?.weight

    const shiftRight = appearanceSettings?.boxShadow?.shiftRight
    const shiftDown = appearanceSettings?.boxShadow?.shiftDown
    const blur = appearanceSettings?.boxShadow?.blur
    const shadowColor = appearanceSettings?.boxShadow?.colour[0]
    const textShadow = `${shiftRight}px ${shiftDown}px ${blur}px ${shadowColor}`
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    return (
        <Wrapper align={align}>
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                onClick={handleClickOn}
            >
                <HeaderLink
                    fontFamily={fontFamily}
                    color={color}
                    fontSize={fontSize}
                    weight={weight}
                    align={align}
                    letterSpacing={letterSpacing}
                    textShadow={textShadow}
                    lineHeight={lineHeight}
                    dangerouslySetInnerHTML={{ __html: content }}
                ></HeaderLink>
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Linktext)

export const HeaderLink = styled.div.attrs((props) => ({}))<{
    fontFamily: string
    color: string
    fontSize: string
    letterSpacing: string
    align: string
    weight: string
    textShadow: string
    lineHeight: number
}>`
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize};
    color: ${(props) => props.color};
    text-align: ${(props) => props.align};
    & > a {
        font-weight: ${(props) => +props.weight};
        letter-spacing: ${(props) => props.letterSpacing};
        text-decoration-line: underline;
        text-shadow: ${(props) => props.textShadow};
        line-height: ${(props) => props.lineHeight};
    }
`
const Wrapper = styled.div.attrs((props) => ({}))<{
    align: string
}>`
    display: flex;
    justify-content: center;
    width: 100%;
`
const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    isPreview: boolean
}>`
    position: relative;
    cursor: pointer;
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    a {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        pointer-events: ${(props) => (props.isPreview ? 'none' : 'auto')};
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
