import { nanoid } from 'nanoid'

export interface IWidget {
    _id?: string
    id: string
    name: string
    type: string
    icon: string
    category: string
    addSocial?: boolean
    addQR?: boolean
    settings: any
    appearanceSettings: { [key: string]: any }
    colourSettings: { [key: string]: any }
    widgets?: any[]
}

export class Widget implements IWidget {
    id = nanoid()
    name = ''
    type = ''
    icon = ''
    category = ''
    settings = {}
    appearanceSettings = {}
    colourSettings = {}
}
