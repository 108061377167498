import React, { memo, MouseEvent } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import Widget from '../../../../types/Widget'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget: Widget
    isPreview?: boolean
    parentIndex?: number | null
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: Widget) => void
}

const Spotify = ({ widget, isPreview = false, updateActiveWidget, index, parentIndex }: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    let appearanceSettings = widget?.appearanceSettings

    const width = appearanceSettings?.width
    const height = appearanceSettings?.height

    const title = widget?.settings?.title
    const uri = widget?.settings?.uri
    const theme = widget?.settings?.theme
    const src = 'https://open.spotify.com/embed/' + `${uri}${theme ? '&theme=0' : ''}`

    return (
        <Wrapper>
            <Container isActive={isActive} isPreview={isPreview}>
                <iframe
                    loading="lazy"
                    frameBorder="0"
                    allow="encrypted-media"
                    style={{ borderRadius: '12px' }}
                    title={title}
                    src={src}
                    width={width}
                    height={height}
                />
                {isPreview && (
                    <EditingIndicator
                        widgetName={capitalizeFirstLetter(widget?.name)}
                        onClick={onEditMode}
                    />
                )}
            </Container>
        </Wrapper>
    )
}

export default memo(Spotify)

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 15px 0;
    cursor: pointer;
`

const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
}>`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;

    iframe {
        width: 100%;
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            display: ${(props) => (props.isActive ? 'none' : 'flex')};
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        pointer-events: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
