import { useSetRecoilState } from "recoil"
import { currentLayerState, currentViewState } from "../../stores/campaign/live/state"


export const useHandleAction = (action) => {

    const setCurrentView = useSetRecoilState(currentViewState)
    const setCurrentLayer = useSetRecoilState(currentLayerState)

    let returnedAction = null

    if(action){
        switch(action.type){
            case "redirect":
                returnedAction = () => {window.open(action.linkUrl)}
                break
            case "toggle popup":
                returnedAction = () => setCurrentLayer(action.layerIndex)
                break
            case "go to page":
                returnedAction = () => {
                    setCurrentView(action.viewIndex)
                    setCurrentLayer(null)
                }
                break
            default:
                break
        }
    }

    return {
        action: returnedAction
    }
}