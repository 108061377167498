import React, { FC } from 'react'

interface Props {
    size: number
    timeLimit: number
    progress: number
    trackWidth?: number
    trackColor?: string
    indicatorWidth?: number
    indicatorColor?: string
    indicatorCap?: 'round' | 'inherit' | 'butt' | 'square'
    label: string
    labelColor?: string
    spinnerMode?: boolean
    spinnerSpeed?: number
}

const ProgressBar: FC<Props> = ({
    size = 150,
    progress = 0,
    trackWidth = 10,
    trackColor = `#ddd`,
    indicatorWidth = 10,
    indicatorColor = `#07c`,
    indicatorCap = `round`,
    label,
    labelColor = `#333`,
    spinnerMode = false,
    spinnerSpeed = 1,
    timeLimit = 30,
}) => {
    const center = size / 2,
        radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
        dashArray = 2 * Math.PI * radius,
        dashOffset = dashArray * ((timeLimit - progress) / timeLimit)

    let hideLabel = size < timeLimit || !label.length || spinnerMode ? true : false

    return (
        <>
            <div className="svg-pi-wrapper" style={{ width: size, height: size }}>
                <svg className="svg-pi" style={{ width: size, height: size }}>
                    <circle
                        className="svg-pi-track"
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={trackColor}
                        strokeWidth={trackWidth}
                    />
                    <circle
                        className={`svg-pi-indicator ${
                            spinnerMode ? 'svg-pi-indicator--spinner' : ''
                        }`}
                        style={{ animationDuration: `${spinnerSpeed} * 1000` }}
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={indicatorColor}
                        strokeWidth={indicatorWidth}
                        strokeDasharray={dashArray}
                        strokeDashoffset={dashOffset}
                        strokeLinecap={indicatorCap}
                    />
                </svg>

                {!hideLabel && (
                    <div className="svg-pi-label" style={{ color: labelColor }}>
                        <span className="svg-pi-label__loading">{label}</span>

                        {!spinnerMode && (
                            <span className="svg-pi-label__progress">
                                {`${progress > timeLimit ? timeLimit : progress}`}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default ProgressBar
