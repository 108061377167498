import React from 'react'
import styled, { keyframes } from 'styled-components'

interface Props {
    gameType: string
}

export const StartGameTitle = ({ gameType }: Props) => {
    const renderGameTitle = () => {
        switch (gameType) {
            case 'scratchcard':
                return (
                    <StartText>
                        <section className="container">
                            <h1 className="title">
                                <span>Scratch and Win</span>
                            </h1>
                        </section>
                    </StartText>
                )
            case 'spinwheel':
                return (
                    <StartText>
                        <section className="container">
                            <h1 className="title">
                                <span>Tap to Start</span>
                            </h1>
                        </section>
                    </StartText>
                )
            case 'flipcard':
                return (
                    <StartText>
                        <section className="container">
                            <h1 className="title">
                                <span>Flip and win</span>
                            </h1>
                        </section>
                    </StartText>
                )
            default:
                return <></>
        }
    }

    return <>{renderGameTitle()}</>
}

const titleAnimation = keyframes`
    0% {
        transform: translateY(-50px);
        opacity: 0;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    }
    20% {
        transform: translateY(0);
        opacity: 1;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    }
    80% {
        transform: translateY(0);
        opacity: 1;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    }
    100% {
        transform: translateY(50px);
        opacity: 0;
        -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
        clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
    }
`

const StartText = styled.span`
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
        height: 90px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h1,
    h2 {
        font-size: 25px;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 0px 0px 14px rgba(150, 150, 150, 1);

        span {
            width: 100%;
            float: left;
            color: #fff;
            -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
            clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
            transform: translateY(-50px);
            text-align: center;
            opacity: 0;
            animation: ${titleAnimation} 3s ease;
        }
    }
    h1 span {
        animation-delay: 0.6s;
        color: #ffffff;
        animation-delay: 0.5s;
    }
`
