export const postCaptchaToken = async (token:string, action:string):Promise<boolean> => {

    if(token === null){
        return false
    }

    try {
        const res = await fetch('/api/form/recaptcha', {
            method: "POST",
            body: JSON.stringify({token, action})
        })

        const data = await res.json()

        return data.data

    } catch (error) {
        return false
    }

} 