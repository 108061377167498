import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { IWidget } from '../../models/Widget.js'
import '../../utils/TweenMax.js'
import Winwheel from '../../utils/Winwheel.js'

export declare type SpinToWinSegment = {
    fillStyle?: string
    textFillStyle?: string
    strokeStyle?: string
    textStrokeStyle?: string
    textFontFamily?: any
    textFontSize?: number
    textFontWeight?: any
    text: string
}

export declare type SpinToWinAnimation = {
    type: 'spinToStop' | 'spinOngoing' | 'spinAndBack'
    duration: number
    spins: number
    soundTrigger?: any
    stopAngle?: any
}

export declare type SpinToWinPins = {
    number: number
    outerRadius: number
    margin: number
    fillStyle: string
    strokeStyle: string
    responsive: true
}

export interface SpinToWinProps extends HTMLAttributes<HTMLCanvasElement> {
    width: number
    height: number
    totalSegements: number
    outerRadius?: number
    innerRadius?: number
    centerX?: number
    centerY?: number
    fillStyle?: string
    textAlignment: 'inner' | 'outer' | 'center'
    textMargin?: number
    textFillStyle?: string
    textOrientation: 'horizontal' | 'vertical' | 'curved'
    textDirection: 'normal' | 'reversed'
    textFontWeight: number
    textFontFamily: any
    textFontSize?: number
    strokeStyle?: string
    lineWidth: number
    segments: Array<SpinToWinSegment>
    animation: SpinToWinAnimation
    stopAtSegment?: number
    onSpinFinish?: Function
    pointerStroke?: string
    pointerFill?: string
    pointerLineWidth?: number
    highlightIndicatedSegment?: boolean
    highlightIndicatedSegmentFill?: string
    highlightIndicatedSegmentTextFill?: string
    highlightOtherSegmentsWhenHasIndicatedSegment?: boolean
    fillOtherSegmentsWhenHasIndicatedSegment?: string
    textFillOtherSegmentsWhenHasIndicatedSegment?: string
    showPins?: boolean
    pins?: SpinToWinPins
    wheelImage?: string
    drawMode?: string
    drawText?: boolean
    isPreview?: boolean
    containerSize?: { width: number; height: number }
    widget?: IWidget
}

let theWheel = null

export const SpinToWin = ({ ...props }: SpinToWinProps) => {
    const [wheel, setWheel] = useState(null)

    const finishSpin = (segment: SpinToWinSegment[]) => {
        if (theWheel && props?.highlightIndicatedSegment) {
            let indicatedSegmentNumber = theWheel.getIndicatedSegmentNumber()
            if (props?.highlightOtherSegmentsWhenHasIndicatedSegment) {
                for (let x = 1; x < theWheel.segments.length; x++) {
                    theWheel.segments[x].fillStyle =
                        props?.fillOtherSegmentsWhenHasIndicatedSegment || 'gray'
                    theWheel.segments[x].textFillStyle =
                        props?.textFillOtherSegmentsWhenHasIndicatedSegment || '#000000'
                }
            }
            theWheel.segments[indicatedSegmentNumber].fillStyle =
                props?.highlightIndicatedSegmentFill || 'yellow'
            theWheel.segments[indicatedSegmentNumber].textFillStyle =
                props?.highlightIndicatedSegmentTextFill || '#000000'
            theWheel.draw()
        }
        if (props?.onSpinFinish) {
            props.onSpinFinish(segment)
        }
    }

    useEffect(() => {
        if (window && props?.containerSize?.width > 100) {
            let audio = new Audio('/sound/spin.mp3')
            const playSound = () => {
                audio.pause()
                audio.currentTime = 0
                // Play the sound.
                audio.play()
            }

            let wh = new Winwheel({
                canvasId: props.widget.id,
                responsive: true,
                numSegments: props?.totalSegements,
                outerRadius: props?.outerRadius,
                innerRadius: props?.innerRadius,
                centerX: props?.centerX,
                centerY: props?.centerY,
                textAlignment: props?.textAlignment || 'outer',
                textOrientation: props?.textOrientation || 'horizontal',
                textDirection: props?.textDirection || 'normal',
                textFontFamily: props?.textFontFamily || 'Courier',
                textFontSize: props?.textFontSize || 20,
                lineWidth: props?.lineWidth || 1,
                textFontWeight: props?.textFontWeight,
                textMargin: props?.textMargin,
                segments: props?.segments || [],
                drawMode: props?.drawMode,
                drawText: props?.drawText,
                animation: {
                    type: props?.animation?.type || 'spinToStop',
                    duration: props?.animation?.duration || 5,
                    spins: props?.animation?.spins || 8,
                    callbackFinished: finishSpin,
                    callbackSound: playSound,
                },
                strokeStyle: props?.strokeStyle,
                pins: {
                    outerRadius: props.drawMode === 'code' ? 3 : 0,
                    responsive: true, // This must be set to true if pin size is to be responsive.
                    margin: -3,
                    fillStyle: '#fff',
                    strokeStyle: '#fff',
                },
            })

            if (props?.drawMode === 'image') {
                let loadedImg = new Image(props.width, props.height)
                // Create callback to execute once the image has finished loading.
                loadedImg.onload = function () {
                    loadedImg.style.width = '100%'
                    loadedImg.style.height = '100%'
                    theWheel.wheelImage = loadedImg // Make wheelImage equal the loaded image object.
                    theWheel.draw() // Also call draw function to render the wheel.
                }
                // Set the image source, once complete this will trigger the onLoad callback (above).
                loadedImg.src = props?.wheelImage
            }

            theWheel = wh
            setWheel(wh)
            wh.draw()
        }
    }, [
        props?.animation?.duration,
        props?.animation?.spins,
        props?.animation?.type,
        props?.centerX,
        props?.centerY,
        props?.containerSize?.width,
        props.drawMode,
        props?.drawText,
        props.height,
        props?.innerRadius,
        props?.lineWidth,
        props?.outerRadius,
        props?.segments,
        props?.strokeStyle,
        props?.textAlignment,
        props?.textDirection,
        props.textFillStyle,
        props?.textFontFamily,
        props?.textFontSize,
        props?.textFontWeight,
        props?.textMargin,
        props?.textOrientation,
        props?.totalSegements,
        props?.wheelImage,
        props.width,
    ])

    useEffect(() => {
        if (theWheel) {
            theWheel.draw()
        }
    }, [wheel])

    const _startAnimation = () => {
        if (wheel && !props.isPreview) {
            wheel.stopAnimation(false)
            wheel.rotationAngle = wheel.rotationAngle % 360
            if (props?.stopAtSegment) {
                let stopAt = wheel.getRandomForSegment(props.stopAtSegment)
                wheel.animation.stopAngle = stopAt
            }
            wheel.startAnimation()
        }
    }

    return (
        <canvas
            id={props.widget?.id}
            width={props.width}
            height={props.height}
            data-responsiveminwidth="180"
            data-responsivescaleheight="true"
            data-responsivemargin="50"
            onClick={_startAnimation}
        >
            Canvas not supported, use another browser.
        </canvas>
    )
}
