import { SubmitButtonSettings } from '../../models/Forms/SubmitButton'
import { Reward } from '../../models/Games/Reward'

export interface ClaimReward {
    message: string
    data: {
        _id?: string
        id: string
        rewardType: string
        reward: string
        name: string
        text?: string
    }
    code: number
    username?: string
    email?: string
}

export const claimReward = async (
    campaignId: string,
    campaignName: string,
    settings: SubmitButtonSettings,
    reward: Reward,
    username?: string,
    email?: string
): Promise<ClaimReward> => {
    if (!settings.actions || !settings.actions.isClaimPrize) return

    if (!reward || Object.keys(reward).length === 0) return

    if (!reward.isReward) {
        return {
            message: 'no reward',
            data: {
                id: '',
                rewardType: 'no win',
                reward: '',
                name: 'no reward',
            },
            code: 200,
            username,
            email,
        }
    }
    const result = await rewardClaimRequest(
        campaignId,
        campaignName,
        settings.gameId,
        reward,
        username,
        email
    )

    return result
}

export const rewardClaimRequest = async (
    campaignId: string,
    campaignName: string,
    gameId: string,
    reward: Reward,
    username?: string,
    email?: string
): Promise<ClaimReward> => {
    try {
        const res = await fetch('/api/reward/claim', {
            method: 'POST',
            body: JSON.stringify({ campaignId, campaignName, gameId, reward }),
        })

        const data = await res.json()
        return { ...data, username, email }
    } catch (error) {
        throw new Error(error.message)
    }
}
