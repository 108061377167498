import { useSetRecoilState } from "recoil"
import { currentLayerState, currentViewState } from "../../../stores/campaign/live/state"

interface PostGameAction {
    viewIndex: number
    layerIndex: number
}

export const useHandlePostGame = (): {set:(postGameAction: PostGameAction)=>void} => {
    
    const setCurrentView = useSetRecoilState(currentViewState)
    const setCurrentLayer = useSetRecoilState(currentLayerState)

    return {
        set: (postGameAction: PostGameAction)=> {
            const {viewIndex, layerIndex} = postGameAction
            setCurrentView(viewIndex) 
            if(layerIndex > -1){
                setCurrentLayer(layerIndex)
            } else {
                setCurrentLayer(null)
            }
        }
    }
}