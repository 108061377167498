export const returnRewardHTML = (rewardResult): string => {
    const rewardHTMLContent = `
        <p>&nbsp;</p>
        <hr />
        <p>&nbsp;</p>
        <p>Your reward details is found below:</p>
        <p>&nbsp;</p>
        <p>Reward ID: ${rewardResult.data._id}</p>
        <p>Reward Type: ${rewardResult.data.rewardType}</p>
        ${
            rewardResult.data.rewardType === 'digital'
                ? `<p>Reward Content: <a style="text-decoration: underline; color: blue;" href=${rewardResult.data.reward} target="_blank">Reward Link</a></p>`
                : ''
        }

        ${
            rewardResult.data.rewardType === 'voucher'
                ? `<p>Reward Voucher Code: ${rewardResult.data.reward}</p>`
                : ''
        }

        ${
            rewardResult.data.rewardType === 'physical'
                ? `<p>Reward Item: ${rewardResult.data.name}</p>`
                : ''
        }
        <p>&nbsp;</p>
        <p>Your reward QR code is attached. For physical store rewards show this QR code to redeem.</p>
        
    `

    return rewardHTMLContent
}
