import React, { useEffect, useState, useRef, MouseEvent, useCallback } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import { Toast } from '../../../../helpers/Toast'
import { useAnalytics } from '../../../../lib/analytics/useAnalytics'
import { useCountdown } from '../../../../lib/hooks/useCountdown'
import { verifyAttempt } from '../../../../lib/reward/session/verifyAttempt'
import { ISegment } from '../../../../models/Games/Segment'
import { ISpinwheel } from '../../../../models/Games/Spinwheel'
import { IWidget } from '../../../../models/Widget'
import { customerAuth } from '../../../../stores/auth/customer/customerAuth'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { liveCampaignState } from '../../../../stores/campaign/live/state'
import { gameAttemptAvailable, rewardState } from '../../../../stores/widgets/game/state'
import { SpinToWin } from '../../../../stories/Components/SpinToWin'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { countdownTimeCalculate } from '../../../../utils/countdownTime'
import { EditingIndicator } from '../../EditingIndicator'
import CountdownAttempt from '../CountdownAttempt'
import Modal from '../Modal'
import ModalContent from '../ModalContent'
import { StartGameTitle } from '../StartGameTitle'

interface Props {
    widget: ISpinwheel | any
    isViewPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: any) => void
}

const Spinwheel = ({
    widget,
    isViewPreview = false,
    updateActiveWidget,
    index,
    parentIndex,
}: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)
    const setAttemptAvailable = useSetRecoilState(gameAttemptAvailable(widget?.id))
    const [loading, setLoading] = useState(false)
    const container = useRef(null)
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
    const [reward, setReward] = useRecoilState(rewardState(widget.id))
    const [isOpen, setIsOpen] = useState(false)
    const [attempt, setAttempt] = useState<number>(0)
    const [rewardIndex, setRewardIndex] = useState(null)
    const [attemptUpdated, setAttemptUpdated] = useState(false)
    const campaign = useRecoilValue(liveCampaignState)
    const _customerAuth = useRecoilValue(customerAuth)
    const [lastAttemptTime, setLastAttemptTime] = useState(null)

    const analytics = useAnalytics('live')
    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    useEffect(() => {
        if (container.current && containerSize.width === 0) {
            setContainerSize({
                width: container.current.clientWidth,
                height: container.current.clientHeight,
            })
        }

        if (!_customerAuth.userId) {
            return
        }

        async function calculateReward(gameWidget: IWidget, campaignId: string) {
            setLoading(true)
            const res = await fetch('/api/reward/calculate', {
                method: 'POST',
                body: JSON.stringify({ gameWidget, campaignId }),
            })
            const result = await res.json()

            if (result?.message === 'exceeded tries' && result.data.lastAttemptTime) {
                const timeToNextGame = countdownTimeCalculate(
                    gameWidget,
                    new Date(result.data.lastAttemptTime * 1000)
                )
                setLastAttemptTime(timeToNextGame)
            }

            if ((result?.data?.attempts || result?.data?.attempts === 0) && result.code !== 500) {
                if (result.data.attempts > widget?.settings?.attempts?.count) {
                    setAttempt(widget?.settings?.attempts?.count)
                    Toast('No more attempts for spin', 'error')
                    const timeToNextGame = countdownTimeCalculate(
                        gameWidget,
                        new Date(result.data.lastAttemptTime * 1000)
                    )
                    setLastAttemptTime(timeToNextGame)
                    setLoading(false)
                    return
                } else {
                    setReward(result.data.reward)
                    setAttempt(result.data.attempts)
                    const rewardId = result.data.reward?.id
                    const rewardIndex = gameWidget.settings.segmentData.findIndex(
                        (segment: any) => segment.id === rewardId
                    )
                    setRewardIndex(rewardIndex + 1)
                }
            } else {
                setReward(result.data)
                const rewardId = result.data.id
                const rewardIndex = gameWidget.settings.segmentData.findIndex(
                    (segment: any) => segment.id === rewardId
                )
                setRewardIndex(rewardIndex + 1)
            }

            if (result.code !== 200) {
                Toast('No more attempts for spin', 'error')
                setAttempt(widget?.settings?.attempts?.count)
                const timeToNextGame = countdownTimeCalculate(
                    gameWidget,
                    new Date(result.data.lastAttemptTime * 1000)
                )
                setLastAttemptTime(timeToNextGame)
                setLoading(false)
                return
            }

            setAttemptAvailable(true)
            setLoading(false)
        }

        if (!isViewPreview && reward === null && _customerAuth.userId) {
            calculateReward(widget, campaign.campaignId)
        }
    }, [reward, _customerAuth, attempt])

    const settings = widget?.settings
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const outerRadius = ((appearanceSettings?.outerRadius / 100) * containerSize.width) / 2.2
    const innerRadius = ((appearanceSettings?.innerRadius / 100) * containerSize.width) / 2.2

    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url

    const fontSize = appearanceSettings?.fontSize
    const fontWeight = appearanceSettings?.font?.weight
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = appearanceSettings?.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings?.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings?.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings?.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth

    const color = Array.isArray(colourSettings['text colour'])
        ? colourSettings['text colour'][0]
        : colourSettings['text colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']
    const backgroundColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']
    const pinColor = Array.isArray(colourSettings['pin colour'])
        ? colourSettings['pin colour'][0]
        : colourSettings['pin colour']
    const strokeStyle = appearanceSettings.strokeStyle
    const border = `${borderWidth} solid ${borderColor}`

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isViewPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    const handleToggleModal = useCallback(() => {
        setIsOpen((prev) => !prev)
    }, [])

    const trackProgress = async (): Promise<void> => {
        if (!attemptUpdated) {
            await verifyAttempt(
                campaign.campaignId,
                widget.id,
                widget.settings.attempts.count,
                widget.settings.attempts.period,
                widget.settings.attempts.totalAttempts
            )
            analytics.track('start game', { game: widget.name })
        }
    }

    const onFinishSpin = (segment: ISegment) => {
        trackProgress()
        setAttemptUpdated(true)
        setIsOpen(true)
        setAttemptAvailable(false)
    }

    const checkAtempts = (general: number, current: number) => {
        const difference = general - current
        if (difference > 0) {
            return difference
        } else {
            return 0
        }
    }

    return (
        <Wrapper
            startGame={!_customerAuth.userId}
            isAttempts={checkAtempts(widget?.settings?.attempts?.count, attempt) === 0}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <Container
                onClick={onEditMode}
                ref={container}
                isActive={isActive}
                isPreview={isViewPreview}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                border={border}
                height={`${containerSize.width}px`}
                attempt={checkAtempts(widget?.settings?.attempts?.count, attempt)}
                fontFamily={fontFamily}
                color={color}
                pinColor={pinColor}
                backgroundColor={backgroundColor}
            >
                <SpinToWin
                    width={isViewPreview ? 350 : 500}
                    height={isViewPreview ? 350 : 500}
                    containerSize={containerSize}
                    isPreview={isViewPreview}
                    outerRadius={outerRadius}
                    innerRadius={innerRadius}
                    centerX={containerSize.width / 2}
                    centerY={containerSize.width / 2}
                    totalSegements={settings?.segmentData?.length}
                    textAlignment={appearanceSettings?.textAlignment}
                    textOrientation={appearanceSettings?.textOrientation}
                    textDirection={appearanceSettings?.textDirection}
                    textFontFamily={appearanceSettings?.font?.family}
                    textMargin={appearanceSettings?.textMargin}
                    lineWidth={appearanceSettings?.segmentLineWidth}
                    segments={settings?.segmentData}
                    textFontWeight={fontWeight}
                    wheelImage={appearanceSettings?.wheelImage}
                    drawMode={settings?.drawMode}
                    drawText={settings?.drawText}
                    textFontSize={fontSize}
                    strokeStyle={strokeStyle}
                    animation={{
                        type: 'spinToStop',
                        duration: 10,
                        spins: 5,
                    }}
                    stopAtSegment={rewardIndex}
                    onSpinFinish={onFinishSpin}
                    widget={widget}
                />
                {appearanceSettings?.pinImage !== '' ? (
                    //  eslint-disable-next-line @next/next/no-img-element
                    <img
                        id={`prizePointer-${widget?.id}`}
                        className="absolute"
                        style={{
                            left: '50%',
                            top: '0px',
                            zIndex: 5,
                            width: `${containerSize?.width * 0.115}px`,
                            height: `${containerSize?.width * 0.115}px`,
                            transform: 'translateX(-50%)',
                            objectFit: 'contain',
                        }}
                        src={appearanceSettings?.pinImage}
                        alt="V"
                    />
                ) : (
                    <div id="triangle-down"></div>
                )}
                {isViewPreview && (
                    <EditingIndicator
                        widget={widget}
                        widgetName={capitalizeFirstLetter(widget?.name)}
                    />
                )}
            </Container>
            {checkAtempts(widget?.settings?.attempts?.count, attempt) === 0 &&
            _customerAuth?.userId ? (
                <CountdownAttempt
                    lastAttemptTime={lastAttemptTime}
                    fontFamily={fontFamily}
                    color={color}
                />
            ) : (
                <Attempts fontFamily={fontFamily} color={color}>
                    {loading
                        ? 'Loading...'
                        : `Attempts: ${checkAtempts(widget?.settings?.attempts?.count, attempt)}`}
                </Attempts>
            )}
            {_customerAuth?.userId && !loading && !lastAttemptTime && (
                <StartGameTitle gameType={widget?.type} />
            )}

            <Modal isOpen={isOpen}>
                <ModalContent
                    reward={reward}
                    widget={widget}
                    setReward={setReward}
                    userData={_customerAuth}
                    handleToggleModal={handleToggleModal}
                    onCompleted={onFinishSpin}
                    setIsOpen={setIsOpen}
                />
            </Modal>
        </Wrapper>
    )
}

export default Spinwheel

const Countdown = styled.div<{
    fontFamily: string
    color: string
}>`
    color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    font-family: ${(props) => props.fontFamily};
    & > div {
        align-items: center;
        margin: 0 0.5rem;
        display: flex;
        flex-direction: column;
    }
`

const Wrapper = styled.div<{
    startGame: boolean
    isAttempts: boolean
}>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    canvas {
        pointer-events: ${(props) => (props.startGame || props.isAttempts ? 'none' : 'auto')};
    }
`

const Attempts = styled.p<{
    fontFamily: string
    color: string
}>`
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    margin: 0.5em auto;
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.color};
`

const Container = styled.div<{
    isActive: boolean
    isPreview: boolean
    attempt: number
    loading?: boolean
    height: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    border: string
    backgroundColor: string
    fontFamily: string
    color: string
    pinColor: string
}>`
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    color: ${(props) => props.color};
    font-family: ${(props) => props.fontFamily};
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: ${(props) => (props.isPreview || props.attempt ? 'auto' : 'none')};
    background-color: ${(props) => props.backgroundColor};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    border: ${(props) => props.border};

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 12px;
        right: 12px;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }

    #triangle-down {
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        z-index: 5;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: ${(props) => `50px solid ${props.pinColor}`};
    }
`
