import React from 'react'
import styled from 'styled-components'

interface Props { 
    backgroundImageUrl: string
    backgroundColour: string
}

const Background = styled.div`
    background-image: url(${({backgroundImageUrl} : Props) => backgroundImageUrl});
    background-color: ${({backgroundColour} : Props) => backgroundColour};
    z-index: -1 !important;
    position: fixed;
    opacity: 1 !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
`

export default Background
