import { nanoid } from 'nanoid'

export enum PollsType {
    DOUBLE = 'double',
    MULTIPLE = 'multiple',
}

export type IAnswer = {
    option: string
    votes: number
    id: string
}

export interface IPolls {
    _id?: string
    id: string
    type: string
    icon: string
    name: string
    settings: {
        data: IAnswer[]
        type: string
        question: string
    }
    appearanceSettings: {
        borderTopLeftRadius: number
        borderTopRightRadius: number
        borderBottomLeftRadius: number
        borderBottomRightRadius: number
        borderTopWidth: number
        borderBottomWidth: number
        borderLeftWidth: number
        borderRightWidth: number
        mode: string
        align: string
        font: {
            family: string
            size: number
            weight?: number
            spacing?: number
        }
        lineHeight: number
    }
    colourSettings: {
        'border colour': string[]
        'background colour': string[]
        'question colour': string[]
    }
}

class Polls implements IPolls {
    id = nanoid()
    type = 'polls'
    name = 'polls'
    icon = '/icons/widgets/polls.png'
    category = 'general'
    settings = {
        data: [
            { id: nanoid(), option: 'Yes', votes: 0 },
            { id: nanoid(), option: 'No', votes: 0 },
            { id: nanoid(), option: 'Maybe', votes: 0 },
        ],
        type: PollsType.MULTIPLE,
        question: 'What you wanna ask?',
    }
    appearanceSettings = {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        mode: 'purple',
        align: 'center',
        width: 95,
        font: {
            family: 'Inter',
            size: 16,
            weight: 400,
            spacing: 2,
        },
        lineHeight: 1,
    }
    colourSettings = {
        'border colour': ['#000', '#FFA7E1', '#0426FF'],
        'background colour': ['rgb(255,255,255)', '#FFA7E1', '#0426FF'],
        'question colour': ['#5c95ff', '#FFA7E1', '#0426FF'],
    }
}

export default Polls
