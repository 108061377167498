import { FilesToUpload } from './formFileUpload'

export const uploadFiles = async (
    files: FilesToUpload[],
    uid: string,
    campaignId: string,
    formId: string
): Promise<{ fieldName: string; data: { url: string; fileName: string }[] }[]> => {
    let urls: { fieldName: string; data: { url: string; fileName: string }[] }[] = []

    if (!files) {
        return urls
    }

    for (let i = 0; i < files.length; i++) {
        const fileGroup = files[i]
        if (!fileGroup.files) {
            return urls
        }
        const _files = fileGroup.files
        const fileType = fileGroup.fileType
        const fieldName = fileGroup.fieldName
        const formData = new FormData()
        for (let k = 0; k < _files.length; k++) {
            formData.append('file', _files[k])
        }

        try {
            const res = await fetch(
                `/api/form/uploadFiles?uid=${uid}&campaignId=${campaignId}&formId=${formId}&fileType=${fileType}`,
                {
                    method: 'POST',
                    body: formData,
                }
            )

            const data = await res.json()

            urls.push({
                fieldName,
                data: data.data,
            })
        } catch (error) {
            console.log(error.message)
            throw new Error(error.message)
        }
    }

    return urls
}
