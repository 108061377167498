import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Button } from '../../../stories/Button/Button'

const modalVariant = {
    initial: { opacity: 0 },
    isOpen: { opacity: 1 },
    exit: { opacity: 0 },
}
const containerVariant = {
    initial: { left: '-50%', transition: { type: 'spring' } },
    isOpen: { left: '50%' },
    exit: { left: '-50%' },
}

interface Props {
    isOpen: boolean
    error: string
}

const ErrorsModal: FC<Props> = ({ isOpen, error }) => {
    const [openModal, setOpenModal] = useState(true)

    const toggleOpen = () => {
        setOpenModal((prev) => !prev)
    }

    return (
        <AnimatePresence>
            {isOpen && openModal && (
                <Overlay
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                    variants={modalVariant}
                >
                    <ModalContainer variants={containerVariant}>
                        <ModalContent>
                            <Header>
                                <h1>Message</h1>
                                <span onClick={toggleOpen}>X</span>
                            </Header>
                            <img width={80} height={80} src="/images/warning.png" alt="warning" />
                            <span>{error}</span>
                        </ModalContent>
                    </ModalContainer>
                </Overlay>
            )}
        </AnimatePresence>
    )
}

export default ErrorsModal

const Overlay = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
`
const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 20px;

    h1 {
        font-size: 20px;
        font-weight: 600;
    }
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #e0e0e0;
        color: #fff;
        width: 30px;
        height: 30px;
    }
`
const ModalContainer = styled(motion.div)`
    width: 100%;
    max-width: 32em;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    padding: 30px;
`

const ModalContent = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;

    & > span {
        font-size: 20px;
        padding: 20px;
        text-align: center;
        color: #b95858;
    }

    & > img {
        margin-top: 10px;
    }
`
