import { nanoid } from 'nanoid'
import { atom } from 'recoil'
import CustomCampaign, { ICustomCampaign } from '../../../models/CustomCampaign'
import View, { IView } from '../../../models/View'
import Campaign from '../../../types/Campaign'

export const campaignState = atom<ICustomCampaign | null>({
    key: 'campaignEditState',
    default: null,
})

export const campaignViewState = atom<IView[]>({
    key: 'campaignViewState',
    default: [new View(`Page 1`, nanoid(), '#FFFFFF', '', undefined, '', [], [])],
})
