import React from 'react'
import { CustomWalletButton } from './styles'

interface Props {
    getPass: () => Promise<void>
}

export const WalletButton = ({ getPass }: Props) => {
    return (
        <CustomWalletButton onClick={getPass}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img width={120} height={120} src="/images/add-to-apple-wallet-logo.png" alt="wallet" />
        </CustomWalletButton>
    )
}
