import React from 'react'
import { IWidget } from '../../../../models/Widget'

interface Props {
    widget?: IWidget
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    isPreview?: boolean
}

const CustomDigitalCard = ({
    widget,
    parentIndex,
    index,
    isPreview = false,
    updateActiveWidget,
}: Props) => {
    return <div>CustomDigitalCard</div>
}

export default CustomDigitalCard
