import React from 'react'
import styled from 'styled-components'

interface Props {
    children: React.ReactElement | React.ReactElement[]
}

const ContainerWrapper = ({ children }: Props) => {
    return <Container>{children}</Container>
}

export default ContainerWrapper

const Container = styled.div`
    width: 100%;
    max-width: 32em;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`
