import React from 'react'
import dynamic from 'next/dynamic'
import { IWidget } from '../../../../../models/Widget'
import { capitalizeFirstLetter } from '../../../../../utils/capitalizeFirstLetter'
import WidgetLoadSpinner from '../../../../Widgets/Layout/Loader'

interface Props {
    widget: IWidget
    index?: number
    parentIndex?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    deleteWidget?: (index: number) => void
    currentLayer?: number | null
    isPopup?: boolean
    isViewPreview?: boolean
}

const WidgetLoader = ({
    widget,
    index,
    parentIndex,
    updateActiveWidget,
    deleteWidget,
    currentLayer,
    isPopup,
    isViewPreview,
}: Props) => {
    const Preview = loadDynamicWidgetPreview(widget.category, widget.type, isViewPreview)

    return (
        <Preview
            widget={widget}
            index={index}
            parentIndex={parentIndex}
            updateActiveWidget={updateActiveWidget}
            deleteWidget={deleteWidget}
            currentLayer={currentLayer}
            isPopup={isPopup}
            isPreview={true}
            isViewPreview={isViewPreview}
        />
    )
}

const capitalizeAndRemoveSpace = (type: string): string => {
    let newType = type
    let modifiedType = newType?.replace(/\s/g, '')
    return capitalizeFirstLetter(modifiedType)
}

const getWidgetCategoryFromType = (type: string): string => {
    let widgetCategory = 'General'
    switch (type) {
        case 'checkbox':
        case 'input box':
        case 'text area':
        case 'submit':
        case 'calendar':
        case 'datepicker':
        case 'rating':
        case 'radio':
        case 'select':
        case 'upload':
            widgetCategory = 'Form'
        default:
            break
    }
    return widgetCategory
}

const loadDynamicWidgetPreview = (category: string, type: string, isViewPreview: boolean): any => {
    let widgetCategory =
        category !== undefined ? capitalizeFirstLetter(category) : getWidgetCategoryFromType(type)
    let modifiedType = capitalizeAndRemoveSpace(type)

    if (isViewPreview) {
        if (type === 'submit' || type === 'gamebutton') {
            return dynamic(() => import(`../../../../Widgets/General/Button/Button`), {
                ssr: false,
                loading: () => <WidgetLoadSpinner />,
            })
        }

        if (type === 'container' || type === 'form') {
            return dynamic(
                () => import(`../../../../Widgets/General/Container/ViewContainerPreview`),
                { ssr: false, loading: () => <WidgetLoadSpinner /> }
            )
        }

        return dynamic(
            () => import(`../../../../Widgets/${widgetCategory}/${modifiedType}/${modifiedType}`),
            { ssr: false, loading: () => <WidgetLoadSpinner /> }
        )
    }

    if (type === 'form') {
        return dynamic(() => import(`../../../../Widgets/General/Container/Preview`), {
            ssr: false,
            loading: () => <WidgetLoadSpinner />,
        })
    }

    if (type === 'submit' || type === 'gamebutton') {
        return dynamic(() => import(`../../../../Widgets/General/Button/Preview`), {
            ssr: false,
            loading: () => <WidgetLoadSpinner />,
        })
    }

    return dynamic(() => import(`../../../../Widgets/${widgetCategory}/${modifiedType}/Preview`), {
        ssr: false,
        loading: () => <WidgetLoadSpinner />,
    })
}

export default WidgetLoader
