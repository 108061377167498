import React, { memo, useState, MouseEvent } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import DatePicker from 'react-date-picker/dist/entry.nostyle'
import styled from 'styled-components'

import { useFormContext } from '../FormContainer/FormContainer'
import { widgetFormField } from '../../../../stores/widgets/forms/state'
import { IWidget } from '../../../../models/Widget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { EditingIndicator } from '../../EditingIndicator'

// import date picker styles
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

interface Props {
    widget?: IWidget
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
}

const DatePickerWidget = ({
    widget,
    isPreview = false,
    updateActiveWidget,
    index,
    parentIndex,
}: Props) => {
    const { formId } = useFormContext()
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    const label = widget.settings.label
    const required = widget.settings.required
    const name = widget.settings.name

    const setValue = useSetRecoilState(widgetFormField({ formId, fieldName: name }))

    const [selectedDate, setSelectedDate] = useState<Date | Date[] | null>(new Date())

    const handleDateChange = (date) => {
        setSelectedDate(date)
        setValue(date)
    }

    const width = widget.appearanceSettings.width + '%'
    const paddingTop = widget.appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = widget.appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = widget.appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = widget.appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = widget.appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = widget.appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft =
        widget.appearanceSettings.marginLeft / 10 === 0
            ? 'auto'
            : widget.appearanceSettings.marginLeft / 10 + 'em'
    const marginRight =
        widget.appearanceSettings.marginRight / 10 === 0
            ? 'auto'
            : widget.appearanceSettings.marginRight / 10 + 'em'
    const borderTopWidth = widget.appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = widget.appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = widget.appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = widget.appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const borderTopLeftRadius = widget.appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget.appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = widget.appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = widget.appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'
    const fontFamily = widget.appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = widget.appearanceSettings?.font?.url

    const color = Array.isArray(widget.colourSettings['text colour'])
        ? widget.colourSettings['text colour'][0]
        : widget.colourSettings['text colour']
    const borderColor = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']
    const labelColor = Array.isArray(widget.colourSettings['label colour'])
        ? widget.colourSettings['label colour'][0]
        : widget.colourSettings['label colour']
    const backgroundColor = Array.isArray(widget.colourSettings['background colour'])
        ? widget.colourSettings['background colour'][0]
        : widget.colourSettings['background colour']

    const alignment = widget?.appearanceSettings?.alignment
    const weight = widget?.appearanceSettings?.font?.weight
    const border = `${borderWidth} solid ${borderColor}`
    const letterSpacing = widget?.appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = widget?.appearanceSettings?.font?.lineHeight || 1

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            fontFamily={fontFamily}
            color={color}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <Container
                onClick={onEditMode}
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                fontSize={fontSize}
                weight={weight}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                border={border}
                backgroundColor={backgroundColor}
            >
                <label
                    className={`${
                        alignment === 'center'
                            ? 'self-center'
                            : alignment === 'left'
                            ? 'self-start'
                            : 'self-end'
                    }`}
                    style={{
                        fontSize: fontSize,
                        marginBottom: '0.5em',
                        color: labelColor,
                        width: '100%',
                        fontWeight: +weight,
                        letterSpacing,
                        lineHeight,
                    }}
                >
                    {label}
                </label>
                <DatePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                    required={required}
                    calendarClassName="width_80"
                />
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(DatePickerWidget)

const Wrapper = styled.div.attrs((props) => ({}))<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    fontFamily: string
    color: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    justify-content: center;
    width: 100%;
    color: ${(props) => props.color};
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    font-family: ${(props) => props.fontFamily};
`
const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    width: string
    fontSize: string
    paddingTop: string
    paddingBottom: string
    weight: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    border: string
    backgroundColor: string
    isPreview: boolean
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 20em;
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => +props.weight};
    width: ${(props) => props.width};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    .react-date-picker__wrapper {
        padding-top: ${(props) => props.paddingTop};
        padding-bottom: ${(props) => props.paddingBottom};
        padding-left: ${(props) => props.paddingLeft};
        padding-right: ${(props) => props.paddingRight};
        border: ${(props) => props.border};
        border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
        border-top-left-radius: ${(props) => props.borderTopLeftRadius};
        border-top-right-radius: ${(props) => props.borderTopRightRadius};
        border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
        background-color: ${(props) => props.backgroundColor};
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }

    .react-calendar {
        width: 100%;
        color: #000 !important;
        background: #cbd5e0;
    }
`
