export const verifyAttempt = async (
    campaignId: string,
    widgetId: string,
    count: number,
    period: string,
    totalAttempts: string | number
): Promise<boolean> => {
    try {
        const res = await fetch('/api/reward/updateAttempt', {
            method: 'POST',
            body: JSON.stringify({
                campaignId,
                widgetId,
                count,
                period,
                totalAttempts,
            }),
        })
        const data = await res.json()

        if (data.code !== 200) {
            throw new Error('invalid session')
        }

        return true
    } catch (error) {
        console.log(error)
        return false
    }
}
