import React from 'react'
import Loader from 'react-loader-spinner'

const WidgetLoadSpinner = () => {
    return (
        <div className="w-full h-full flex items-center justify-center">
            <Loader type="ThreeDots" color="#808080" height={25} width={25} timeout={100} />
        </div>
    )
}

export default WidgetLoadSpinner
