import { nanoid } from 'nanoid'
import { Reward } from './Reward'

export interface IFlipSegment {
    id: string
    image: string
    imageName: string
    text: string
    isFlipped: boolean
    reward?: Reward
    postGame?: {
        viewIndex: number
        layerIndex: number
    }
}

export class FlipSegment implements IFlipSegment {
    id = nanoid()
    image = ''
    imageName = ''
    text = ''
    isFlipped = false
    reward = {
        _id: '',
        rewardType: '',
        name: '',
        allocatedQuantity: 0,
        quantity: 0,
        isReward: false,
        probability: 0,
    }
    postGame = {
        viewIndex: 0,
        layerIndex: -1,
    }

    constructor(text: string) {
        this.text = text
    }
}
