import React, { memo, MouseEvent } from 'react'
import { useQueryClient } from 'react-query'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { IWidget } from '../../../../models/Widget'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { IBrandingSettings } from '../../../../types/Branding'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget?: IWidget
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    isPreview?: boolean
}

const Richtext = ({ widget, updateActiveWidget, index, parentIndex, isPreview = false }: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)
    const queryClient = useQueryClient()
    const branding = queryClient.getQueryData<IBrandingSettings>('branding')
    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    let appearanceSettings = widget?.appearanceSettings
    let colourSettings = widget?.colourSettings
    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget?.settings,
        }
    }
    if (!colourSettings) {
        colourSettings = {
            textColour: widget?.settings?.textColour,
        }
    }

    const width = appearanceSettings?.width + '%'
    const fontSize = appearanceSettings ? appearanceSettings.font.size + 'px' : '14px'
    const align = appearanceSettings?.align
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.marginRight / 10 + 'em'
    let content = widget?.settings?.content
    const weight = appearanceSettings?.font?.weight || 300

    content = content?.replace(/<ul>/g, "<ul style='list-style-type: disc;' >")
    content = content?.replace(/<ol>/g, "<ol style='list-style-type: decimal;' >")

    const fontFamily = appearanceSettings?.font?.family || 'Poppins'

    const color = Array.isArray(colourSettings?.textColour)
        ? colourSettings?.textColour[0]
        : colourSettings?.textColour
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url || branding?.defaultFont?.url || ''
    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    return (
        <Wrapper>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                letterSpacing={letterSpacing}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                onClick={onEditMode}
            >
                <div
                    className="header-text"
                    tabIndex={0}
                    style={{
                        margin:
                            align === 'left'
                                ? 'auto 0 0 0'
                                : align === 'center'
                                ? '0 auto'
                                : '0 0 0 auto',
                        fontSize,
                        fontFamily: fontFamily,
                        fontWeight: Number(weight),
                        color,
                        width: '100%',
                        textAlign: align,
                        lineHeight,
                    }}
                    dangerouslySetInnerHTML={{ __html: content }}
                ></div>
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Richtext)

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`
const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
    width: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    letterSpacing: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
}>`
    position: relative;
    cursor: pointer;
    width: ${(props) => props.width};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    padding-top: ${(props) => props.paddingTop};
    padding-left: ${(props) => props.paddingLeft};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-right: ${(props) => props.paddingRight};

    .header-text {
        p,
        pre,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            letter-spacing: ${(props) => props.letterSpacing};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }
`
