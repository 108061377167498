import { validateSingleInput } from './validateSingleInput'

export const checkFileTypes = (files: FileList, fileType: string) => {
    let isValid = []
    for (let i = 0; i < files.length; i++) {
        let validFileExtensions = []
        let valid = true

        switch (fileType) {
            case 'image':
                validFileExtensions = ['jpeg', 'png', 'gif', 'webp', 'svg+xml', 'svg', 'jpg']
                break
            case 'audio':
                validFileExtensions = ['mpeg', 'ogg', 'mp3', 'wav']
                break
            case 'video':
                validFileExtensions = ['mp4', 'webm', 'ogg', 'wmv', 'mov', 'avi', 'mpeg', 'mpg']
                break
            case 'document':
                validFileExtensions = [
                    'doc',
                    'csv',
                    'pdf',
                    'docx',
                    'txt',
                    'ppt',
                    'xlsx',
                    'xlsm',
                    'pptx',
                ]
                break
            default:
                break
        }

        valid = validateSingleInput(files[i], validFileExtensions)

        if (!valid) {
            isValid.push(valid)
        }
    }

    return isValid
}
