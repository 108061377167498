import React, { memo, MouseEvent } from 'react'
import styled from 'styled-components'
import { Navigation, Pagination, Keyboard, Autoplay, EffectFade, Parallax } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useRecoilState } from 'recoil'

import { IWidget } from '../../../../models/Widget'
import { ISliderSlides } from '../../../../models/SliderSlides'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { EditingIndicator } from '../../EditingIndicator'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

interface SliderPropsTypes {
    widget?: IWidget
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    isPreview?: boolean
}

const Slider = ({
    widget,
    updateActiveWidget,
    index,
    parentIndex,
    isPreview = false,
}: SliderPropsTypes) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    const colorSettings = widget?.colourSettings
    let appearanceSettings = widget?.appearanceSettings
    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget?.settings,
        }
    }

    const width = appearanceSettings?.width + '%'
    const height = appearanceSettings?.height ? appearanceSettings?.height + '%' : '100%'
    const textAlign = appearanceSettings?.font?.align
    const varticalAlign = appearanceSettings?.font?.verticalAlign

    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'

    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'

    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const titleFontSize = appearanceSettings?.font?.size?.title / 10 + 'em'
    const subtitleFontSize = appearanceSettings?.font?.size?.subtitle / 10 + 'em'
    const textFontSize = appearanceSettings ? appearanceSettings.font.size + 'px' : '14px'
    const fontUrl = appearanceSettings?.font?.url

    const arrowsColour = Array.isArray(colorSettings['arrows colour'])
        ? colorSettings['arrows colour']?.[0]
        : colorSettings['arrows colour'] || '#fff'
    const dotsColour = Array.isArray(colorSettings['dots colour'])
        ? colorSettings['dots colour']?.[0]
        : colorSettings['dots colour'] || '#fff'
    const textColour = Array.isArray(colorSettings['text colour'])
        ? colorSettings['text colour']?.[0]
        : colorSettings['text colour'] || '#fff'

    return (
        <Container>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            {widget && (
                <Wrapper
                    onClick={onEditMode}
                    isActive={isActive}
                    isPreview={isPreview}
                    width={width}
                    textAlign={textAlign}
                    paddingTop={paddingTop}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                    marginTop={marginTop}
                    marginBottom={marginBottom}
                    marginLeft={marginLeft}
                    marginRight={marginRight}
                    height={height}
                    textColour={textColour}
                    dotsColour={dotsColour}
                    arrowsColour={arrowsColour}
                    fontFamily={fontFamily}
                    titleFontSize={titleFontSize}
                    subtitleFontSize={subtitleFontSize}
                    textFontSize={textFontSize}
                    borderTopLeftRadius={borderTopLeftRadius}
                    borderBottomLeftRadius={borderBottomLeftRadius}
                    borderTopRightRadius={borderTopRightRadius}
                    borderBottomRightRadius={borderBottomRightRadius}
                    description={widget?.settings?.slideDescription}
                    varticalAlign={varticalAlign}
                >
                    <Swiper
                        slidesPerView={widget?.settings?.slidesToShow}
                        effect={widget?.settings?.fadeEffect ? 'fade' : 'slide'}
                        keyboard={{
                            enabled: true,
                        }}
                        pagination={
                            widget?.settings?.pagination && {
                                clickable: true,
                            }
                        }
                        autoplay={
                            widget?.settings?.autoplay && {
                                delay: 2500,
                                disableOnInteraction: false,
                            }
                        }
                        parallax={widget?.settings?.parallax?.active}
                        speed={widget?.settings?.speed}
                        navigation={widget?.settings?.navigation}
                        modules={[Keyboard, Pagination, Navigation, Autoplay, EffectFade, Parallax]}
                        className="mySwiper"
                    >
                        {widget?.settings?.slides &&
                            widget?.settings?.slides?.map(
                                (slide: ISliderSlides, index: React.Key) => (
                                    <SwiperSlide key={slide?.id}>
                                        <div className="description">
                                            <div className="title" data-swiper-parallax="-300">
                                                {slide?.title}
                                            </div>
                                            <div className="subtitle" data-swiper-parallax="-200">
                                                {slide?.subtitle}
                                            </div>
                                            <div className="text" data-swiper-parallax="-700">
                                                <p>{slide?.text}</p>
                                            </div>
                                        </div>
                                        {/* eslint-disable-next-line @next/next/no-img-element */}
                                        <img src={slide?.path} alt={`slide ${index}`} />
                                    </SwiperSlide>
                                )
                            )}
                    </Swiper>
                    {isPreview && <EditingIndicator widgetName={widgetName} />}
                </Wrapper>
            )}
        </Container>
    )
}

export default memo(Slider)

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Wrapper = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
    width: string
    textAlign: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    height: string
    textColour: string
    fontFamily: string
    titleFontSize: string
    subtitleFontSize: string
    textFontSize: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    dotsColour: string
    arrowsColour: string
    description: boolean
    varticalAlign: string
}>`
    width: ${(props) => props.width};
    position: relative;
    height: ${(props) => props.height};
    cursor: pointer;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    .swiper-wrapper {
        height: inherit;
    }
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        color: #fff;
        padding-top: ${(props) => props.paddingTop};
        padding-left: ${(props) => props.paddingLeft};
        padding-bottom: ${(props) => props.paddingBottom};
        padding-right: ${(props) => props.paddingRight};
        height: initial;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
        border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
        border-top-left-radius: ${(props) => props.borderTopLeftRadius};
        border-top-right-radius: ${(props) => props.borderTopRightRadius};
        object-fit: cover;
        object-position: 1 0;
    }

    .swiper-slide .description {
        display: ${(props) => (props.description ? 'flex' : 'none')};
        flex-direction: column;
        height: 90%;
        position: absolute;
        justify-content: ${(props) => props.varticalAlign};
        font-family: ${(props) => props.fontFamily};
        margin: 15px;
        color: ${(props) => props.textColour};
        text-align: ${(props) => props.textAlign};
    }

    .swiper-slide .title {
        font-size: ${(props) => props.titleFontSize};
    }

    .swiper-slide .subtitle {
        font-size: ${(props) => props.subtitleFontSize};
        margin-bottom: 10px;
    }

    .swiper-slide .text {
        font-size: ${(props) => props.textFontSize};
        max-width: 400px;
        line-height: 1.3;
    }

    .parallax-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 130%;
        height: 100%;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center;
    }

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
        background-color: ${(props) => props.dotsColour};
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: ${(props) => props.arrowsColour};
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-left: ${(props) => props.paddingLeft};
        margin-right: ${(props) => props.paddingRight};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            pointer-events: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }
`
