import { nanoid } from 'nanoid'
import DefaultFont from '../types/DefaultFont'
import QRCode from './QRCode'
import { IView } from './View'

export interface ICustomCampaign {
    templateId: null | string
    templateName: null | string
    templateCategory: string
    templateThumbnail: string
    isPublicTemplate: boolean
    status: string
    editMode: boolean
    creatorUID: string
    campaignId: string
    campaignName: string
    campaignDescription: string
    metaImageUrl: any
    metaImageFile: File | null
    deployQRUrl: string
    deployUrl: string
    redirect: boolean
    redirectUrl: string
    origin: string
    createdAt: string
    lastModified: string | null
    scans: number
    fontFamilies: DefaultFont[]
    views: IView[]
    screenshot?: string
    shortUrl?: string
    uid?: string
    analytics: {
        googleAnalytics: {
            enabled: boolean
            trackingId: string
        }
        tagManagerSettings: {
            enabled: boolean
            appName: string
            containerId: string
            dataLayerName: string
        }
    }
    qrcode: {
        logoFileName: string
        value: any
        ecLevel: any
        enableCORS: boolean
        size: number
        quietZone: number
        bgColor: string
        fgColor: string
        logoImage: any
        logoWidth: number
        logoHeight: number
        logoOpacity: number
        qrStyle: any
        file: undefined | File
    }
    migrationCampaignId?: string
    migratorCampaignId?: string
    from_template?: boolean
}

class Campaign implements ICustomCampaign {
    constructor(
        public creatorUID: string,
        public origin: string,
        public fontFamilies: any[],
        public views: any[],
        public thumbnail: string
    ) {
        this.creatorUID = creatorUID
        this.origin = origin
        this.fontFamilies = fontFamilies
        this.views = views
        this.thumbnail = thumbnail
    }
    campaignId = nanoid()
    campaignName = 'Unnamed campaign'
    campaignDescription = ''
    metaImageUrl = ''
    metaImageFile = null
    screenshot = ''
    redirect = false
    redirectUrl = ''
    templateId = ''
    templateName = ''
    templateCategory = ''
    templateThumbnail = `${this.thumbnail}`
    isPublicTemplate = false
    status = 'draft'
    editMode = false
    createdAt = new Date().toISOString()
    lastModified = new Date().toISOString()
    scans = 0
    deployQRUrl = ''
    deployUrl = `${this.origin}/live/${this.campaignId}`
    analytics = {
        googleAnalytics: {
            enabled: false,
            trackingId: '',
        },
        tagManagerSettings: {
            enabled: false,
            appName: 'deploy-link',
            containerId: 'GTM-',
            dataLayerName: 'customDataLayer',
        },
    }
    qrcode = new QRCode(
        '',
        'M',
        true,
        200,
        10,
        'rgba(0,0,0,0)',
        '#000000',
        '',
        50,
        50,
        0.5,
        'squares',
        undefined
    )
}

export default Campaign
