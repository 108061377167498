import React from 'react'
import styled from 'styled-components'

import Widget from '../../../../types/Widget'
import { IButtonSettings } from '../../../../models/ButtonSettings'
import { useButtonAction } from '../../../../lib/hooks/useButtonAction'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget: Widget
    action: IButtonSettings
    isPreview: boolean
    widgetName: string
    onEditMode: (e) => void
    isActiveButton: boolean
}

const DefaultButton = ({
    widget,
    action,
    isPreview,
    widgetName,
    onEditMode,
    isActiveButton,
}: Props) => {
    const handleAction = useButtonAction(action)

    let appearanceSettings = widget.appearanceSettings
    let colourSettings = widget.colourSettings

    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget.settings,
        }
    }

    if (!colourSettings) {
        colourSettings = {
            'text colour': widget.settings.textColour,
            'button colour': widget.settings.buttonColour,
            'border colour': widget.settings.borderColour,
        }
    }

    const width = appearanceSettings.width + '%'
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const fontFamily = appearanceSettings.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url
    const fontSize = widget?.appearanceSettings?.font?.size + 'px'
    const weight = appearanceSettings?.font?.weight

    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'

    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`

    const color = Array.isArray(colourSettings['text colour'])
        ? colourSettings['text colour'][0]
        : colourSettings['text colour']
    const backgroundColor = Array.isArray(colourSettings['button colour'])
        ? colourSettings['button colour'][0]
        : colourSettings['button colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']

    const handleClickOn = () => {
        handleAction.action()
    }

    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    return (
        <Container
            isActiveButton={isActiveButton}
            isPreview={isPreview}
            width={width}
            borderTopLeftRadius={borderTopLeftRadius}
            borderBottomLeftRadius={borderBottomLeftRadius}
            borderTopRightRadius={borderTopRightRadius}
            borderBottomRightRadius={borderBottomRightRadius}
            onClick={onEditMode}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <button
                type="button"
                onClick={handleClickOn}
                style={{
                    paddingTop,
                    paddingBottom,
                    paddingLeft,
                    paddingRight,
                    fontFamily,
                    backgroundColor,
                    fontWeight: weight,
                    color,
                    margin: `0 0`,
                    borderWidth,
                    borderColor,
                    fontSize,
                    letterSpacing,
                    lineHeight,
                }}
                className={`focus:outline-none ${isPreview && 'pointer-events-none'}`}
            >
                {action.linkTitle}
            </button>
            {isPreview && <EditingIndicator widgetName={widgetName} />}
        </Container>
    )
}

export default DefaultButton

const Container = styled.div.attrs((props) => ({}))<{
    isActiveButton: boolean
    width: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    isPreview: boolean
}>`
    position: relative;
    cursor: pointer;
    width: ${(props) => props.width};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    button {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActiveButton && '1px solid rgb(58, 226, 155)'};
        }
    }

    .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActiveButton ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActiveButton ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
