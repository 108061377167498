import BaseInput from './BaseInput'

class FormContainer extends BaseInput {
    constructor() {
        super()
        this.icon = '/icons/widgets/form.svg'
        this.widgets = []
        this.name = 'form'
        this.type = 'form'
        this.category = 'form'
        this.settings = {
            name: 'form',
        }
        this.colourSettings = {
            'border colour': ['#000', '#FFA7E1', '#0426FF'],
            'background colour': ['#fff', '#FFA7E1', '#0426FF'],
        }
        this.appearanceSettings = {
            border: true,
            borderRadius: true,
            margin: true,
            padding: true,
            font: {
                family: 'Poppins',
            },
            width: 90,
            height: 0,
            'font size': 8,
            alignment: 'center',
            spacing: 5,
            columns: 1,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            backgroundImageUrl: '',
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 5,
            paddingRight: 5,
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 0,
            marginRight: 0,
        }
    }
}

export default FormContainer
