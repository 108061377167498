import { atom, atomFamily, selectorFamily } from 'recoil'
import SubmitButton, { ISubmitButton } from '../../../models/Forms/SubmitButton'

export interface Fields {
    id: string
    fieldName: string
    value?: any
    required: boolean
    fieldType: string
    inputType?: string
    fieldLabel: string
    defaultValue: any
}

export interface FormFields {
    campaignId: string
    schemaVersion: number
    formId: string
    formName: string
    formType: string
    fields: Fields[]
}

export interface IEmailSetting {
    backgroundColour: string
    backgroundFile: null | string
    backgroundFileName: string
    bannerImageFile: null | string
    bannerImageFileName: string
    bannerImageUrl: string
    confirmation: boolean
    content: string
    email: string
    fontFamily: string
    fontUrl: string
    social: {
        linkedin: string
        facebook: string
        instagram: string
        twitter: string
        youtube: string
    }
    subject: string
    textColour: string
    width: number
}

export const widgetFormState = atomFamily<FormFields, string>({
    key: 'formState',
    default: {
        campaignId: '',
        schemaVersion: 1,
        formId: '',
        formType: 'form',
        formName: 'form',
        fields: [],
    },
})

export const widgetEmailState = atomFamily<string, string>({
    key: 'widgetFormEmail',
    default: '',
})

export const widgetFormField = selectorFamily<any, { formId: string; fieldName: string }>({
    key: 'widgetFormField',
    get:
        ({ formId, fieldName }) =>
        ({ get }) => {
            const formState: FormFields = get(widgetFormState(formId))
            if (!formState.fields) return
            const inputIndex = formState.fields.findIndex((field) => field.fieldName === fieldName)
            if (inputIndex > -1) {
                return formState.fields[inputIndex].value
            }
            return ''
        },
    set:
        ({ formId, fieldName }) =>
        ({ set }, value) => {
            const formState = widgetFormState(formId)
            set(formState, (prevState) => {
                if (!prevState?.fields) return
                const inputIndex = prevState.fields.findIndex(
                    (field) => field.fieldName === fieldName
                )

                if (inputIndex == -1) return
                const field = prevState.fields[inputIndex]

                const newField = {
                    ...field,
                    value,
                }

                const fields = [
                    ...prevState.fields.slice(0, inputIndex),
                    newField,
                    ...prevState.fields.slice(inputIndex + 1),
                ]

                return { ...prevState, fields }
            })
        },
})

export const widgetFormEmailState = atomFamily<IEmailSetting, string>({
    key: 'widgetFormEmail',
    default: {
        backgroundColour: '#CBFEF2',
        backgroundFile: null,
        backgroundFileName: '',
        bannerImageFile: null,
        bannerImageFileName: '',
        bannerImageUrl:
            'https://marketing-image-production.s3.amazonaws.com/uploads/22ce43266efffe3ac9ec97f23349591fda87e67585bf5bdd298c5579ccc95653f12a980813de484f662580b5c67797ce40049968c443763998a867fe5639edf4.png',
        confirmation: false,
        content:
            '<p>Hello!</p><p>&nbsp;</p><p>We have successfully received your form submission.</p><p>&nbsp;</p><p> Thanks!</p>',
        email: '',
        fontFamily: 'Poppins',
        fontUrl:
            '@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);',
        social: {
            linkedin: 'https://pinterest.com',
            facebook: 'https://www.facebook.com',
            instagram: 'https://pinterest.com',
            twitter: 'https://www.instagram.com',
            youtube: 'https://www.youtube.com',
        },
        subject: 'Form Submission Confirmation',
        textColour: 'black',
        width: 90,
    },
})

export const widgetFormSubmitButtonState = atomFamily<ISubmitButton, string>({
    key: 'widgetFormSubmitButton',
    default: new SubmitButton(),
})
