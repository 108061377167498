import { nanoid } from 'nanoid'
import { IWidget } from '../Widget'
import { FlipSegment } from './FlipSegment'
import { ISegment } from './Segment'

export interface IFlipCard extends IWidget {
    settings: {
        foregroundImage: string
        foregroundImageName: string
        segmentData: ISegment[]
        chooseCard: number
        losingText: string
        winnerText: string
        redirect: string
        attempts: {
            count: number
            period: string
            totalAttempts: number | string
        }
        resetAttempt: {
            enabled: boolean
            time: number
            period: string
            day: number
            date: number | string
        }
        probabilityTotal: number
    }
    appearanceSettings: {
        width: number
        marginTop: number
        marginBottom: number
        marginLeft: number
        marginRight: number
        paddingTop: number
        paddingBottom: number
        paddingLeft: number
        paddingRight: number
        borderTopLeftRadius: number
        borderTopRightRadius: number
        borderBottomLeftRadius: number
        borderBottomRightRadius: number
        borderTopWidth: number
        borderBottomWidth: number
        borderLeftWidth: number
        borderRightWidth: number
        font: {
            family: string
            weight: number
        }
        fontSize: number
    }
    colourSettings: {
        'background colour': string[]
        'border colour': string[]
    }
}

class Flipcard implements IFlipCard {
    id = nanoid()
    name = 'flip card'
    type = 'flipcard'
    category = 'game'
    icon = '/icons/widgets/flipcard.png'
    settings = {
        redirect: '',
        foregroundImage: '/icons/widgets/flip/flip.svg',
        foregroundImageName: 'custom',
        chooseCard: 2,
        losingText: 'Sorry but better luck next time',
        winnerText: 'Congratulations you’re a winner!',
        segmentData: [
            new FlipSegment('Card 1'),
            new FlipSegment('Card 2'),
            new FlipSegment('Card 3'),
            new FlipSegment('Card 4'),
            new FlipSegment('Card 5'),
            new FlipSegment('Card 6'),
            new FlipSegment('Card 7'),
            new FlipSegment('Card 8'),
            new FlipSegment('Card 9'),
        ],
        attempts: {
            count: 1,
            period: 'day',
            totalAttempts: 'unlimited',
        },
        resetAttempt: {
            enabled: false,
            time: 0,
            period: 'day',
            day: 1,
            date: 'start',
        },
        probabilityTotal: 0,
    }
    appearanceSettings = {
        width: 100,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 0,
        marginRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        font: {
            family: 'Poppins',
            weight: 400,
        },
        fontSize: 10,
    }
    colourSettings = {
        'background colour': ['rgba(0,0,0,0)', '#FFA7E1', '#0426FF'],
        'border colour': ['rgba(0,0,0,0)', '#FFA7E1', '#0426FF'],
    }
}

export default Flipcard
