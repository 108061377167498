import { postCaptchaToken } from "./postToken";

export const handleRecaptchaToken = async (recaptchaRef, formId: string):Promise<void> => {
    try {
        const recaptchaToken = await recaptchaRef.current.executeAsync();
        const status = await postCaptchaToken(recaptchaToken, formId)
            
        if(!status){
            recaptchaRef.current.reset()
            throw new Error('invalid recaptcha token')
        }
    } catch (error){
        throw new Error(error.message)
    }

}