import { IWidget } from '../models/Widget'

export const countdownTimeCalculate = (widget: IWidget, lastAttemptTime: Date) => {
    let speenPeriod = new Date(lastAttemptTime)
    switch (widget?.settings?.attempts?.period) {
        case 'hour': {
            speenPeriod.setHours(lastAttemptTime.getHours() + 1)
            break
        }
        case 'day': {
            speenPeriod.setDate(lastAttemptTime.getDate() + 1)
            break
        }
        case 'week': {
            speenPeriod.setDate(lastAttemptTime.getDate() + 7)
            break
        }
        case 'month': {
            speenPeriod.setMonth(lastAttemptTime.getMonth() + 1)
            break
        }
        default: {
            break
        }
    }
    return speenPeriod.getTime()
}
