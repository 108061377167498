export const returnAcceptedFileString = (fileType: string):string => {
    let fileTypeString = "image/*"
    
    switch(fileType){
        case "video":
            fileTypeString = "video/*"
            break
        case "audio":
            fileTypeString = "audio/*"
            break
        case "doc":
            fileTypeString = ".doc,.docx,.ppt, .pptx,.txt,.pdf,.xlsx, .xlsm, .csv"
            break
        default:
            fileTypeString = ""
            break
    }

    return fileTypeString
}