import {atomFamily} from 'recoil'

interface SubmitButtonActionSettings {
    isClaimPrize: boolean
    isSendEmail: boolean
    isSendSMS: boolean
}


export const submitButtonState = atomFamily<boolean, string>({
    key: "submitButton",
    default: false
})


export const submitButtonActionSettingState = atomFamily<SubmitButtonActionSettings, string>({
    key: "submitButtonActionSetting",
    default: {
        isClaimPrize: false,
        isSendEmail: false,
        isSendSMS: false
    }
})

export const handleGameSelectorState = atomFamily<string, string>({
    key: 'handleGameSelector',
    default: ""
})

