import { CustomerAuth } from "../../../stores/auth/customer/customerAuth"
import {PromiseFetch} from "../../hooks/fetch/promiseFetch"

export const postIdToken = async (token:string):Promise<CustomerAuth> => {
    try {
        if(!token || token === "") throw new Error('missing token')
        const user = await PromiseFetch("POST", "/api/auth/social/google", {token})
        return user
    } catch (error){
        throw new Error(error.message)
    }
}   