import { motion } from 'framer-motion'
import React, { useState } from 'react'
import ReactCardFlip from 'react-card-flip'
import styled from 'styled-components'
import { IFlipCard } from '../../../../models/Games/Flipcard'
import { IFlipSegment } from '../../../../models/Games/FlipSegment'
import { IWidget } from '../../../../models/Widget'

interface Props {
    data: IFlipSegment
    isFlipped: boolean
    widget: IFlipCard | IWidget
    handleSelectCard?: any
}

const Card = ({ data, isFlipped, widget, handleSelectCard }: Props) => {
    const [isFlippedCard, setFlippedCard] = useState(isFlipped)

    const onSelect = (data: IFlipSegment) => () => {
        setFlippedCard((prev) => !prev)
        handleSelectCard(data)
    }

    const teaserVariants = {
        hidden: { opacity: 0 },
        show: { opacity: 1 },
    }

    return (
        <motion.div variants={teaserVariants}>
            <ReactCardFlip isFlipped={isFlippedCard}>
                <Front id="card" className="react-card-front" onClick={onSelect(data)}>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                        src={widget?.settings?.foregroundImage}
                        alt={widget?.settings?.foregroundImageName || 'foreground'}
                    />
                </Front>
                {isFlippedCard && (
                    <Back className="react-card-back">
                        {data.image ? (
                            <>
                                {/* eslint-disable-next-line @next/next/no-img-element */}
                                <img src={data.image} alt={data.imageName} />
                                <span>{data.text}</span>
                            </>
                        ) : (
                            <span>{data.reward.name || 'Better luck'}</span>
                        )}
                    </Back>
                )}
            </ReactCardFlip>
        </motion.div>
    )
}

export default Card

const Front = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    img {
        width: 100%;
    }
`
const Back = styled.div`
    background-color: #fff;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0.5em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 600;
    img {
        border-radius: 0.5em;
    }
`
