import React, { memo, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled, { keyframes } from 'styled-components'
import { motion } from 'framer-motion'

import { IFlipCard } from '../../../../models/Games/Flipcard'
import { IWidget } from '../../../../models/Widget'
import { customerAuth } from '../../../../stores/auth/customer/customerAuth'
import { loginModal } from '../../../../stores/auth/customer/loginModal'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { liveCampaignState } from '../../../../stores/campaign/live/state'
import { gameAttemptAvailable } from '../../../../stores/widgets/game/state'
import { EditingIndicator } from '../../EditingIndicator'
import { Toast } from '../../../../helpers/Toast'
import Card from './Card'
import { IFlipSegment } from '../../../../models/Games/FlipSegment'
import Modal from '../Modal'
import ModalContent from '../ModalContent'
import { verifyAttempt } from '../../../../lib/reward/session/verifyAttempt'
import { useAnalytics } from '../../../../lib/analytics/useAnalytics'
import { useCountdown } from '../../../../lib/hooks/useCountdown'
import { countdownTimeCalculate } from '../../../../utils/countdownTime'
import CountdownAttempt from '../CountdownAttempt'

interface Props {
    widget: IFlipCard | any
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: any) => void
}

const Flipcard = ({ widget, isPreview = false, updateActiveWidget, index, parentIndex }: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)
    const campaign = useRecoilValue(liveCampaignState)
    const _customerAuth = useRecoilValue(customerAuth)
    const setAuthVisible = useSetRecoilState(loginModal)
    const setAttemptAvailable = useSetRecoilState(gameAttemptAvailable(widget?.id))
    const [loading, setLoading] = useState(false)
    const [choosenCard, setChoosenCard] = useState<IFlipSegment[]>([])
    const [numberOfAttempt, setNumberOfAttempt] = useState<number>(widget?.settings?.chooseCard)
    const [isOpen, setIsOpen] = useState(false)
    const [shuffledCards, setShuffledCards] = useState([])
    const [lastAttemptTime, setLastAttemptTime] = useState(null)

    const handleToggleModal = useCallback(() => {
        setIsOpen((prev) => !prev)
    }, [])

    const analytics = useAnalytics('live')

    useEffect(() => {
        const shuffleCard = (array: IFlipSegment[]) => {
            const copyArray = [...array]
            let currentIndex = copyArray.length,
                randomIndex

            // While there remain cards to shuffle.
            while (currentIndex !== 0) {
                // Pick a remaining card.
                randomIndex = Math.floor(Math.random() * currentIndex)
                currentIndex--

                // And swap it with the current card.
                ;[copyArray[currentIndex], copyArray[randomIndex]] = [
                    copyArray[randomIndex],
                    copyArray[currentIndex],
                ]
            }
            setShuffledCards(copyArray)
            return copyArray
        }

        shuffleCard(widget?.settings?.segmentData)
    }, [widget?.settings?.segmentData])

    useEffect(() => {
        if (!_customerAuth.userId) {
            setAuthVisible(true)
            return
        }

        async function calculateReward(gameWidget: IWidget, campaignId: string) {
            setLoading(true)
            const res = await fetch('/api/reward/calculate', {
                method: 'POST',
                body: JSON.stringify({ campaignId, gameWidget }),
            })

            const result = await res.json()

            if (result.message === 'exceeded tries' && result.data.lastAttemptTime) {
                const timeToNextGame = countdownTimeCalculate(
                    gameWidget,
                    new Date(result.data.lastAttemptTime * 1000)
                )
                setLastAttemptTime(timeToNextGame)
            }

            if (result.code !== 200) {
                setNumberOfAttempt(null)
                Toast('No more attempts for flip card', 'error')
                setLoading(false)
                return
            }
            setAttemptAvailable(true)
            setLoading(false)
        }

        if (!isPreview) {
            calculateReward(widget, campaign?.campaignId)
        }
    }, [_customerAuth.userId, campaign?.campaignId, shuffledCards])

    useEffect(() => {
        if (numberOfAttempt === 0) {
            trackProgress()
            setIsOpen(true)
            setAttemptAvailable(false)
        }
    }, [numberOfAttempt])

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'
    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings

    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const fontUrl = appearanceSettings?.font?.url
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontSize = appearanceSettings?.fontSize
    const fontWeight = appearanceSettings?.font?.weight
    const width = appearanceSettings?.width + '%'
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const borderWidth =
        appearanceSettings?.borderTopWidth / 10 + 'em' &&
        appearanceSettings?.borderBottomWidth / 10 + 'em' &&
        appearanceSettings?.borderBottomWidth / 10 + 'em' &&
        appearanceSettings?.borderRightWidth / 10 + 'em'

    const backgroundColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']
    const border = `${borderWidth} solid ${borderColor}`

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    const handleSelectCard = (card: IFlipSegment) => {
        setChoosenCard((prev) => [...prev, card])
        setNumberOfAttempt((prev) => prev - 1)
    }

    const isWinnigRewards = choosenCard.filter((card) => card.reward.isReward === true)

    const trackProgress = async (): Promise<void> => {
        await verifyAttempt(
            campaign.campaignId,
            widget.id,
            widget.settings.attempts.count,
            widget.settings.attempts.period,
            widget.settings.attempts.totalAttempts
        )
        analytics.track('start game', { game: widget.name })
    }

    const containerVariants = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    }

    return (
        <Wrapper onClick={onEditMode}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                loading={loading}
                border={border}
                backgroundColor={backgroundColor}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
            >
                <CardItems
                    attempt={numberOfAttempt}
                    variants={containerVariants}
                    initial="hidden"
                    animate="show"
                >
                    {shuffledCards?.map((card: IFlipSegment) => (
                        <Card
                            key={card.id}
                            data={card}
                            isFlipped={card.isFlipped}
                            widget={widget}
                            handleSelectCard={handleSelectCard}
                        />
                    ))}
                </CardItems>
                {loading ? (
                    <Loader font={fontFamily}>Loading...</Loader>
                ) : (
                    <ChooseCard font={fontFamily} fontSize={fontSize} fontWeight={fontWeight}>
                        Choose: <span>{numberOfAttempt ? numberOfAttempt : 0}</span>
                    </ChooseCard>
                )}

                {lastAttemptTime && !loading && _customerAuth?.userId && (
                    <CountdownAttempt
                        lastAttemptTime={lastAttemptTime}
                        fontFamily={fontFamily}
                        color="#2d3748"
                    />
                )}

                {isPreview && <EditingIndicator widgetName={widgetName} />}

                <Modal isOpen={isOpen}>
                    <ModalContent
                        reward={isWinnigRewards}
                        widget={widget}
                        userData={_customerAuth}
                        handleToggleModal={handleToggleModal}
                        setIsOpen={setIsOpen}
                    />
                </Modal>
            </Container>
        </Wrapper>
    )
}

export default memo(Flipcard)

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Loader = styled.span<{
    font: string
}>`
    width: 100%;
    margin: 10px 0;
    text-align: center;
    font-family: ${(props) => props.font};
    font-size: 19px;
`

export const CardItems = styled(motion.div)<{
    attempt: number
}>`
    width: 100%;
    display: grid;
    grid-gap: 0.5em;
    pointer-events: ${(props) => (props.attempt ? 'auto' : 'none')};
    grid-template-columns: repeat(3, 1fr);
    .react-card-front,
    .react-card-back {
        width: 100%;
        height: 100%;
        border-radius: 1em;
    }
    .react-card-flip {
        height: 100%;
    }
`

const pulse = keyframes`
    0% {
      -webkit-transform: scale(0.8, 0.8);
      transform: scale(0.8, 0.8)
    }
    50% {
      -webkit-transform: scale(1.0, 1.0);
      transform: scale(1.0, 1.0);
    }
    100% {
      -webkit-transform: scale(0.8, 0.8);
      transform: scale(0.8, 0.8);
    }
`
const ChooseCard = styled.span<{
    font: string
    fontSize: number
    fontWeight: number
}>`
    margin: 1em auto;
    text-align: center;
    display: flex;
    align-items: center;
    width: max-content;
    font-family: ${(props) => props.font};
    font-size: ${(props) => props.fontSize + 'px'};
    font-weight: ${(props) => props.fontWeight};
    background-color: #fff;
    padding: 0.2em;
    border-radius: 1.5em;
    span {
        margin-left: 0.2em;
        font-size: 2.1em;
        animation: ${pulse} 2s infinite;
    }
`

const Container = styled.div<{
    isActive: boolean
    isPreview: boolean
    loading: boolean
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    backgroundColor: string
    border: string
}>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: min-content;
    width: ${(props) => props.width};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
    position: relative;
    border: ${(props) => props.border};
    background-color: ${(props) => props.backgroundColor};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
