import styled from 'styled-components'

export const Button = styled.button`
    background-color: white;
    padding: 0.5rem 1rem;
    color: grey; 
    font-size: 14px;
    font-weight: 500;
    width: 200px;
    box-shadow: 0px 1px 2px grey;
    border: 2px solid transparent;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0.3rem 0;
    
    :focus, :hover {
        outline: none;
        border: 2px solid lightblue;
    }
`
