import { Fields } from '../../stores/widgets/forms/state'
import { ClaimReward } from './claimReward'

export const updateRewardFields = (rewardResult: ClaimReward, fields: Fields[], prize = null) => {
    const updatedFields = fields.map((field) => {
        let value = ''
        if (field.fieldName === 'Reward ID') {
            value = rewardResult.data._id
        } else if (field.fieldName === 'Reward Type') {
            value = rewardResult.data.rewardType
        } else if (field.fieldName === 'Reward Name') {
            value = rewardResult.data.name + prize
        } else if (field.fieldName === 'Reward Content') {
            value = rewardResult.data.reward
        } else if (field.fieldName === 'Username') {
            value = rewardResult.username
        } else if (field.fieldName === 'User email') {
            value = rewardResult.email
        } else {
            return field
        }

        return { ...field, value }
    })

    return updatedFields
}
