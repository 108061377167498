import { Button } from '@material-ui/core'
import { nanoid } from 'nanoid'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, {
    Dispatch,
    memo,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import { Toast } from '../../../../helpers/Toast'
import { clearFormFields } from '../../../../lib/forms/clearFormFields'
import { submitForm } from '../../../../lib/forms/submitForm'
import { useHandlePostGame } from '../../../../lib/hooks/useHandlePostGame'
import { claimReward } from '../../../../lib/reward/claimReward'
import { updateRewardFields } from '../../../../lib/reward/updateRewardFields'
import FormEmail from '../../../../models/Forms/FormEmail'
import FormSMS from '../../../../models/Forms/FormSMS'
import { ISegment } from '../../../../models/Games/Segment'
import { CustomerAuth } from '../../../../stores/auth/customer/customerAuth'
import { liveCampaignState } from '../../../../stores/campaign/live/state'
import { Fields, widgetFormState } from '../../../../stores/widgets/forms/state'
import { gameRunningState } from '../../../../stores/widgets/game/state'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import ButtonSpinner from '../../../ButtonSpinner'
import { useTemplate } from '../../../Templates/Custom/CustomTemplate'
import { useFormContext } from '../../Form/FormContainer/FormContainer'

interface ContentProps {
    reward: ISegment
    userData: CustomerAuth
    isWinner: boolean
    accruedInterest: number
    widget: any
    setReward?: SetterOrUpdater<any>
    setQuestionNumber?: Dispatch<SetStateAction<number>>
    handleToggleModal: () => void
    trackAttempt: () => Promise<void>
    updateGameAttempts?: (
        campaignId: string,
        widgetId: string,
        totalAttempts: number | string
    ) => Promise<void>
}

const gameFormField: Fields[] = [
    {
        id: nanoid(),
        fieldName: 'Reward ID',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
    {
        id: nanoid(),
        fieldName: 'Reward Type',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
    {
        id: nanoid(),
        fieldName: 'Reward Name',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
    {
        id: nanoid(),
        fieldName: 'Username',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
    {
        id: nanoid(),
        fieldName: 'User email',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
]

const ModalContent = ({
    reward,
    userData,
    widget,
    setReward,
    accruedInterest,
    handleToggleModal,
    isWinner,
    trackAttempt,
    updateGameAttempts,
    setQuestionNumber,
}: ContentProps) => {
    const { formId } = useFormContext()
    const { uid, campaignId } = useTemplate()
    const [formState, setFormState] = useRecoilState(widgetFormState(formId))
    const campaign = useRecoilValue(liveCampaignState)
    const [loading, setLoading] = useState(false)
    const handlePostGame = useHandlePostGame()
    const setStartGame = useSetRecoilState(gameRunningState)
    const router = useRouter()

    useEffect(() => {
        setFormState({
            ...formState,
            fields: gameFormField,
            formName: `Results game ${widget?.name}`,
        })
    }, [])

    const resetFormState = useCallback(() => {
        const emptyFields = clearFormFields(formState.fields)
        setFormState({
            ...formState,
            fields: emptyFields,
        })
        setReward(null)
    }, [formState, setFormState, setReward])

    const downloadDigitalReward = (url: string) => {
        document.location.href = url
    }

    const redirectToNextPage = useCallback(
        (winner: boolean) => {
            if (winner) {
                if (widget?.settings?.redirect) {
                    handleToggleModal()
                    router.push(widget?.settings?.redirect)
                } else {
                    handleToggleModal()
                    handlePostGame.set(widget?.settings?.postGame)
                }
            } else {
                handleToggleModal()
            }
        },
        [handlePostGame, handleToggleModal, widget]
    )

    const submitGameData = useCallback(async () => {
        setLoading(true)
        try {
            let fields = formState.fields
            if (
                !reward ||
                (Object.keys(reward).length === 0 && reward.reward.allocatedQuantity === 0)
            ) {
                resetFormState()
                setLoading(false)
                Toast('Unable to submit form due to no reward.', 'error', widget.id)
                handleToggleModal()
                return
            }

            const settings = {
                links: [],
                actions: {
                    showToast: false,
                    isClaimPrize: true,
                    isSendEmail: false,
                    isSendSMS: false,
                },
                toastMessage: '',
                gameId: widget.id,
                email: new FormEmail(),
                sms: new FormSMS(),
            }

            const rewardResult = await claimReward(
                campaignId,
                campaign.campaignName,
                settings,
                reward.reward,
                userData.firstName,
                userData.email
            )

            if (rewardResult.message === 'success' || rewardResult.message === 'no reward') {
                const updatedFields = updateRewardFields(rewardResult, fields)
                fields = updatedFields
            } else {
                setReward(null)
                resetFormState()
                setLoading(false)

                if (rewardResult.message === 'no more prize') {
                    Toast('Your reward can no longer be claimed.', 'error', widget.id)
                    handleToggleModal()
                }
                return
            }
            if (rewardResult.message === 'success') {
                Toast('Thanks! We will contact you shortly', 'success', widget.id)
            }

            await submitForm(fields, uid, campaignId, formState.formId, formState.formName)

            if (reward.reward.isReward && reward.reward.rewardType === 'digital') {
                const url: string = reward?.reward?.digitalSettings?.fileUrl
                downloadDigitalReward(url)
            }

            await trackAttempt()
            handlePostGame.set(reward?.postGame)

            setLoading(false)
            handleToggleModal()
            resetFormState()
        } catch (error) {
            resetFormState()
            setLoading(false)
            Toast(error.message, 'error', widget.id)
        }
    }, [
        campaign.campaignName,
        campaignId,
        formState,
        handlePostGame,
        handleToggleModal,
        resetFormState,
        reward,
        setReward,
        uid,
        userData,
        widget.id,
        trackAttempt,
    ])

    const submitting = useCallback(async () => {
        if (widget?.settings?.unSetPrizes) {
            if (isWinner) {
                setLoading(true)
                await updateGameAttempts(
                    campaignId,
                    widget.id,
                    widget.settings.attempts.totalAttempts
                )
                // Uncomment this to give the player only one attempt
                // localStorage.setItem(widget.name, widget.id)
                setLoading(false)
                setStartGame(false)
                redirectToNextPage(isWinner)
                setQuestionNumber(1)
            } else {
                setStartGame(false)
                // Uncomment this to give the player only one attempt
                // localStorage.setItem(widget.name, widget.id)
                redirectToNextPage(isWinner)
                setQuestionNumber(1)
            }
        } else {
            await submitGameData()
        }
    }, [campaignId, isWinner, redirectToNextPage, submitGameData, updateGameAttempts, widget])

    const renderContent = useMemo(() => {
        if (isWinner) {
            return (
                <div className="winner">
                    <Image width="80px" height="67px" src="/images/confetti.svg" alt="confetti" />
                    <h1>{capitalizeFirstLetter(widget?.settings?.winnerText)}</h1>
                    <span>{`You scored ${accruedInterest} percent`}</span>
                    {!widget?.settings?.unSetPrizes && <h3>{reward?.reward?.name}</h3>}
                    {reward?.image && (
                        <Image
                            className="prize"
                            width="250px"
                            height="250px"
                            src={reward?.image}
                            alt={reward?.imageName || 'prize'}
                        />
                    )}
                    <Button
                        style={{ backgroundColor: '#000', color: '#fff' }}
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={submitting}
                    >
                        {loading ? <ButtonSpinner height="24px" width="26px" /> : 'Ok'}
                    </Button>
                </div>
            )
        } else {
            return (
                <div className="better_luck">
                    <Image width="80px" height="67px" src="/images/betterluck.svg" alt="confetti" />
                    <h1>{capitalizeFirstLetter(widget?.settings?.losingText)}</h1>
                    {accruedInterest && <span>{`You scored ${accruedInterest} percent`}</span>}
                    <Button
                        style={{ backgroundColor: '#000', color: '#fff' }}
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={submitting}
                    >
                        {loading ? <ButtonSpinner height="24px" width="26px" /> : 'Ok'}
                    </Button>
                </div>
            )
        }
    }, [isWinner, accruedInterest, reward, submitting, loading, widget])

    return <Content>{renderContent}</Content>
}

export default memo(ModalContent)

const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em;
    .winner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('/images/confetti2.gif');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        & > div:nth-child(2) {
            margin-bottom: 18px !important;
        }
        h1 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            max-width: 210px;
            color: #192536;
            margin-bottom: 12px;
        }
        h3 {
            font-weight: 700;
            font-size: 37px;
            line-height: 44px;
            text-align: center;
            text-transform: uppercase;
            color: #192536;
            margin-bottom: 10px;
        }
        img {
            object-fit: contain;
        }
    }
    .better_luck {
        max-width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > div {
            margin-bottom: 18px !important;
        }
        h1 {
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            text-align: center;
            color: #192536;
            margin-bottom: 12px;
        }
        & > span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #192536;
            margin-bottom: 10px;
        }
    }
    .MuiButtonBase-root {
        margin-top: 15px;
        outline: none;
        width: 118px;
        height: 52px;
        text-transform: none;
        border-radius: 8px;
        box-shadow: none;
        font-size: 18px;
    }
    .MuiButtonBase-root:hover {
        background-color: none !important;
    }
`
