import React, { Fragment, useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { IWidget } from '../../models/Widget'

interface Props {
    widgetName: string
    widget?: IWidget
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const EditingIndicator = ({ widgetName, widget, onClick }: Props) => {
    const renderIndicator = useMemo(
        () => (
            <>
                <div className="edit_mode">
                    <SelectionIndicator isSpin={widget?.type === 'spinwheel'}>
                        <span>{widgetName}</span>
                        <svg
                            width="10"
                            height="9"
                            viewBox="0 0 10 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.98958 1.44466L8.55556 3.01063C8.62153 3.0766 8.62153 3.18424 8.55556 3.25022L4.76389 7.04188L3.15278 7.2207C2.9375 7.24501 2.75521 7.06271 2.77951 6.84744L2.95833 5.23633L6.75 1.44466C6.81597 1.37869 6.92361 1.37869 6.98958 1.44466ZM9.80208 1.04709L8.95486 0.19987C8.69097 -0.0640191 8.26215 -0.0640191 7.99653 0.19987L7.38195 0.814453C7.31597 0.880425 7.31597 0.988064 7.38195 1.05404L8.94792 2.62001C9.01389 2.68598 9.12153 2.68598 9.1875 2.62001L9.80208 2.00542C10.066 1.7398 10.066 1.31098 9.80208 1.04709ZM6.66667 6.01063V7.77799H1.11111V2.22244H5.10069C5.15625 2.22244 5.20833 2.19987 5.24826 2.16167L5.94271 1.46723C6.07465 1.33529 5.9809 1.11133 5.79514 1.11133H0.833333C0.373264 1.11133 0 1.48459 0 1.94466V8.05577C0 8.51584 0.373264 8.8891 0.833333 8.8891H6.94444C7.40451 8.8891 7.77778 8.51584 7.77778 8.05577V5.31619C7.77778 5.13042 7.55382 5.03841 7.42188 5.16862L6.72743 5.86306C6.68924 5.90299 6.66667 5.95508 6.66667 6.01063Z"
                                fill="white"
                            />
                        </svg>
                    </SelectionIndicator>
                </div>
                <div className="hover_block" onClick={onClick}></div>
            </>
        ),
        [onClick, widget?.type, widgetName]
    )
    return <Fragment>{renderIndicator}</Fragment>
}

const bounce = keyframes`
  0%,100%{ transform:translate(0); }
  25%{ transform:rotateX(20deg) translateY(2px) rotate(-3deg); }
  50%{ transform:translateY(-10px) rotate(3deg) scale(1.1);  }
`

const SelectionIndicator = styled.div<{
    isSpin?: boolean
}>`
    animation: ${bounce} 0.3s ease-in-out;
    display: flex;
    font-family: 'Matter';
    align-items: flex-end;
    background-color: #3ae29b;
    position: absolute;
    height: 15px;
    left: -1px;
    top: ${(props) => (props.isSpin ? '-1px' : '-16px')};
    z-index: 10;

    & > span {
        color: #fff;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        margin: 0 15px 0 4px;
    }
    & > svg {
        margin: 3px;
    }
`
