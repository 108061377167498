import React, { useCallback, useEffect, useState } from 'react'
import { SetterOrUpdater } from 'recoil'
import styled from 'styled-components'

import { usePuzzleGameHook } from '../../../../hooks/usePuzzleGameHook'
import { IPuzzle } from '../../../../models/Games/Puzzle'
import { ISegment } from '../../../../models/Games/Segment'
import { IWidget } from '../../../../models/Widget'
import { CustomerAuth } from '../../../../stores/auth/customer/customerAuth'
import GameRules from '../GameRules'
import Modal from '../Modal'
import ModalContent from '../ModalContent'
import Points from './Points'
import Tile from './Tile'

interface Props {
    widget: IPuzzle | IWidget
    reward: ISegment
    setReward: SetterOrUpdater<any>
    playerData: CustomerAuth
    trackProgress: () => Promise<void>
}

const PuzzleGameComponent = ({ widget, reward, setReward, playerData, trackProgress }: Props) => {
    const [board, moves, solved, isTileRight, newGame, move] = usePuzzleGameHook()
    const [isOpen, setIsOpen] = useState(false)
    const [complited, setComplited] = useState({ win: false, lose: false })

    useEffect(() => {
        if (moves > widget?.settings?.moves && reward) {
            setComplited((prev) => ({ ...prev, lose: true }))
            setIsOpen(true)
            setReward((prev) => ({
                ...prev,
                reward: { ...prev.reward, reward: { ...prev.reward.reward, isReward: false } },
            }))
        }
        if (solved && moves < widget?.settings?.moves && reward) {
            setComplited((prev) => ({ ...prev, win: true }))
            setIsOpen(true)
        }
    }, [moves, solved])

    const colourSettings = widget?.colourSettings
    const appearanceSettings = widget?.appearanceSettings
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const borderWidth =
        appearanceSettings?.borderTopWidth / 10 + 'em' &&
        appearanceSettings?.borderBottomWidth / 10 + 'em' &&
        appearanceSettings?.borderBottomWidth / 10 + 'em' &&
        appearanceSettings?.borderRightWidth / 10 + 'em'
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const fontSize = appearanceSettings?.fontSize + 'px'
    const fontWeight = appearanceSettings?.font?.weight
    const backgroundColor = colourSettings['background colour'][0]
    const borderColor = colourSettings['border colour'][0]
    const textColor = colourSettings['text colour'][0]
    const blockColor = colourSettings['block colour'][0]
    const border = `${borderWidth} solid ${borderColor}`

    const handleToggleModal = useCallback(() => {
        setIsOpen((prev) => !prev)
    }, [])

    const onFinish = async (reward: ISegment) => {
        await trackProgress()
        newGame()
    }

    return (
        <GameContainer
            brandImage={widget?.settings?.brandImage}
            textColor={textColor}
            blockColor={blockColor}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
        >
            <Board
                startGame={playerData.userId}
                width={`${Math.sqrt(board.length) * 85}px`}
                height={`${Math.sqrt(board.length) * 85}px`}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                border={border}
                backgroundColor={backgroundColor}
            >
                {board.slice(0, -1).map((pos: React.Key, index: number) => (
                    <Tile
                        index={index}
                        key={pos}
                        pos={pos}
                        onClick={move(index)}
                        isRight={isTileRight(index)}
                        gap={Math.sqrt(board.length) + 10}
                    />
                ))}
                {solved && (
                    <Overlay
                        width={`${Math.sqrt(board.length) * 85}px`}
                        height={`${Math.sqrt(board.length) * 85}px`}
                    ></Overlay>
                )}
            </Board>

            <Points moves={moves} posibleMoves={widget?.settings?.moves} color={textColor} />

            <GameRules color={textColor}>
                <h1>Rules of puzzle game</h1>
                <span>
                    Inspired by the original Game of Fifteen, this version is played with the goal
                    to solve the puzzle in the least amount of moves. The puzzle is essentially
                    two-dimensional; meaning tiles cannot be lifted, removed or forced into place.
                    Only sliding movements are allowed to solve the puzzle. Finding moves and paths
                    opened up by each move within the two-dimensional confines of the board are
                    important parts of solving sliding block puzzles.1 The goal of the game is to
                    rearrange the tiles back in order, finalizing with the empty space at the bottom
                    right corner.
                    <img src="/images/puzzle_game.jpeg" alt="puzzle_game" />
                    <hr />
                    At the top of the game you have the total number of moves. If you complete the
                    puzzle in fewer moves, you will receive one of the prizes that are raffled off
                    with this game.
                </span>
            </GameRules>

            {(complited.win || complited.lose) && (
                <Modal isOpen={isOpen}>
                    <ModalContent
                        reward={reward}
                        widget={widget}
                        setReward={setReward}
                        userData={playerData}
                        handleToggleModal={handleToggleModal}
                        onCompleted={onFinish}
                        puzzleComplited={complited}
                        setIsOpen={setIsOpen}
                    />
                </Modal>
            )}
        </GameContainer>
    )
}

export default PuzzleGameComponent

const GameContainer = styled.div<{
    brandImage: string | null
    textColor: string
    blockColor: string
    fontFamily: string
    fontSize: string
    fontWeight: number
}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${(props) => props.fontFamily};

    .tile {
        cursor: pointer;
        font-size: ${(props) => props.fontSize};
        font-weight: ${(props) => props.fontWeight};
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${(props) => props.textColor};
        text-shadow: 0 0 0.1rem black;
        width: 70px;
        height: 70px;
        position: absolute;
        background: ${(props) => props.blockColor};
        transition: all 0.3s ease-in-out;
        border-radius: 0.3rem;
        box-shadow: 0 0 0.7rem black;
    }

    .tile__correct {
        background-image: ${(props) => (props.brandImage ? `url(${props.brandImage})` : '')};
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #00aa00;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background: #0004;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
    }
`

const Board = styled.div<{
    width: string
    height: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    border: string
    backgroundColor: string
    startGame: any
}>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    user-select: none;
    margin: 0 auto;
    margin-bottom: 18px;
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    position: relative;
    background: ${(props) => props.backgroundColor};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: ${(props) => props.border};
    box-shadow: 0 0 1.5rem black;
    transition: all 0.3s ease-in-out;
    pointer-events: ${(props) => (!props.startGame ? 'none' : 'auto')};
`

const Overlay = styled.div<{
    width: string
    height: string
}>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: #0004;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
`
