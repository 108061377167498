export const clearFormFields = (fields): any[] => {
    const clearFields = fields.map((field) => {
        if (field.fieldType === 'upload' || field.fieldType === 'checkbox') {
            return { ...field, value: [] }
        } else {
            return { ...field, value: field.defaultValue || '' }
        }
    })

    return clearFields
}
