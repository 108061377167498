import { Button } from '@material-ui/core'
import { nanoid } from 'nanoid'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, {
    Dispatch,
    memo,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'
import Papa from 'papaparse'
import { parse } from 'json2csv'

import { Toast } from '../../../helpers/Toast'
import { clearFormFields } from '../../../lib/forms/clearFormFields'
import { submitForm } from '../../../lib/forms/submitForm'
import { useHandlePostGame } from '../../../lib/hooks/useHandlePostGame'
import { claimReward } from '../../../lib/reward/claimReward'
import { updateRewardFields } from '../../../lib/reward/updateRewardFields'
import FormEmail from '../../../models/Forms/FormEmail'
import FormSMS from '../../../models/Forms/FormSMS'
import { IFlipSegment } from '../../../models/Games/FlipSegment'
import { ISegment } from '../../../models/Games/Segment'
import WheelSegment from '../../../models/Games/WheelSegment'
import { CustomerAuth } from '../../../stores/auth/customer/customerAuth'
import { liveCampaignState } from '../../../stores/campaign/live/state'
import { Fields, widgetFormState } from '../../../stores/widgets/forms/state'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import ButtonSpinner from '../../ButtonSpinner'
import { useTemplate } from '../../Templates/Custom/CustomTemplate'
import { useFormContext } from '../Form/FormContainer/FormContainer'
import { delay } from '../../../helpers/delay'

interface ContentProps {
    reward: ISegment | WheelSegment | IFlipSegment[]
    userData: CustomerAuth
    widget: any
    setReward?: SetterOrUpdater<any>
    handleToggleModal: () => void
    onCompleted?: (reward: ISegment | WheelSegment) => void
    setIsOpen?: Dispatch<SetStateAction<boolean>>
    puzzleComplited?: { win: boolean; lose: boolean }
}

const gameFormField: Fields[] = [
    {
        id: nanoid(),
        fieldName: 'Reward ID',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
    {
        id: nanoid(),
        fieldName: 'Reward Type',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
    {
        id: nanoid(),
        fieldName: 'Reward Name',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
    {
        id: nanoid(),
        fieldName: 'Username',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
    {
        id: nanoid(),
        fieldName: 'User email',
        value: '',
        required: true,
        fieldType: 'reward',
        fieldLabel: 'reward',
        defaultValue: '',
    },
]

const ModalContent = ({
    reward,
    userData,
    widget,
    setReward,
    handleToggleModal,
    onCompleted,
    setIsOpen,
    puzzleComplited,
}: ContentProps) => {
    const { formId } = useFormContext()
    const { uid, campaignId } = useTemplate()
    const [formState, setFormState] = useRecoilState(widgetFormState(formId))
    const campaign = useRecoilValue(liveCampaignState)
    const [loading, setLoading] = useState(false)
    const handlePostGame = useHandlePostGame()
    const router = useRouter()
    const count = widget?.settings?.resetAttempt?.day
    const period = widget?.settings?.resetAttempt?.period

    useEffect(() => {
        setFormState({
            ...formState,
            fields: gameFormField,
            formName: `Results game ${widget?.name}`,
        })
    }, [])

    const resetFormState = useCallback(() => {
        const emptyFields = clearFormFields(formState.fields)
        setFormState({
            ...formState,
            fields: emptyFields,
        })
        if (widget.type !== 'flipcard') {
            setReward(null)
        }
    }, [formState, setFormState, setReward, widget.type])

    const downloadDigitalReward = (url: string) => {
        document.location.href = url
    }

    const fallbackCopyTextToClipboard = (text: string) => {
        let textArea = document.createElement('textarea')
        textArea.value = text
        // Avoid scrolling to bottom
        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
            document.execCommand('copy')
            Toast(`Copied to clipboard! ${text} `, 'success', text)
        } catch (err) {
            Toast('Oops, unable to copy ', 'error')
        }
        document.body.removeChild(textArea)
    }

    const copyToClipboard = async (text: string) => {
        try {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(text)
                Toast(`Copied to clipboard! ${text} `, 'success', text)
            } else {
                fallbackCopyTextToClipboard(text)
                return
            }
        } catch (error) {
            Toast('Error copying link', 'error')
        }
    }

    const submitGameData = useCallback(async () => {
        setLoading(true)
        try {
            if (Array.isArray(reward)) {
                reward.forEach(async (item) => {
                    let fields = formState.fields
                    if (
                        !item ||
                        (Object.keys(item).length === 0 && item.reward.allocatedQuantity === 0)
                    ) {
                        resetFormState()
                        setLoading(false)
                        Toast('Unable to submit form due to no reward.', 'error', widget.id)
                        return
                    }

                    const settings = {
                        links: [],
                        actions: {
                            showToast: false,
                            isClaimPrize: true,
                            isSendEmail: false,
                            isSendSMS: false,
                        },
                        toastMessage: '',
                        gameId: widget.id,
                        email: new FormEmail(),
                        sms: new FormSMS(),
                    }

                    const rewardResult = await claimReward(
                        campaignId,
                        campaign.campaignName,
                        settings,
                        item.reward,
                        userData.firstName,
                        userData.email
                    )

                    if (
                        rewardResult.message === 'success' ||
                        rewardResult.message === 'no reward'
                    ) {
                        const updatedFields = updateRewardFields(rewardResult, fields)
                        fields = updatedFields
                    } else {
                        resetFormState()
                        setLoading(false)
                        if (rewardResult.message === 'no more prize') {
                            Toast('Your reward can no longer be claimed.', 'error', widget.id)
                            handleToggleModal()
                        }
                        return
                    }

                    if (rewardResult.message === 'success') {
                        Toast('Thanks! We will contact you shortly', 'success', widget.id)
                    }
                    if (widget?.type !== 'flipcard') {
                        onCompleted(item)
                    }

                    let userId = campaign.uid
                    if (!userId) {
                        userId = campaign.creatorUID
                    }

                    await submitForm(
                        fields,
                        userId,
                        campaignId,
                        formState.formId,
                        formState.formName
                    )

                    if (widget?.settings?.redirect && item?.reward?.isReward) {
                        router.push(widget.settings.redirect)
                    } else {
                        handlePostGame.set(item?.postGame)
                    }

                    if (item.reward.isReward && item.reward.rewardType === 'digital') {
                        const url: string = item?.reward?.digitalSettings?.fileUrl
                        downloadDigitalReward(url)
                    }

                    handlePostGame.set(item?.postGame)
                })
                setLoading(false)
                handleToggleModal()
                resetFormState()
            } else {
                let fields = formState.fields
                if (
                    !reward ||
                    (Object.keys(reward).length === 0 && reward.reward.allocatedQuantity === 0)
                ) {
                    resetFormState()
                    setLoading(false)
                    Toast('Unable to submit form due to no reward.', 'error', widget.id)
                    handleToggleModal()
                    return
                }

                const settings = {
                    links: [],
                    actions: {
                        showToast: false,
                        isClaimPrize: true,
                        isSendEmail: false,
                        isSendSMS: false,
                    },
                    toastMessage: '',
                    gameId: widget.id,
                    email: new FormEmail(),
                    sms: new FormSMS(),
                }

                const rewardResult = await claimReward(
                    campaignId,
                    campaign.campaignName,
                    settings,
                    reward.reward,
                    userData.firstName,
                    userData.email
                )

                if (rewardResult.message === 'success' || rewardResult.message === 'no reward') {
                    if (reward?.reward?.rewardType === 'multiple') {
                        const updatedFields = updateRewardFields(
                            rewardResult,
                            fields,
                            reward?.prize
                        )
                        fields = updatedFields
                    } else {
                        const updatedFields = updateRewardFields(rewardResult, fields)
                        fields = updatedFields
                    }
                } else {
                    setReward(null)
                    resetFormState()
                    setLoading(false)

                    if (rewardResult.message === 'no more prize') {
                        Toast('Your reward can no longer be claimed.', 'error', widget.id)
                        handleToggleModal()
                    }
                    return
                }

                if (widget?.type !== 'flipcard') {
                    onCompleted(reward)
                }

                await submitForm(fields, uid, campaignId, formState.formId, formState.formName)

                if (reward.reward.isReward && reward.reward.rewardType === 'digital') {
                    const url: string = reward?.reward?.digitalSettings?.fileUrl
                    downloadDigitalReward(url)
                }
                if (reward.reward.isReward && reward.reward.rewardType === 'multiple') {
                    const obj = { code_activation: reward.prize }
                    const blob = new Blob([JSON.stringify(obj, null, 2)], {
                        type: 'application/json',
                    })
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.setAttribute('href', url)
                    a.setAttribute('download', `prize.csv`)
                    document.body.appendChild(a)
                    a.click()
                    window.URL.revokeObjectURL(url)

                    await copyToClipboard(reward.prize)
                }

                if (rewardResult.message === 'success' && reward.reward.rewardType === 'physical') {
                    Toast('Thanks! We will contact you shortly', 'success', widget.id)
                }

                if (widget?.settings?.redirect && reward?.reward?.isReward) {
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.setAttribute('href', widget.settings.redirect)
                    document.body.appendChild(a)

                    delay(3000, () => {
                        a.click()
                        document.body.removeChild(a)
                    })
                } else {
                    handlePostGame.set(reward?.postGame)
                }
            }
            setLoading(false)
            handleToggleModal()
            resetFormState()
        } catch (error) {
            resetFormState()
            setLoading(false)
            console.log(error)
            Toast(error.message, 'error', widget.id)
        }
        if (widget?.type !== 'scratchcard') {
            setIsOpen(false)
        }
    }, [
        campaign.campaignName,
        campaignId,
        formState,
        handlePostGame,
        handleToggleModal,
        onCompleted,
        resetFormState,
        reward,
        setIsOpen,
        setReward,
        uid,
        userData,
        widget,
    ])

    const renderContent = useMemo(() => {
        if (Array.isArray(reward) && reward.length !== 0) {
            return (
                <div className="winner">
                    <Image width="80px" height="67px" src="/images/confetti.svg" alt="confetti" />
                    <h1>{capitalizeFirstLetter(widget?.settings?.winnerText)}</h1>
                    {reward.map((item) => (
                        <Reward key={item.id}>
                            <span>{item?.reward?.name}</span>
                            {item?.image && (
                                <Image
                                    className="prize"
                                    width="200px"
                                    height="200px"
                                    src={item?.image}
                                    alt={item?.imageName || 'prize'}
                                />
                            )}
                        </Reward>
                    ))}
                    <Button
                        style={{ backgroundColor: '#000', color: '#fff' }}
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={submitGameData}
                    >
                        {loading ? <ButtonSpinner height="24px" width="26px" /> : 'Claim'}
                    </Button>
                </div>
            )
        } else if (!Array.isArray(reward) && reward?.reward?.isReward) {
            if (widget?.type === 'puzzle') {
                if (puzzleComplited.win) {
                    return (
                        <div className="winner">
                            <Image
                                width="80px"
                                height="67px"
                                src="/images/confetti.svg"
                                alt="confetti"
                            />
                            <h1>{capitalizeFirstLetter(widget?.settings?.winnerText)}</h1>
                            <br />
                            <h3>{reward?.reward?.name}</h3>
                            {reward?.image && widget?.type !== 'spinwheel' && (
                                <Image
                                    className="prize"
                                    width="250px"
                                    height="250px"
                                    src={reward?.image}
                                    alt={reward?.imageName || 'prize'}
                                />
                            )}
                            <Button
                                style={{ backgroundColor: '#000', color: '#fff' }}
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={submitGameData}
                            >
                                {loading ? <ButtonSpinner height="24px" width="26px" /> : 'Claim'}
                            </Button>
                        </div>
                    )
                }
                if (puzzleComplited.lose) {
                    return (
                        <div className="better_luck">
                            <Image
                                width="80px"
                                height="67px"
                                src="/images/betterluck.svg"
                                alt="confetti"
                            />
                            <h1>{capitalizeFirstLetter(widget?.settings?.losingText)}</h1>
                            <Button
                                style={{ backgroundColor: '#000', color: '#fff' }}
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={submitGameData}
                            >
                                {loading ? <ButtonSpinner height="24px" width="26px" /> : 'Ok'}
                            </Button>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="winner">
                        <Image
                            width="80px"
                            height="67px"
                            src="/images/confetti.svg"
                            alt="confetti"
                        />
                        <h1>{capitalizeFirstLetter(widget?.settings?.winnerText)}</h1>
                        <br />
                        {reward?.prize ? <h3>{reward?.prize}</h3> : <h3>{reward?.reward?.name}</h3>}
                        {reward?.image && widget?.type !== 'spinwheel' && (
                            <Image
                                className="prize"
                                width="250px"
                                height="250px"
                                src={reward?.image}
                                alt={reward?.imageName || 'prize'}
                            />
                        )}
                        <Button
                            style={{ backgroundColor: '#000', color: '#fff' }}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={submitGameData}
                        >
                            {loading ? <ButtonSpinner height="24px" width="26px" /> : 'Claim'}
                        </Button>
                    </div>
                )
            }
        } else {
            return (
                <div className="better_luck">
                    <Image width="80px" height="67px" src="/images/betterluck.svg" alt="confetti" />
                    <h1>{capitalizeFirstLetter(widget?.settings?.losingText)}</h1>
                    {widget.type !== 'spinwheel' && (
                        <span>
                            Come back in {count} {period} to try again
                        </span>
                    )}
                    <Button
                        style={{ backgroundColor: '#000', color: '#fff' }}
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={submitGameData}
                    >
                        {loading ? <ButtonSpinner height="24px" width="26px" /> : 'Ok'}
                    </Button>
                </div>
            )
        }
    }, [reward, submitGameData, loading, widget.type, widget?.settings?.losingText, count, period])

    return <Content>{renderContent}</Content>
}

export default memo(ModalContent)

const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em;
    .winner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('/images/confetti2.gif');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        & > div:nth-child(2) {
            margin-bottom: 18px !important;
        }
        h1 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            max-width: 210px;
            color: #192536;
            margin-bottom: 12px;
        }
        h3 {
            font-weight: 700;
            font-size: 37px;
            line-height: 44px;
            text-align: center;
            text-transform: uppercase;
            color: #192536;
            margin-bottom: 10px;
        }
        img {
            object-fit: contain;
        }
    }
    .better_luck {
        max-width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > div {
            margin-bottom: 18px !important;
        }
        h1 {
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            text-align: center;
            color: #192536;
            margin-bottom: 12px;
        }
        & > span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #192536;
            margin-bottom: 10px;
        }
    }
    .MuiButtonBase-root {
        margin-top: 15px;
        outline: none;
        width: 118px;
        height: 52px;
        text-transform: none;
        border-radius: 8px;
        box-shadow: none;
        font-size: 18px;
    }
    .MuiButtonBase-root:hover {
        background-color: none !important;
    }
`
const Reward = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > span {
        margin-top: 10px;
        font-weight: 500;
        font-size: 26px;
        line-height: 19px;
        text-align: center;
        color: #1b8a2d;
    }
`
