
export interface IFormSMS {
    phoneNumber: string
    textMessage: string
    attachment: any[]
}


class FormSMS {
    phoneNumber = ""
    textMessage = ""
    attachment = []
}

export default FormSMS