import React, { useState } from 'react'
import { Line, Link, NavIcon, Overlay, OverlayContent } from './styles'

export const BurgerMenu = () => {
    const [openMenu, setOpenMenu] = useState(false)

    const onHandleOpenMenu = () => {
        setOpenMenu((prev) => !prev)
    }

    return (
        <>
            <NavIcon onClick={onHandleOpenMenu}>
                <Line open={openMenu} />
                <Line open={openMenu} />
                <Line open={openMenu} />
            </NavIcon>
            <Overlay open={openMenu}>
                <OverlayContent open={openMenu}>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img width={'50%'} height={50} src="/images/logo.svg" alt="logo" />
                    <Link target="_blank" href="https://www.deployable.co">
                        Learn more about DEPLOYABLE
                    </Link>
                    <Link target="_blank" href="https://www.deploy.link">
                        Get your own digital card.
                    </Link>
                </OverlayContent>
            </Overlay>
        </>
    )
}
