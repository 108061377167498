import { nanoid } from 'nanoid'
import { atom, selector } from 'recoil'
import CustomCampaignTemplate, { ICustomCampaign } from '../../../models/CustomCampaign'
import View from '../../../models/View'

export const liveCampaignState = atom<ICustomCampaign>({
    key: 'liveCampaignState',
    default: new CustomCampaignTemplate(
        '',
        'https://deploy.link',
        [{ family: 'Poppins' }],
        [new View(`Page 1`, nanoid(), '#FFFFFF', '#FFFFFF', undefined, '', [], [])],
        null
    ),
})

export const currentViewState = atom<number>({
    key: 'currentView',
    default: 0,
})

export const currentLayerState = atom<number>({
    key: 'currentLayer',
    default: -1,
})

export const viewState = atom({
    key: 'view',
    default: null,
})

export const layerState = atom({
    key: 'layer',
    default: null,
})

export const viewSelector = selector<any>({
    key: 'viewSelector',
    get: ({ get }) => {
        const viewIndex = get(currentViewState)
        const campaign = get(liveCampaignState)
        if (campaign) {
            return campaign.views[viewIndex]
        } else {
            return viewState
        }
    },
    set: ({ set }, newValue) => set(viewState, newValue),
})

export const layerSelector = selector<any>({
    key: 'layerSelector',
    get: ({ get }) => {
        const layerIndex = get(currentLayerState)
        const viewIndex = get(currentViewState)
        const campaign = get(liveCampaignState)
        if (campaign && layerIndex > -1 && campaign.views[viewIndex].layers[layerIndex]) {
            return campaign.views[viewIndex].layers[layerIndex]
        } else {
            return null
        }
    },
    set: ({ set }, newValue) => set(layerState, newValue),
})

export const windowSizeState = atom<{ height: number; width: number }>({
    key: 'liveWindowSize',
    default: {
        width: 400,
        height: 700,
    },
})
