import { nanoid } from 'nanoid'

export enum LinkAction {
    GO_TO_LINK = 'go to link',
    GO_TO_VIEW = 'redirect to view',
    GO_TO_POPUP = 'redirect to popup',
    GO_TO_EMAIL = 'set email',
}

export interface ILinkSettings {
    id: string
    linkTitle: string
    linkUrl: string
    type: LinkAction
    layerIndex: number
    viewIndex: number
    widgetSettings: { [key: string]: any }
}

class LinkSettings implements ILinkSettings {
    id = nanoid()
    linkTitle = ''
    linkUrl = ''
    type = LinkAction.GO_TO_LINK
    layerIndex = 0
    viewIndex = 0
    widgetSettings = {}

    constructor(linkTitle, linkUrl, type, layerIndex, viewIndex) {
        this.linkTitle = linkTitle
        this.linkUrl = linkUrl
        this.type = type
        this.layerIndex = layerIndex // if type === moveToComponent / showComponent / closeComponent, then find widgetIndex in settings.widget and move/show
        this.viewIndex = viewIndex // if type === goToView , then find view from viewIndex in settings to display
    }
}

export default LinkSettings
