import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { IWidget } from '../../../../models/Widget'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { liveCampaignState } from '../../../../stores/campaign/live/state'
import { IAnswer } from '../../../../models/General/Polls'
import { IFormFields } from '../../../../types/Forms/FormData'
import { EditingIndicator } from '../../EditingIndicator'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import PollsWidget from './PollsWidget'
import { Toast } from '../../../../helpers/Toast'

export interface PollsTheme {
    textColor: string
    mainColor: string
    backgroundColor: string
    alignment: string
    mode: string[]
}

interface Props {
    widget?: IWidget
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    isPreview?: boolean
}

const themes = {
    purple: ['#6D4B94', '#7C6497', '#6D4B943B'],
    red: ['#E23D3D', '#EF4545', '#FF28283B'],
    blue: ['#5674E0', '#5674E0', '#5674E03B'],
    black: ['#303030', '#303030', '#3030303B'],
    white: ['#ffffff', '#ffffff', '#ffffff3B'],
    cyan: ['#00BCDD', '#00BCDD', '#00BCDD3B'],
}

const Polls: FC<Props> = ({
    widget,
    updateActiveWidget,
    index,
    parentIndex,
    isPreview = false,
}) => {
    const sectionState = useRecoilValue(viewSectionState)
    const ativeWidget = useRecoilValue(activeWidgetState)
    const campaign = useRecoilValue(liveCampaignState)
    const [pollAnswers, setPollAnswers] = useState([...widget?.settings?.data])
    const [isVoted, setIsVoted] = useState(false)

    const submitPollsForm = async (pollsData: { [key: string]: any[] }) => {
        try {
            const res = await fetch(
                `/api/polls/submitPolls?campaignId=${campaign.campaignId}&formId=${widget.id}&formName=${widget.name}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(pollsData),
                }
            )

            const data = await res.json()

            return data
        } catch (error) {
            Toast(error, 'error')
            throw new Error(error.message)
        }
    }

    const obtainColors = (customTheme: string) => {
        const colors = themes[customTheme]
        if (!colors) {
            return themes['black']
        }
        return colors
    }

    const dataFromLocalStorage = JSON.parse(localStorage.getItem(widget.name))

    useEffect(() => {
        const updateFormFields = (pollsAnswers: IFormFields[]) => {
            const updatedFields = pollsAnswers?.map((field: IFormFields) => {
                return {
                    option: field.fieldName,
                    votes: field.value,
                    id: field.id,
                }
            })

            return updatedFields
        }

        async function fetchPollsForms(campaignId: string) {
            const res = await fetch(`/api/polls/getForm`, {
                method: 'POST',
                body: JSON.stringify({ campaignId }),
            })

            const result = await res.json()

            if (result.data) {
                const newFields = updateFormFields(result.data.forms[0].fields)
                setPollAnswers(newFields)
            }
        }

        const dataFromLocalStorage = JSON.parse(localStorage.getItem(widget.name))

        if (!isPreview) {
            if (dataFromLocalStorage && dataFromLocalStorage.id === widget.id) {
                setIsVoted(true)
            }
            fetchPollsForms(campaign.campaignId)
        }
    }, [])

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'
    const appearanceSettings = widget?.appearanceSettings
    const colorSettings = widget?.colourSettings
    const fontUrl = appearanceSettings?.font?.url
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const fontSize = appearanceSettings?.font?.size
    const fontWeight = appearanceSettings?.font?.weight
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const mode = obtainColors(appearanceSettings?.mode)

    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius + 'px'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius + 'px'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius + 'px'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius + 'px'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const borderColour = colorSettings['border colour'][0]
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop + 'px'
    const paddingBottom = appearanceSettings?.paddingBottom + 'px'
    const paddingLeft = appearanceSettings?.paddingLeft + 'px'
    const paddingRight = appearanceSettings?.paddingRight + 'px'

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    const customStyles: PollsTheme = {
        textColor: colorSettings['question colour'][0],
        mainColor: '#62f5c4',
        backgroundColor: 'rgb(255,255,255)',
        alignment: appearanceSettings?.align,
        mode: mode,
    }

    const handleVote = useCallback(
        async (voteAnswer: string) => {
            if (isPreview) return

            try {
                const voteAnswerIndex = pollAnswers.findIndex(
                    (answer) => answer.option === voteAnswer
                )

                const newPollAnswer = {
                    ...pollAnswers[voteAnswerIndex],
                    votes: pollAnswers[voteAnswerIndex].votes + 1,
                }

                const newPollAnswers = [
                    ...pollAnswers.slice(0, voteAnswerIndex),
                    newPollAnswer,
                    ...pollAnswers.slice(voteAnswerIndex + 1),
                ]

                setPollAnswers(newPollAnswers)

                const updateFormFields = (pollsAnswers) => {
                    const updatedFields = pollsAnswers.map((field: IAnswer) => {
                        return {
                            id: field.id,
                            fieldName: field.option,
                            value: field.votes,
                            fieldType: 'polls',
                            fieldLabel: 'answer',
                        }
                    })

                    return updatedFields
                }

                const newFields = updateFormFields(newPollAnswers)

                await submitPollsForm({ fields: newFields })

                localStorage.setItem(
                    widget.name,
                    JSON.stringify({
                        id: widget.id,
                        answer: voteAnswer,
                        voteId: pollAnswers[voteAnswerIndex].id,
                    })
                )
            } catch (error) {
                alert(error)
            }
        },
        [campaign.campaignId, isPreview, pollAnswers, widget.id, widget.name]
    )

    return (
        <Container>
            <Wrapper
                onClick={onEditMode}
                isActive={isActive}
                isPreview={isPreview}
                width={widget?.appearanceSettings?.width + '%'}
                align={widget?.appearanceSettings?.align}
                background={colorSettings['background colour'][0]}
                border={`${borderWidth} solid ${borderColour}`}
                borderTopLeftRadius={borderTopLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                fontFamily={fontFamily}
                fontSize={fontSize + 'px'}
                fontWeight={+fontWeight}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginRight={marginRight}
                marginLeft={marginLeft}
            >
                <style>
                    {!fontUrl
                        ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                        : `@font-face {
                  font-family: '${fontFamily}';
                  src: url('${fontUrl}');
                  font-style: normal;
                }
                
                .header-text {
                  font-family: '${fontFamily}';
                }`}
                </style>

                <PollsWidget
                    question={widget?.settings?.question}
                    pollAnswers={pollAnswers}
                    handleVote={handleVote}
                    isVoted={isVoted}
                    customStyles={customStyles}
                    pollsType={widget?.settings?.type}
                    dataFromLocalStorage={dataFromLocalStorage}
                />
                {isPreview && <EditingIndicator widgetName={capitalizeFirstLetter(widget?.name)} />}
            </Wrapper>
        </Container>
    )
}

export default Polls

const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled.div<{
    align: string
    width: string
    isActive: boolean
    isPreview: boolean
    background: string
    border: string
    borderTopLeftRadius: string
    borderTopRightRadius: string
    borderBottomLeftRadius: string
    borderBottomRightRadius: string
    fontFamily: string
    fontSize: string
    fontWeight: number
    marginTop?: string
    marginBottom?: string
    marginRight?: string
    marginLeft?: string
    paddingTop?: string
    paddingBottom?: string
    paddingLeft?: string
    paddingRight?: string
}>`
    width: ${(props) => props.width};
    display: flex;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.background};
    border: ${(props) => props.border};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    padding-top: ${(props) => props.paddingTop};
    padding-left: ${(props) => props.paddingLeft};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-right: ${(props) => props.paddingRight};

    article {
        width: 100%;

        h1 {
            width: 100%;
            border-width: 0 !important;
            border-bottom: 1px solid #6d4b943b !important;
            text-align: ${(props) => props.align} !important;
            font-weight: ${(props) => props.fontWeight} !important;
        }
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: none;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
