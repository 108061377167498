import React, { FC } from 'react'
import styled from 'styled-components'

interface Props {
    moves: number
    posibleMoves: number
    color?: string
}

const Points: FC<Props> = ({ moves, posibleMoves, color = '#717f95' }) => {
    return (
        <PointsContainer textColor={color}>
            <div className="moves">
                <div>Moves made:</div>
                {`${moves}/${posibleMoves}`}
            </div>
        </PointsContainer>
    )
}

export default Points

const PointsContainer = styled.div<{
    textColor: string
}>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: ${(props) => props.textColor};

    & > .moves {
        font-size: 18px;
        text-align: center;
    }
`
