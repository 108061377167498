import React, {
    createRef,
    FC,
    forwardRef,
    MutableRefObject,
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'
import { IAnswer } from '../../../../models/General/Polls'
import { PollsTheme } from './Polls'

interface Props {
    question?: string
    answers: IAnswer[]
    theme?: PollsTheme
    isVoted: boolean
    isVotedId?: string
    onVote: (answer: string) => void
    answer?: string
}

const AnswerInner = forwardRef<HTMLDivElement, any>((props, ref) => {
    return (
        <div ref={ref} className="answerInner">
            {props.children}
        </div>
    )
})

export const MultiplePoll: FC<Props> = ({
    question,
    answers,
    theme,
    onVote,
    isVoted,
    isVotedId,
    answer,
}) => {
    const [voted, setVoted] = useState<boolean>(false)
    const answerRefs = useRef<RefObject<HTMLDivElement>[]>(
        answers.map(() => createRef<HTMLDivElement>())
    )

    const calculatePercent = (votes: any, answers: any[]) => {
        const initialValue = 0
        const totalVotesSum = answers.reduce(
            (accumulator: number, currentValue: { votes: number }) =>
                accumulator + currentValue.votes,
            initialValue
        )

        const percent = Math.round((votes / totalVotesSum) * 100)

        if (Number.isInteger(percent)) {
            return percent
        } else {
            return 0
        }
    }

    const animateAnswers = (
        results: IAnswer[],
        refs: MutableRefObject<RefObject<HTMLDivElement>[]>,
        theme?: any,
        index?: number,
        answerId?: string,
        isVotedId?: string
    ): void => {
        const answersBuf: HTMLElement[] = []
        let unclickedAnswers = []
        let restOfAnswers: IAnswer[] = []

        for (const result of results) {
            if (index !== undefined) {
                result.id !== answerId && restOfAnswers.push(result)
            } else {
                restOfAnswers = results
            }
            const answerBuffer: HTMLElement | null = refs.current[index].current
            answerBuffer && answersBuf.push(answerBuffer)
        }

        const anotherRef = refs.current.filter((_item, itemIndex) => itemIndex !== index)
        unclickedAnswers.push(anotherRef)
        unclickedAnswers = unclickedAnswers.flat()

        if (index !== undefined) {
            answersBuf[index].animate(
                [
                    { width: 0, easing: 'ease-out', backgroundColor: 'white' },
                    {
                        width: `${calculatePercent(results[index].votes, results)}%`,
                        easing: 'ease-out',
                        backgroundColor: `${theme?.mainColor}`,
                    },
                ],
                500
            )
            answersBuf[index].style.width = `${calculatePercent(results[index].votes, results)}%`
            if (theme?.mainColor) answersBuf[index].style.backgroundColor = theme?.mainColor
        }

        for (let i = 0; i < restOfAnswers.length; i++) {
            if (unclickedAnswers[i]?.current) {
                unclickedAnswers[i].current.animate(
                    [
                        { width: 0, easing: 'ease-out', backgroundColor: 'white' },
                        {
                            width: `${calculatePercent(restOfAnswers[i].votes, results)}%`,
                            easing: 'ease-out',
                            backgroundColor: `${
                                restOfAnswers[i].id === isVotedId ? theme?.mainColor : '#efefef'
                            }`,
                        },
                    ],
                    500
                )
                unclickedAnswers[i].current.style.width = `${calculatePercent(
                    restOfAnswers[i].votes,
                    results
                )}%`
                unclickedAnswers[i].current.style.backgroundColor = `${
                    restOfAnswers[i].id === isVotedId ? theme?.mainColor : '#efefef'
                }`
            }
        }
    }

    useEffect(() => {
        if (isVoted) {
            const answerIndex = answers.findIndex((item) => item.option === answer)

            animateAnswers(answers, answerRefs, theme, answerIndex, isVotedId, isVotedId)
            setVoted(true)
        }
    }, [answer, answers, isVoted, theme])

    const handleClickAnswer = (index: number, id: string) => {
        if (!voted) {
            setVoted(true)
            animateAnswers(answers, answerRefs, theme, index, id, isVotedId)
            onVote(answers[index].option)
        }
    }

    return (
        <Container align={theme.alignment} color={theme?.textColor} mode={theme.mode}>
            {question && <h1>{question}</h1>}

            {answers.map((result, index) => (
                <div
                    key={result.id}
                    role="button"
                    id={result.id}
                    className={voted ? 'answer' : 'answer_hover' + ' ' + 'answer'}
                    style={{
                        backgroundColor: theme?.backgroundColor,
                    }}
                    onClick={() => handleClickAnswer(index, result.id)}
                    onTouchEnd={() => handleClickAnswer(index, result.id)}
                >
                    <AnswerInner ref={answerRefs.current[index]}>
                        <p>{result.option}</p>
                    </AnswerInner>
                    {voted && <span>{calculatePercent(result.votes, answers)}%</span>}
                </div>
            ))}
        </Container>
    )
}

const Container = styled.article<{
    align: string
    color: string
    mode: string[]
}>`
    color: ${(props) => props.mode[0]};
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border-bottom: 1px solid #d6d6d6;
        text-align: ${(props) => props.align};
        color: ${(props) => props.color};
    }

    .answer {
        width: 95%;
        margin-bottom: 1rem;
        border: ${(props) => `2px solid ${props.mode[1]}`};
        border-radius: 0.5rem;
        -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        transition: background-color 0.3s ease-out;
        cursor: pointer;
    }
    .answer_hover:hover {
        background-color: #efefef !important;
    }
    .answerInner {
        width: 0;
        left: 0;
        margin: 0;
        height: 100%;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        text-align: left;
    }
    .answerInner p {
        margin: 0;
        padding: 1rem;
        font-weight: bold;
    }
    .answer span {
        position: absolute;
        right: 1rem;
        top: 25%;
        font-size: 1.2rem;
    }
`
