class QRCode {
    value: string
    ecLevel: any
    enableCORS: boolean
    size: number
    quietZone: number
    bgColor: string
    fgColor: string
    logoImage: string | ArrayBuffer
    logoWidth: number
    logoHeight: number
    logoOpacity: number
    logoFileName: string
    qrStyle: string
    file: File | undefined

    constructor(
        value,
        ecLevel,
        enableCORS,
        size,
        quietZone,
        bgColor,
        fgColor,
        logoImage,
        logoWidth,
        logoHeight,
        logoOpacity,
        qrStyle,
        file
    ) {
        this.value = value
        this.ecLevel = ecLevel
        this.enableCORS = enableCORS
        this.size = size
        this.quietZone = quietZone
        this.bgColor = bgColor
        this.fgColor = fgColor
        this.logoImage = logoImage
        this.logoWidth = logoWidth
        this.logoHeight = logoHeight
        this.logoOpacity = logoOpacity
        this.qrStyle = qrStyle
        this.file = file
    }
}

export default QRCode
