import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const modalVariant = {
    initial: { opacity: 0 },
    isOpen: { opacity: 1 },
    exit: { opacity: 0 },
}
const containerVariant = {
    initial: { top: '-50%', transition: { type: 'spring' } },
    isOpen: { top: '50%' },
    exit: { top: '-50%' },
}
export const Modal = ({ children, isOpen }) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <Overlay
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                    variants={modalVariant}
                >
                    <ModalContainer variants={containerVariant}>{children}</ModalContainer>
                </Overlay>
            )}
        </AnimatePresence>
    )
}

const Overlay = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
`
const ModalContainer = styled(motion.div)`
    width: 100%;
    max-width: 32em;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
`
