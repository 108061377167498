import React, { forwardRef, useRef, useEffect, MouseEvent, memo } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import { IWidget } from '../../../../models/Widget'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import RenderPreview from '../../../Templates/Custom/Components/WidgetPreview/RenderPreview'
import RenderWidgets from '../../../Templates/Custom/Live/RenderWidgets'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget: any
    parentIndex: number
    isPreview?: boolean
    index?: number
    currentLayer?: number
    isPopup?: boolean
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
}

const PreviewComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
    return (
        <div
            className="container-sortable w-full mx-auto h-full grid"
            style={{ minHeight: '7em' }}
            ref={ref}
        >
            {props.children}
        </div>
    )
})

const Container = ({
    widget,
    index,
    parentIndex,
    currentLayer,
    isPopup,
    isPreview = false,
    updateActiveWidget,
}: Props) => {
    const container = useRef(null)
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    let appearanceSettings = widget.appearanceSettings
    let colourSettings = widget.colourSettings

    if (!appearanceSettings) {
        if (Object.keys(widget.settings).length > 0) {
            appearanceSettings = {
                ...widget.settings,
            }
        }
    }

    if (!colourSettings) {
        colourSettings = {
            ...widget.settings,
        }
    }

    useEffect(() => {
        if (isPreview) {
            const columns = appearanceSettings.columns || 1
            const alignItems = appearanceSettings.alignItems || 'center'
            const sortableContainer = container.current.querySelector('.container-sortable')
            sortableContainer.style.gridTemplateColumns = `repeat(${columns},1fr)`
            sortableContainer.style.alignItems = alignItems
        }
    }, [])

    const backgroundImageUrl =
        appearanceSettings.backgroundImageUrl || widget.settings.background_image_url
    const backgroundColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']
    const paddingTop =
        appearanceSettings.paddingTop / 10 + 'em' || widget.settings.padding_top / 10 + 'em'
    const paddingBottom =
        appearanceSettings.paddingBottom / 10 + 'em' || widget.settings.padding_bottom / 10 + 'em'
    const paddingLeft =
        appearanceSettings.paddingLeft / 10 + 'em' || widget.settings.padding_left / 10 + 'em'
    const paddingRight =
        appearanceSettings.paddingRight / 10 + 'em' || widget.settings.padding_right / 10 + 'em'
    const width = appearanceSettings.width + '%'
    const borderTopWidth =
        appearanceSettings.borderTopWidth / 10 + 'em' || widget.settings.border_width / 10 + 'em'
    const borderBottomWidth =
        appearanceSettings.borderBottomWidth / 10 + 'em' || widget.settings.border_width / 10 + 'em'
    const borderLeftWidth =
        appearanceSettings.borderLeftWidth / 10 + 'em' || widget.settings.border_width / 10 + 'em'
    const borderRightWidth =
        appearanceSettings.borderRightWidth / 10 + 'em' || widget.settings.border_width / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const marginTop =
        appearanceSettings.marginTop / 10 + 'em' || appearanceSettings.margin_top / 10 + 'em'
    const marginBottom =
        appearanceSettings.marginBottom / 10 + 'em' || appearanceSettings.margin_bottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    //const margin_left = widget.settings.marginLeft/10 + 'em' || widget.settings.margin_left/10 + 'em'
    // const margin_right = widget.settings.marginRight/10 + 'em' || widget.settings.margin_right/10 + 'em'
    const borderTopLeftRadius =
        appearanceSettings.borderTopLeftRadius / 10 + 'em' ||
        appearanceSettings.border_radius / 10 + 'em'
    const borderTopRightRadius =
        appearanceSettings.borderTopRightRadius / 10 + 'em' ||
        appearanceSettings.border_radius / 10 + 'em'
    const borderBottomLeftRadius =
        appearanceSettings.borderBottomLeftRadius / 10 + 'em' ||
        appearanceSettings.border_radius / 10 + 'em'
    const borderBottomRightRadius =
        appearanceSettings.borderBottomRightRadius / 10 + 'em' ||
        appearanceSettings.border_radius / 10 + 'em'
    const shiftRight = appearanceSettings?.boxShadow?.shiftRight
    const shiftDown = appearanceSettings?.boxShadow?.shiftDown
    const spread = appearanceSettings?.boxShadow?.spread
    const blur = appearanceSettings?.boxShadow?.blur
    const shadowColor = appearanceSettings?.boxShadow?.colour[0]
    const boxShadow = `${shiftRight}px ${shiftDown}px ${spread}px ${blur}px ${shadowColor}`
    const widgets = widget.widgets
    // const uniqueClass = `c-${new Date().getTime()}`

    //border hover:border-solid border-[#]

    const isChildrenGame = widget?.widgets?.some(
        (widget: { category: string }) => widget.category === 'game'
    )

    const checkJustify = (align: string) => {
        if (align === 'left') {
            return 'flex-start'
        } else if (align === 'end') {
            return 'flex-end'
        } else {
            return 'center'
        }
    }

    return (
        <Wrapper alignment={checkJustify(appearanceSettings?.alignItems)}>
            <ContainerWrapper
                isActive={isActive}
                isPreview={isPreview}
                isChildrenGame={isChildrenGame}
                sectionState={sectionState}
                width={width}
                borderTopWidth={borderTopWidth}
                borderLeftWidth={borderLeftWidth}
                borderRightWidth={borderRightWidth}
                borderBottomWidth={borderBottomWidth}
                borderWidth={borderWidth}
                borderColor={borderColor}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                ref={container}
                onClick={onEditMode}
                boxShadow={boxShadow}
                style={{
                    backgroundImage: `url(${backgroundImageUrl || ''})`,
                    backgroundColor,
                    borderTopLeftRadius,
                    borderTopRightRadius,
                    borderBottomLeftRadius,
                    borderBottomRightRadius,
                    borderColor,
                    paddingTop,
                    paddingBottom,
                    paddingLeft,
                    paddingRight,
                    alignItems: `${isPreview ? 'center' : appearanceSettings.alignItems}`,
                    gridTemplateColumns: `${
                        isPreview
                            ? 'repeat(1,1fr)'
                            : 'repeat(' + appearanceSettings.columns + ',1fr)'
                    }`,
                }}
            >
                {isPreview && (
                    <RenderPreview
                        currentLayer={currentLayer}
                        isPopup={isPopup}
                        parentIndex={index}
                        widget={widget}
                        PreviewComponent={PreviewComponent}
                    />
                )}

                {!isPreview && (
                    <RenderWidgets id={widget.id} widgets={widgets} parentIndex={parentIndex} />
                )}
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </ContainerWrapper>
        </Wrapper>
    )
}

export default memo(Container)

const Wrapper = styled.div<{
    alignment: string
}>`
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.alignment};
    width: 100%;
`

const ContainerWrapper = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    width: string
    isPreview: boolean
    isChildrenGame: boolean
    borderTopWidth: string
    borderLeftWidth: string
    borderRightWidth: string
    borderBottomWidth: string
    borderWidth: string
    borderColor: string
    sectionState: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    boxShadow: string
}>`
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: grid;
    height: auto;
    outline: none;
    cursor: pointer;
    width: ${(props) => props.width};
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    overflow-y: ${(props) => !props.isPreview && 'hidden'};
    overflow-x: ${(props) => !props.isPreview && 'hidden'};
    border: ${(props) => `${props.borderWidth} solid ${props.borderColor}`};
    border-top-width: ${(props) => props.borderTopWidth};
    border-bottom-width: ${(props) => props.borderBottomWidth};
    border-right-width: ${(props) => props.borderRightWidth};
    border-left-width: ${(props) => props.borderLeftWidth};
    box-shadow: ${(props) => props.boxShadow};

    &:hover {
        & > .hover_block {
            display: ${(props) =>
                props.isChildrenGame && props.sectionState === 'widgetEditSettings'
                    ? 'none'
                    : 'block'};
            position: absolute;
            background: none;
            pointer-events: none;
            box-sizing: border-box;
            top: ${(props) => `-${props.borderTopWidth}`};
            left: ${(props) => `-${props.borderLeftWidth}`};
            right: ${(props) => `-${props.borderRightWidth}`};
            bottom: ${(props) => `-${props.borderBottomWidth}`};
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        pointer-events: none;
        top: ${(props) => `-${props.borderTopWidth}`};
        left: ${(props) => `-${props.borderLeftWidth}`};
        right: ${(props) => `-${props.borderRightWidth}`};
        bottom: ${(props) => `-${props.borderBottomWidth}`};
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }

    .container-sortable {
    }
`
