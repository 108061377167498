import { nanoid } from 'nanoid'

export enum ButtonAction {
    REDIRECT = 'redirect',
    GO_TO_VIEW = 'go to page',
    TOGGLE_POPUP = 'toggle popup',
    START_GAME = 'start game',
}

export interface IButtonSettings {
    id: string
    linkTitle: string
    linkUrl: string
    type: ButtonAction // redirect, go to page, open popup, close popup
    layerIndex: number
    viewIndex: number
    widgetSettings: { [key: string]: any }
}

class ButtonSettings implements IButtonSettings {
    id = nanoid()
    linkTitle = ''
    linkUrl = ''
    type = ButtonAction.REDIRECT
    layerIndex = 0
    viewIndex = 0
    widgetSettings = {}

    constructor(linkTitle, linkUrl, type, layerIndex, viewIndex) {
        this.linkTitle = linkTitle
        this.linkUrl = linkUrl
        this.type = type
        this.layerIndex = layerIndex // if type === moveToComponent / showComponent / closeComponent, then find widgetIndex in settings.widget and move/show
        this.viewIndex = viewIndex // if type === goToView , then find view from viewIndex in settings to display
    }
}

export default ButtonSettings
