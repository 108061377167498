import React, { FC, MouseEvent } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { useCountdown } from '../../../../lib/hooks/useCountdown'
import { IWidget } from '../../../../models/Widget'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget?: IWidget
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    isPreview?: boolean
}

const Countdown: FC<Props> = ({
    widget,
    parentIndex,
    index,
    isPreview = false,
    updateActiveWidget,
}) => {
    const [total, days, hours, minutes, seconds] = useCountdown(widget?.settings?.endDate)
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'
    const fontFamily = widget?.appearanceSettings.font.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')

    const color = widget?.colourSettings?.textColour[0]

    const handleClickOn = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    return (
        <Wrapper>
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                paddingTop={widget?.appearanceSettings?.paddingTop}
                paddingBottom={widget?.appearanceSettings?.paddingBottom}
                paddingLeft={widget?.appearanceSettings?.paddingLeft}
                paddingRight={widget?.appearanceSettings?.paddingRight}
                marginTop={widget?.appearanceSettings?.marginTop}
                marginBottom={widget?.appearanceSettings?.marginBottom}
                marginLeft={widget?.appearanceSettings?.marginLeft}
                marginRight={widget?.appearanceSettings?.marginRight}
                onClick={handleClickOn}
            >
                {total > 0 ? (
                    <WrapperDiv
                        color={color}
                        align={widget?.appearanceSettings?.align}
                        fontFamily={widget?.appearanceSettings?.font?.family}
                        fontSize={widget?.appearanceSettings?.font?.size}
                        weight={widget?.appearanceSettings?.font?.weight}
                        lineHeight={widget?.appearanceSettings?.font?.lineHeight}
                        letterSpacing={widget?.appearanceSettings?.font?.spacing}
                    >
                        <TimeDiv>
                            {days}
                            <Timespan>Days</Timespan>
                        </TimeDiv>
                        <TimeDiv>
                            {hours} <Timespan>Hours</Timespan>
                        </TimeDiv>
                        <TimeDiv>
                            {minutes} <Timespan>Minutes</Timespan>
                        </TimeDiv>
                        <TimeDiv>
                            {seconds} <Timespan>Seconds</Timespan>
                        </TimeDiv>
                    </WrapperDiv>
                ) : (
                    <WrapperDiv
                        align={widget?.appearanceSettings?.align}
                        fontFamily={widget?.appearanceSettings?.font?.family}
                        fontSize={widget?.appearanceSettings?.font?.size}
                        weight={widget?.appearanceSettings?.font?.weight}
                        lineHeight={widget?.appearanceSettings?.font?.lineHeight}
                        letterSpacing={widget?.appearanceSettings?.font?.spacing}
                        color={color}
                    >
                        {widget?.settings?.endDateText}
                    </WrapperDiv>
                )}

                {isPreview && <EditingIndicator widgetName={capitalizeFirstLetter(widget?.name)} />}
            </Container>
        </Wrapper>
    )
}

const WrapperDiv = styled.div.attrs((props) => ({}))<{
    fontFamily: string
    fontSize: string
    letterSpacing: string
    align: string
    weight: string
    lineHeight: number
    color: string
}>`
    width: 100%;
    display: flex;
    justify-content: ${(props) => props.align};
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize}px;
    letter-spacing: ${(props) => props.letterSpacing}px;
    font-weight: ${(props) => props.weight};
    line-height: ${(props) => props.lineHeight};
    align-items: center;
    color: ${(props) => props.color};
`
const TimeDiv = styled.div`
    letter-spacing: inherit;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 0.5rem;
`
const Timespan = styled.span`
    letter-spacing: inherit;
    display: flex;
    flex-direction: column;
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    isPreview: boolean
}>`
    position: relative;
    cursor: pointer;
    width: 100%;
    padding-top: ${(props) => props.paddingTop}px;
    padding-bottom: ${(props) => props.paddingBottom}px;
    padding-left: ${(props) => props.paddingLeft}px;
    padding-right: ${(props) => props.paddingRight}px;
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    margin-left: ${(props) => props.marginLeft}px;
    margin-right: ${(props) => props.marginRight}px;
    a {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        pointer-events: ${(props) => (props.isPreview ? 'none' : 'auto')};
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`

export default Countdown
