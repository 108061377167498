import React, { FC } from 'react'
import styled from 'styled-components'
import { IAnswer, PollsType } from '../../../../models/General/Polls'
import { DoublePoll } from './DoublePoll'
import { MultiplePoll } from './MultiplePoll'
import { PollsTheme } from './Polls'

interface Props {
    pollAnswers: IAnswer[]
    dataFromLocalStorage: { [key: string]: string }
    question: string
    isVoted: boolean
    customStyles: PollsTheme
    handleVote: (answer: string) => void
    pollsType: string
}

const PollsWidget: FC<Props> = ({
    pollAnswers,
    dataFromLocalStorage,
    question,
    isVoted,
    customStyles,
    handleVote,
    pollsType,
}) => {
    const initialValue = 0
    const totalVotesSum = pollAnswers.reduce(
        (accumulator: number, currentValue: { votes: number }) => accumulator + currentValue.votes,
        initialValue
    )

    return (
        <>
            {pollsType === PollsType.DOUBLE ? (
                <DoublePoll
                    question={question}
                    answers={pollAnswers}
                    onVote={handleVote}
                    theme={customStyles}
                    isVoted={isVoted}
                />
            ) : (
                <MultiplePoll
                    question={question}
                    answers={pollAnswers}
                    theme={customStyles}
                    onVote={handleVote}
                    isVoted={isVoted}
                    isVotedId={dataFromLocalStorage && dataFromLocalStorage.voteId}
                    answer={dataFromLocalStorage && dataFromLocalStorage.answer}
                />
            )}

            <TotalVotes>{totalVotesSum} votes</TotalVotes>
        </>
    )
}

export default PollsWidget

const TotalVotes = styled.span`
    margin: 10px 0;
`
