import React from 'react'
import Rating, { IconContainerProps } from '@material-ui/lab/Rating'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import styled from 'styled-components'

interface Props {
    value: number
    name: string
    handleChange: (event: any, value: number) => void
    color: string
}

const customIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied',
    },
}

function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props
    return <span {...other}>{customIcons[value].icon}</span>
}

const Sentiment = ({ name, value, handleChange, color }: Props) => {
    return (
        <RatingContainer color={color}>
            <Rating
                name={name}
                defaultValue={value}
                value={value}
                getLabelText={(value: number) => customIcons[value].label}
                IconContainerComponent={IconContainer}
                onChange={(event, newValue) => handleChange(event, newValue)}
            />
        </RatingContainer>
    )
}

export default Sentiment

const RatingContainer = styled.div.attrs((props) => ({}))<{
    color: string
}>`
    display: flex;
    .MuiRating-label {
        color: ${(props) => (props.color ? props.color : 'orange')};
    }
`
