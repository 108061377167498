import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Timer } from './Timer'
import Questions from './Questions'
import Modal from '../Modal'
import ModalContent from './ModalContent'
import { ISegment } from '../../../../models/Games/Segment'
import { CustomerAuth } from '../../../../stores/auth/customer/customerAuth'
import { SetterOrUpdater, useRecoilValue } from 'recoil'
import { IQuestion } from '../../../../models/Games/QuestionsQuiz'
import GameRules from '../GameRules'
import { gameRunningState } from '../../../../stores/widgets/game/state'
import { Toast } from '../../../../helpers/Toast'

interface Props {
    reward: ISegment
    userData: CustomerAuth
    widget: any
    setReward?: SetterOrUpdater<any>
    trackAttempt: () => Promise<void>
    isPreview: boolean
    color: string
    lastAttemptTime: number | null
    setError?: Dispatch<SetStateAction<string>>
}

const QuizGameComponent: FC<Props> = ({
    reward,
    setReward,
    widget,
    userData,
    trackAttempt,
    isPreview,
    color,
    lastAttemptTime,
    setError,
}) => {
    const [questionNumber, setQuestionNumber] = useState(1)
    const [endGame, setEndGame] = useState(false)
    const [earned, setEarned] = useState(null)
    const startGame = useRecoilValue(gameRunningState)

    const data: IQuestion[] = widget?.settings?.questions

    useEffect(() => {
        if (questionNumber > 1) {
            const completedPrecent = ((questionNumber - 1) / data.length) * 100
            setEarned(Math.round(completedPrecent))
        }
    }, [questionNumber, data])

    const handleToggleModal = useCallback(() => {
        setEndGame((prev) => !prev)
        setQuestionNumber(null)
    }, [])

    const renderQuestions = () => {
        if (!isPreview && questionNumber && !widget?.settings?.unSetPrizes && userData.userId) {
            return (
                <Questions
                    data={data}
                    setStop={setEndGame}
                    questionNumber={questionNumber}
                    setQuestionNumber={setQuestionNumber}
                    color={color}
                />
            )
        } else if (startGame || widget?.settings?.unUsePreview) {
            return (
                <Questions
                    data={data}
                    setStop={setEndGame}
                    questionNumber={questionNumber}
                    setQuestionNumber={setQuestionNumber}
                    color={color}
                />
            )
        } else {
            return (
                <img
                    style={{ margin: '0 auto', objectFit: 'cover' }}
                    width={200}
                    height={180}
                    src={widget?.settings?.previewImage}
                    alt="quiz preview"
                />
            )
        }
    }

    //This function for unauthorized users
    const updateGameAttempts = async (
        campaignId: string,
        widgetId: string,
        totalAttempts: number | string
    ) => {
        try {
            const res = await fetch('/api/game/updateAttemptCount', {
                method: 'POST',
                body: JSON.stringify({ campaignId, widgetId, totalAttempts }),
            })

            const result = await res.json()
            if (result.code !== 200) {
                Toast(result.message, 'error')
                setError(result.message)
            }
        } catch (error) {
            setError('Something went wrong')
            console.log(error)
        }
    }

    return (
        <>
            {!widget?.settings?.unUseTimer && (
                <Timer
                    timeLimit={widget?.settings?.timeLimit}
                    setStop={setEndGame}
                    questionNumber={questionNumber}
                    widget={widget}
                    userData={userData}
                    lastAttemptTime={lastAttemptTime}
                />
            )}

            {renderQuestions()}

            <GameRules color={color}>
                <h1>Rules of quiz game</h1>
                <span>
                    The aim of the game is to answer as many correct answers as possible for your
                    chance to win rewards.
                    <img src="/images/quiz-preview.png" alt="quiz game" />
                </span>
            </GameRules>

            {!isPreview && (
                <Modal isOpen={endGame}>
                    <ModalContent
                        reward={reward}
                        isWinner={earned >= widget?.settings?.winningPrecent}
                        accruedInterest={earned}
                        widget={widget}
                        setReward={setReward}
                        userData={userData}
                        handleToggleModal={handleToggleModal}
                        trackAttempt={trackAttempt}
                        updateGameAttempts={updateGameAttempts}
                        setQuestionNumber={setQuestionNumber}
                    />
                </Modal>
            )}
        </>
    )
}

export default QuizGameComponent
