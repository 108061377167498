import React from 'react'
import Rating from '@material-ui/lab/Rating'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import styled from 'styled-components'

enum Size {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

interface Props {
    type: string
    value: number
    max: number
    name: string
    handleChange: (event: any, newValue: number) => void
    size: Size
    color: string
}

const CustomRating = ({ name, value, max = 5, size, handleChange, color }: Props) => {
    return (
        <RatingContainer color={color}>
            <Rating
                size={size}
                name={name}
                max={max}
                precision={0.5}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                value={value}
                onChange={(event, newValue) => handleChange(event, newValue)}
            />
        </RatingContainer>
    )
}

export default CustomRating

const RatingContainer = styled.div.attrs((props) => ({}))<{
    color: string
}>`
    display: flex;
    .MuiSvgIcon-root {
        color: ${(props) => (props.color ? props.color : 'orange')};
    }
`
