import React, { memo, MouseEvent } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { windowSizeState } from '../../../../stores/campaign/live/state'
import { mobilePreviewState } from '../../../../stores/builder/mobilePreview'
import { IWidget } from '../../../../models/Widget'
import { viewPreviewSize } from '../../../../stores/builder/view/viewPreviewSize'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget?: IWidget
    isPreview?: boolean
    isViewPreview?: boolean
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    index?: number
    parentIndex?: number
}

const Iframe = ({
    widget,
    isPreview = false,
    isViewPreview = false,
    updateActiveWidget,
    parentIndex,
    index,
}: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const handleClickOn = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    let windowState = windowSizeState

    if (isViewPreview) {
        windowState = viewPreviewSize
    }

    if (!isPreview && !isViewPreview) {
        windowState = windowSizeState
    }

    const windowSize = useRecoilValue(windowState)

    let appearanceSettings = widget.appearanceSettings
    let colourSettings = widget.colourSettings
    if (!appearanceSettings) {
        appearanceSettings = {
            align: widget.settings.align,
            borderRadius: widget.settings.border_radius,
            borderWidth: widget.settings.border_width,
            height: widget.settings.height,
            marginBottom: widget.settings.margin_bottom,
            marginLeft: widget.settings.margin_left,
            marginRight: widget.settings.margin_right,
            marginTop: widget.settings.margin_top,
            paddingBottom: widget.settings.padding_bottom,
            paddingLeft: widget.settings.padding_left,
            paddingRight: widget.settings.padding_right,
            paddingTop: widget.settings.padding_top,
            width: widget.settings.width,
        }
    }

    if (!colourSettings) {
        colourSettings = {
            'background colour': widget.settings.background_colour,
            'border colour': widget.settings.border_colour,
        }
    }

    const name = widget.name
    const src = widget.settings.src
    const width = appearanceSettings.width + '%'
    const height = (appearanceSettings.height / 100) * windowSize.height + 'px'

    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings.marginRight / 10 + 'em'
    const borderWidth = appearanceSettings.borderWidth / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']
    const backgroundColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']

    return (
        <Wrapper onClick={handleClickOn}>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                height={height}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
            >
                <iframe
                    src={src}
                    loading="lazy"
                    name={name}
                    title={name}
                    width={width}
                    height={height}
                    style={{
                        backgroundColor,
                        borderWidth,
                        borderColor,
                    }}
                    frameBorder="0"
                    allowFullScreen
                    seamless
                    sandbox="allow-forms allow-modals allow-popups allow-presentation allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation  allow-top-navigation-by-user-activation"
                    referrerPolicy="strict-origin-when-cross-origin"
                >
                    <p className="text-sm">Your browser does not support iframes.</p>
                </iframe>
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Iframe)

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
    width: string
    height: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
}>`
    position: relative;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};

    iframe {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        pointer-events: ${(props) => (props.isPreview ? 'none' : 'auto')};
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
