import { Toast } from '../../helpers/Toast'
import { Widget } from '../../models/Widget'

export const filterFormWidgets = (widgets: Widget[], type: string | undefined): any[] => {
    if (type !== 'form') {
        const index = widgets.findIndex((widget) => {
            if (
                widget.type === 'input box' ||
                widget.type === 'submit' ||
                widget.type === 'text area' ||
                widget.type === 'select' ||
                widget.type === 'checkbox' ||
                widget.type === 'upload' ||
                widget.type === 'radio' ||
                widget.type === 'rating' ||
                widget.type === 'calendar' ||
                widget.type === 'datepicker'
            ) {
                return true
            }
        })

        if (index > -1) {
            const filtered = widgets.filter((widget) => widget.type !== widgets[index].type)
            Toast(`${widgets[index].name} can only be placed in a form container`, 'error')
            return filtered
        }
    } else if (type === 'form') {
        const index = widgets.findIndex((widget) => {
            if (
                widget.type === 'button' ||
                widget.type === 'gamebutton' ||
                widget.type === 'audio' ||
                widget.type === 'spotify' ||
                widget.type === 'link text' ||
                widget.type === 'rich text' ||
                widget.type === 'events' ||
                widget.type === 'social' ||
                widget.type === 'video' ||
                widget.type === 'profile card' ||
                widget.type === 'iframe' ||
                widget.type === 'image' ||
                widget.type === 'polls' ||
                widget.type === 'swiper' ||
                widget.type === 'stepper' ||
                widget.type === 'form' ||
                widget.type === 'countdown'
            ) {
                return true
            }
        })
        if (index > -1) {
            const filtered = widgets.filter((widget) => widget.type !== widgets[index].type)
            Toast(`${widgets[index].name} can not be placed in a form container`, 'error')
            return filtered
        }
    }
    if (type === 'container' || type === 'form') {
        const index = widgets.findIndex((widget) => {
            if (widget.type === 'container' || widget.type === 'form') {
                return true
            }
        })

        if (index > -1) {
            const filtered = widgets.filter((widget) => widget.type !== widgets[index].type)
            Toast(`${widgets[index].name} can not be placed in a container or form`, 'error')
            return filtered
        }
    }

    return widgets
}
