import { useRecoilState, useSetRecoilState } from 'recoil'
import { viewSectionState } from '../../../stores/builder/viewSectionState'
import { widgetCategoryIndexState } from '../../../stores/builder/widgetCategoryIndexState'

export const useWidgetCategory = (): { set: () => void } => {
    const [sectionState, setSectionState] = useRecoilState(viewSectionState)
    const setWidgetCategory = useSetRecoilState(widgetCategoryIndexState)

    return {
        set: (): void => {
            if (sectionState === 'widgetEditSettings') {
                setSectionState('widgetPicker')
                // setWidgetCategory(2)
            }
        },
    }
}
