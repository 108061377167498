import React, { useState } from 'react'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import styled from 'styled-components'

import { IButtonSettings } from '../../../../models/ButtonSettings'
import { customerAuth } from '../../../../stores/auth/customer/customerAuth'
import { loginModal } from '../../../../stores/auth/customer/loginModal'
import { gameErrors, gameRunningState } from '../../../../stores/widgets/game/state'
import Widget from '../../../../types/Widget'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import Checkbox from '../../../Inputs/Checkbox'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget: Widget
    action: IButtonSettings
    onEditMode: (e) => void
    isActiveButton: boolean
    isPreview: boolean
}

const StartGameButton = ({ widget, action, onEditMode, isActiveButton, isPreview }: Props) => {
    const _customerAuth = useRecoilValue(customerAuth)
    const setAuthVisible = useSetRecoilState(loginModal)
    const [startGame, setStartGame] = useRecoilState(gameRunningState)
    const startGameErrors = useRecoilValue(gameErrors)
    const [agree, setAgree] = useState(false)

    let appearanceSettings = widget.appearanceSettings
    let colourSettings = widget.colourSettings

    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget.settings,
        }
    }

    if (!colourSettings) {
        colourSettings = {
            'text colour': widget.settings.textColour,
            'button colour': widget.settings.buttonColour,
            'border colour': widget.settings.borderColour,
        }
    }

    const disableAuthorization = widget?.settings?.disableAuthorization
    const width = appearanceSettings.width + '%'
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const fontFamily = appearanceSettings.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'
    const weight = appearanceSettings?.font?.weight

    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'

    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`

    const color = Array.isArray(colourSettings['text colour'])
        ? colourSettings['text colour'][0]
        : colourSettings['text colour']
    const backgroundColor = Array.isArray(colourSettings['button colour'])
        ? colourSettings['button colour'][0]
        : colourSettings['button colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']

    const handleClickOn = () => {
        if (!disableAuthorization && !_customerAuth.userId) {
            setAuthVisible(true)
            return
        }
        setStartGame(true)
    }

    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    return (
        <Container
            isActiveButton={isActiveButton}
            isPreview={isPreview}
            width={width}
            borderTopLeftRadius={borderTopLeftRadius}
            borderBottomLeftRadius={borderBottomLeftRadius}
            borderTopRightRadius={borderTopRightRadius}
            borderBottomRightRadius={borderBottomRightRadius}
            color={color}
            onClick={onEditMode}
            error={startGameErrors}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            {!disableAuthorization && !_customerAuth.userId && (
                <div className="agrees">
                    <Checkbox
                        name="termsAndConditions"
                        text="I agree to terms and conditions"
                        checked={agree}
                        handleChange={() => setAgree((prev) => !prev)}
                    />
                </div>
            )}
            {((!_customerAuth.userId && !disableAuthorization) ||
                (!startGame && disableAuthorization)) && (
                <ButtonWrapper disabled={!disableAuthorization && !agree} error={startGameErrors}>
                    <button
                        type="button"
                        onClick={handleClickOn}
                        disabled={!disableAuthorization && !agree}
                        style={{
                            paddingTop,
                            paddingBottom,
                            paddingLeft,
                            paddingRight,
                            fontFamily,
                            backgroundColor,
                            fontWeight: weight,
                            color,
                            margin: `0 0`,
                            borderWidth,
                            borderColor,
                            fontSize,
                            letterSpacing,
                            lineHeight,
                        }}
                        className={`focus:outline-none ${isPreview && 'pointer-events-none'}`}
                    >
                        {action.linkTitle}
                    </button>
                </ButtonWrapper>
            )}
            {isPreview && <EditingIndicator widgetName={capitalizeFirstLetter(widget?.name)} />}
        </Container>
    )
}

export default StartGameButton

const ButtonWrapper = styled.div<{
    disabled: boolean
    error: string
}>`
    opacity: ${(props) => (props.disabled || props.error ? '0.5' : '1')};
`

const Container = styled.div.attrs((props) => ({}))<{
    isActiveButton: boolean
    width: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    isPreview: boolean
    color: string
    error: string
}>`
    position: relative;
    cursor: pointer;
    width: ${(props) => props.width};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    pointer-events: ${(props) => (props.error ? 'none' : 'auto')};

    button {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    .agrees {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            color: ${(props) => props.color};
        }
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActiveButton && '1px solid rgb(58, 226, 155)'};
        }
    }

    .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActiveButton ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActiveButton ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
