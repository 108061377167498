import React, { useRef, useState, useEffect, MouseEvent, memo } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ScratchMe from './SC'
import { Toast } from '../../../../helpers/Toast'
import { useAnalytics } from '../../../../lib/analytics/useAnalytics'
import { verifyAttempt } from '../../../../lib/reward/session/verifyAttempt'
import { IWidget } from '../../../../models/Widget'
import { customerAuth } from '../../../../stores/auth/customer/customerAuth'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { liveCampaignState } from '../../../../stores/campaign/live/state'
import { gameAttemptAvailable, rewardState } from '../../../../stores/widgets/game/state'
import { EditingIndicator } from '../../EditingIndicator'
import { ISegment } from '../../../../models/Games/Segment'
import { StartGameTitle } from '../StartGameTitle'
import { useCountdown } from '../../../../lib/hooks/useCountdown'
import { countdownTimeCalculate } from '../../../../utils/countdownTime'
import CountdownAttempt from '../CountdownAttempt'

interface Props {
    widget: IWidget
    isPreview: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
}

const Scratchcard = ({
    widget,
    isPreview = false,
    updateActiveWidget,
    index,
    parentIndex,
}: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)
    const campaign = useRecoilValue(liveCampaignState)
    const setAttemptAvailable = useSetRecoilState(gameAttemptAvailable(widget?.id))
    const [reward, setReward] = useRecoilState(rewardState(widget?.id))
    const _customerAuth = useRecoilValue(customerAuth)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
    const containerRef = useRef(null)
    const [errorState, setErrorState] = useState<string | null>(null)
    const [background, setBackground] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [lastAttemptTime, setLastAttemptTime] = useState(null)
    const analytics = useAnalytics('live')

    let attemptUpdated = false

    useEffect(() => {
        if (containerRef.current) {
            setContainerSize({
                width: containerRef.current.clientWidth,
                height: containerRef.current.clientWidth * 2,
            })
        }

        if (!_customerAuth.userId) {
            return
        }

        async function calculateReward(gameWidget: IWidget, campaignId: string) {
            setLoading(true)
            const res = await fetch('/api/reward/calculate', {
                method: 'POST',
                body: JSON.stringify({ campaignId, gameWidget }),
            })

            const result = await res.json()

            if (result.message === 'exceeded tries' && result.data.lastAttemptTime) {
                const timeToNextGame = countdownTimeCalculate(
                    gameWidget,
                    new Date(result.data.lastAttemptTime * 1000)
                )
                setLastAttemptTime(timeToNextGame)
            }

            if (result.code !== 200) {
                Toast('No more attempts for scratch card game', 'error')
                setErrorState('No more attempts for scratch card game')
                return
            }

            if (result.data.attempts !== undefined) {
                setReward(result.data.reward)
                const rewardId = result.data.reward.id
                const rewardIndex = gameWidget.settings.segmentData.findIndex(
                    (segment: any) => segment.id === rewardId
                )
                setBackground(gameWidget?.settings?.segmentData[rewardIndex]?.image)
            } else {
                setReward(result.data)
                const rewardId = result.data.id
                const rewardIndex = gameWidget.settings.segmentData.findIndex(
                    (segment: any) => segment.id === rewardId
                )
                setBackground(gameWidget?.settings?.segmentData[rewardIndex]?.image)
            }

            setAttemptAvailable(true)
            setLoading(false)
        }

        if (!isPreview && reward === null) {
            calculateReward(widget, campaign?.campaignId)
        }
    }, [_customerAuth.userId, campaign?.campaignId])

    const foreground = widget?.settings?.foregroundImage
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const completePercent = widget?.settings?.completePercent

    const width = (appearanceSettings?.width / 100) * containerSize?.width
    const height = (appearanceSettings?.height / 100) * containerSize?.height
    const strokeWidth = appearanceSettings?.strokeWidth

    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const borderWidth =
        appearanceSettings?.borderTopWidth / 10 + 'em' &&
        appearanceSettings?.borderBottomWidth / 10 + 'em' &&
        appearanceSettings?.borderBottomWidth / 10 + 'em' &&
        appearanceSettings?.borderRightWidth / 10 + 'em'

    const backgroundColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']
    const border = `${borderWidth} solid ${borderColor}`

    const completed = (rew: ISegment) => {
        setAttemptAvailable(false)
    }

    const trackProgress = async (percent: number): Promise<void> => {
        if (errorState) {
            Toast(errorState, 'error', widget.id)
            return
        }

        if (percent === 5 && !attemptUpdated) {
            attemptUpdated = true
            await verifyAttempt(
                campaign.campaignId,
                widget.id,
                widget.settings.attempts.count,
                widget.settings.attempts.period,
                widget.settings.attempts.totalAttempts
            )
            analytics.track('start game', { game: widget.name })
        }
    }

    const toggleError = () => {
        if (errorState) {
            Toast(errorState, 'error', widget.id)
        }
    }

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    return (
        <Wrapper onClick={onEditMode} startGame={!_customerAuth.userId}>
            <Container
                ref={containerRef}
                isActive={isActive}
                isPreview={isPreview}
                loading={loading}
                border={border}
                backgroundColor={backgroundColor}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
            >
                {isPreview ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                        alt="scratch card preview"
                        src={foreground}
                        style={{
                            width,
                            height,
                            objectFit: 'cover',
                            borderTopLeftRadius,
                            borderBottomLeftRadius,
                            borderTopRightRadius,
                            borderBottomRightRadius,
                        }}
                        onClick={toggleError}
                    />
                ) : (
                    <ScratchMe
                        width={width}
                        height={height}
                        reward={reward}
                        widget={widget}
                        setReward={setReward}
                        userData={_customerAuth}
                        foregroundImageSrc={foreground}
                        backgroundImageSrc={background}
                        strokeWidth={strokeWidth}
                        onProgress={trackProgress}
                        onCompleted={completed}
                        completedAt={completePercent}
                        borderTopLeftRadius={borderTopLeftRadius}
                        borderBottomLeftRadius={borderBottomLeftRadius}
                        borderTopRightRadius={borderTopRightRadius}
                        borderBottomRightRadius={borderBottomRightRadius}
                        userId={_customerAuth?.userId}
                    />
                )}
                {lastAttemptTime && _customerAuth?.userId && (
                    <CountdownAttempt
                        lastAttemptTime={lastAttemptTime}
                        fontFamily="Poppins"
                        color="#2d3748"
                    />
                )}
                {_customerAuth?.userId && !lastAttemptTime && (
                    <StartGameTitle gameType={widget?.type} />
                )}
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Scratchcard)

const Wrapper = styled.div<{
    startGame: boolean
}>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    canvas {
        pointer-events: ${(props) => (props.startGame ? 'none' : 'auto')};
    }
`

const Container = styled.div<{
    isActive: boolean
    isPreview: boolean
    loading: boolean
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    backgroundColor: string
    border: string
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85%;
    pointer-events: auto;
    position: relative;
    border: ${(props) => props.border};
    background-color: ${(props) => props.backgroundColor};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
