import { IEmailAttachment } from './EmailAttachment'

export interface IFormEmail {
    email: string
    addSocial: boolean
    social: {
        facebook?: string
        twitter?: string
        instagram?: string
        pinterest?: string
        linkedin?: string
    }
    width: number
    subject: string
    fontFamily: string
    fontUrl: string
    bannerImageUrl: string
    bannerImageFile: string | null
    bannerImageFileName: string
    backgroundColour: string
    backgroundFile: string
    backgroundFileName: string
    textColour: string
    content: string
    attachments: IEmailAttachment[]
    submissionId?: string
}

class FormEmail {
    email = ''
    addSocial = true
    social = {
        facebook: '',
        twitter: '',
        instagram: '',
        pinterest: '',
        linkedin: '',
    }
    width = 90
    subject = 'Form Submission Confirmation'
    fontFamily = 'Poppins'
    fontUrl =
        '@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);'
    bannerImageUrl =
        'https://marketing-image-production.s3.amazonaws.com/uploads/22ce43266efffe3ac9ec97f23349591fda87e67585bf5bdd298c5579ccc95653f12a980813de484f662580b5c67797ce40049968c443763998a867fe5639edf4.png'
    bannerImageFile = null
    bannerImageFileName = ''
    backgroundColour = '#fff'
    backgroundFile = null
    backgroundFileName = ''
    textColour = 'black'
    content =
        '<p>Hello!</p><p>&nbsp;</p><p>We have successfully received your form submission.</p><p>&nbsp;</p><p> Thanks!</p>'
    attachments = []
    submissionId = ''
}

export default FormEmail
