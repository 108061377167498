interface SubmissionResponse {
    data: {
        acknowledged: boolean
        insertedId: string
    }
    message: string
    code: number
}

export const submitForm = async (
    formData,
    uid: string,
    campaignId: string,
    formId: string,
    formName: string
): Promise<SubmissionResponse> => {
    try {
        const res = await fetch(
            `/api/form/submission?uid=${uid}&campaignId=${campaignId}&formId=${formId}&formName=${formName}`,
            {
                method: 'POST',
                body: JSON.stringify(formData),
            }
        )

        const data: SubmissionResponse = await res.json()

        return data
    } catch (error) {
        throw new Error(error.message)
    }
}
