import { atom, selector } from 'recoil'
import { IWidget } from '../../models/Widget'
import { campaignViewState } from '../campaign/edit/campaignState'
import { viewSectionState } from './viewSectionState'

export interface ActiveWidget {
    index: number
    currentViewIndex: number
    currentLayer: number
    parentId?: string
    parentIndex?: number
    widget: any
}

export const activeWidgetState = atom<ActiveWidget>({
    key: 'activeWidgetState',
    default: {
        index: 0,
        currentViewIndex: 0,
        parentIndex: -1,
        currentLayer: null,
        widget: null,
    },
})

export const activeWidgetSelector = selector<IWidget>({
    key: 'activeWidgetSelector',
    get: ({ get }) => {
        const sectionState = get(viewSectionState)
        const { widget, index, parentIndex, currentLayer, currentViewIndex } =
            get(activeWidgetState)
        //console.log(currentViewIndex)
        const views = get(campaignViewState)
        let foundWidget = null
        if (sectionState === 'widgetEditSettings') {
            //console.log('widget id', widget.id)

            const widgetId = widget.id
            views.map((view) => {
                const viewWidgets = view.widgets
                viewWidgets.map((viewWidget) => {
                    if (!foundWidget) {
                        if (viewWidget.id === widgetId) {
                            foundWidget = viewWidget
                            return
                        } else {
                            if (viewWidget.type === 'container' || viewWidget.type === 'form') {
                                const containerWidgets = viewWidget.widgets
                                containerWidgets.map((containerWidget) => {
                                    if (containerWidget.id === widgetId) {
                                        foundWidget = containerWidget
                                        return
                                    }
                                })
                            }
                        }
                    }
                })

                if (!foundWidget) {
                    const layers = view.layers
                    layers.map((layer) => {
                        if (!foundWidget) {
                            const layerWidgets = layer.widgets
                            layerWidgets.map((layerWidget) => {
                                if (!foundWidget) {
                                    if (layerWidget.id === widgetId) {
                                        foundWidget = layerWidget
                                        return
                                    }
                                    if (
                                        layerWidget.type === 'container' ||
                                        layerWidget.type === 'form'
                                    ) {
                                        const containerWidgets = layerWidget.widgets
                                        containerWidgets.map((containerWidget) => {
                                            if (containerWidget.id === widgetId) {
                                                foundWidget = containerWidget
                                                return
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    })
                }
            })

            return foundWidget
        }

        if (currentLayer === null) {
            if (typeof parentIndex == 'number' && parentIndex >= 0) {
                foundWidget = views[currentViewIndex]?.widgets[parentIndex]?.widgets?.[index]
            } else {
                foundWidget = views[currentViewIndex]?.widgets[index]
            }
        } else {
            if (typeof parentIndex == 'number' && parentIndex >= 0) {
                foundWidget =
                    views[currentViewIndex]?.layers[currentLayer]?.widgets[parentIndex]?.widgets[
                        index
                    ]
            } else {
                foundWidget = views[currentViewIndex]?.layers[currentLayer]?.widgets[index]
            }
        }
        return foundWidget
    },
})
