import React from 'react'
import styled from 'styled-components'
import { useCountdown } from '../../../lib/hooks/useCountdown'

interface Props {
    lastAttemptTime: Date
    fontFamily?: string
    color?: string
}

const CountdownAttempt = ({ lastAttemptTime, fontFamily, color }: Props) => {
    const [total, days, hours, minutes, seconds] = useCountdown(lastAttemptTime)

    return (
        <>
            {total > 0 ? (
                <Countdown fontFamily={fontFamily} color={color}>
                    <h3>Next game in:</h3>
                    <TimeInfo>
                        <div>
                            {days}
                            <span>Days</span>
                        </div>
                        <div>
                            {hours}
                            <span>Hours</span>
                        </div>
                        <div>
                            {minutes}
                            <span>Minutes</span>
                        </div>
                        <div>
                            {seconds}
                            <span>Seconds</span>
                        </div>
                    </TimeInfo>
                </Countdown>
            ) : null}
        </>
    )
}

export default CountdownAttempt

const Countdown = styled.div<{
    fontFamily: string
    color: string
}>`
    color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    background-color: #fc8181;
    padding: 10px 0;
    border-radius: 15px;
    font-family: ${(props) => props.fontFamily};
    & > h3 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 14px;
    }
`
const TimeInfo = styled.div`
    display: flex;
    justify-content: center;
    & > div {
        align-items: center;
        margin: 0 0.5rem;
        display: flex;
        flex-direction: column;
    }
`
