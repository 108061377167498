import React, { memo, MouseEvent } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import Widget from '../../../../types/Widget'
import { EditingIndicator } from '../../EditingIndicator'

interface AudioProps {
    widget: Widget
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: Widget) => void
}

const Audio = ({
    widget,
    isPreview = false,
    updateActiveWidget,
    index,
    parentIndex,
}: AudioProps) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    let appearanceSettings = widget?.appearanceSettings
    let colorSettings = widget?.colourSettings
    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget?.settings,
        }
    }

    const width = appearanceSettings?.width + '%'
    const height = appearanceSettings?.height + 'em'
    const src = widget?.settings?.src
    const align = appearanceSettings?.align
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'

    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'
    const color = Array.isArray(colorSettings?.labelColor)
        ? colorSettings?.labelColor[0]
        : colorSettings?.labelColor
    const weight = appearanceSettings?.font?.weight
    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    const autoPlay = widget?.settings?.autoPlay
    const loop = widget?.settings?.loop
    const muted = widget?.settings?.muted

    const label = widget?.settings?.label

    return (
        <Wrapper onClick={onEditMode}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                height={height}
                fontFamily={fontFamily}
                fontSize={fontSize}
                color={color}
                align={align}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                weight={weight}
                letterSpacing={letterSpacing}
                lineHeight={lineHeight}
            >
                <figure>
                    <label>{label}</label>
                    <audio controls src={src} autoPlay={autoPlay} muted={muted} loop={loop}>
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                </figure>
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Audio)

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 15px 0;
    cursor: pointer;
`

const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    width: string
    height: string
    fontFamily: string
    color: string
    align: string
    fontSize: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    weight: number
    isPreview: boolean
    lineHeight: number
    letterSpacing: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    figure {
        text-align: ${(props) => props.align};
    }

    audio {
        width: 100%;
        height: ${(props) => props.height};
        margin-top: 8px;
    }

    label {
        width: 100%;
        font-size: ${(props) => props.fontSize};
        font-family: ${(props) => props.fontFamily};
        color: ${(props) => props.color};
        font-weight: ${(props) => +props.weight};
        letter-spacing: ${(props) => props.letterSpacing};
        line-height: ${(props) => props.lineHeight};
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
