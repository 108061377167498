import { nanoid } from 'nanoid'
import { IWidget } from '../Widget'
import { ISegment, Segment } from './Segment'

export interface IScratchCard extends IWidget {
    settings: {
        foregroundImage: string
        foregroundImageName: string
        segmentData: ISegment[]
        completePercent: number
        losingText: string
        winnerText: string
        redirect: string
        attempts: {
            count: number
            period: string
            totalAttempts: number | string
        }
        resetAttempt: {
            enabled: boolean
            time: number
            period: string
            day: number
            date: number | string
        }
        probabilityTotal: number
    }
    appearanceSettings: {
        width: number
        height: number
        strokeWidth: number
        marginTop: number
        marginBottom: number
        marginLeft: number
        marginRight: number
        paddingTop: number
        paddingBottom: number
        paddingLeft: number
        paddingRight: number
        borderTopLeftRadius: number
        borderTopRightRadius: number
        borderBottomLeftRadius: number
        borderBottomRightRadius: number
        borderTopWidth: number
        borderBottomWidth: number
        borderLeftWidth: number
        borderRightWidth: number
    }
    colourSettings: {
        'background colour': string[]
        'border colour': string[]
    }
}

class Scratchcard implements IScratchCard {
    id = nanoid()
    name = 'scratch card'
    type = 'scratchcard'
    category = 'game'
    icon = '/icons/widgets/scratchcard.svg'
    settings = {
        redirect: '',
        foregroundImage: '/icons/widgets/foreground.svg',
        foregroundImageName: 'custom',
        completePercent: 70,
        losingText: 'Sorry but better luck next time',
        winnerText: 'Congratulations you’re a winner!',
        segmentData: [new Segment('BETTER LUCK'), new Segment('Reward')],
        attempts: {
            count: 1,
            period: 'day',
            totalAttempts: 'unlimited',
        },
        resetAttempt: {
            enabled: false,
            time: 0,
            period: 'day',
            day: 1,
            date: 'start',
        },
        probabilityTotal: 0,
    }
    appearanceSettings = {
        width: 100,
        height: 40,
        strokeWidth: 30,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 0,
        marginRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
    }
    colourSettings = {
        'background colour': ['rgba(0,0,0,0)', '#FFA7E1', '#0426FF'],
        'border colour': ['rgba(0,0,0,0)', '#FFA7E1', '#0426FF'],
    }
}

export default Scratchcard
