import React, { memo, MouseEvent } from 'react'
import { useQueryClient } from 'react-query'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { widgetFormField } from '../../../../stores/widgets/forms/state'
import { IBrandingSettings } from '../../../../types/Branding'
import Widget from '../../../../types/Widget'
import { EditingIndicator } from '../../EditingIndicator'
import { useFormContext } from '../FormContainer/FormContainer'
import CustomRating from './CustomRating'
import Sentiment from './Sentiment'

interface Props {
    widget?: Widget
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: Widget) => void
}

const Rating = ({ widget, isPreview = false, updateActiveWidget, index, parentIndex }: Props) => {
    const { formId } = useFormContext()
    const queryClient = useQueryClient()
    const branding = queryClient.getQueryData<IBrandingSettings>('branding')
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    const name = widget?.settings?.name
    const [value, setValue] = useRecoilState(widgetFormField({ formId, fieldName: name }))

    const handleRatingChange = (_event, rating): void => {
        setValue(rating)
    }

    const type = widget?.settings?.defaultOption
    const max = parseInt(widget?.settings?.numberOfRatings)
    const size = widget?.appearanceSettings?.defaultSize
    const label = widget?.settings?.label
    const labelSize = widget?.appearanceSettings?.labelSize

    const width = widget?.appearanceSettings?.width + '%'
    const font = branding?.defaultFont || { family: 'Poppins' }
    const modFontFamily = font?.family?.replace(/\s/g, '+')

    const paddingTop = widget?.appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = widget?.appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = widget?.appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = widget?.appearanceSettings?.paddingRight / 10 + 'em'
    const marginTop = widget?.appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = widget?.appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = widget?.appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = widget?.appearanceSettings?.marginRight / 10 + 'em'
    const alignment = widget?.appearanceSettings?.alignment
    const color = Array.isArray(widget?.colourSettings['icon colour'])
        ? widget?.colourSettings['icon colour']?.[0]
        : widget?.colourSettings['icon colour']

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            fontFamily={font?.family}
        >
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <Container
                onClick={onEditMode}
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
            >
                <label
                    style={{
                        width: '100%',
                        fontSize: labelSize,
                        fontFamily: font?.family,
                        marginBottom: '0.5em',
                        color,
                    }}
                >
                    {label}
                </label>

                <div className="flex justify-center items-center mx-auto" style={{ width: '100%' }}>
                    {type === 'stars' && (
                        <CustomRating
                            type={type}
                            name={name}
                            value={Number(value) || Number(widget?.settings?.defaultValue)}
                            max={max}
                            size={size}
                            color={color}
                            handleChange={handleRatingChange}
                        />
                    )}

                    {type === 'sentiment' && (
                        <Sentiment
                            name={name}
                            color={color}
                            value={Number(value) || Number(widget?.settings?.defaultValue)}
                            handleChange={handleRatingChange}
                        />
                    )}
                </div>
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Rating)

const Wrapper = styled.div.attrs((props) => ({}))<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    fontFamily: string
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    font-family: ${(props) => props.fontFamily};
`
const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    input {
        width: 100%;
        border-radius: inherit;
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
