import React from 'react'
import Image from 'next/image'
import { GoogleLogin } from 'react-google-login'
import { postIdToken } from '../../../lib/auth/google/postIdToken'
import { Toast } from '../../../helpers/Toast'
import { useSetRecoilState } from 'recoil'
import { customerAuth } from '../../../stores/auth/customer/customerAuth'
import { loginModal } from '../../../stores/auth/customer/loginModal'
import { Button } from './Button'

// const clientId = '956474753284-14cj95orcf3aqmovcssvtkvoeb5ol5it.apps.googleusercontent.com'
const clientId = '1029772577902-vc4t42ga2fpl25gn9e6mr2uot2jtvpds.apps.googleusercontent.com'

const GoogleButton = () => {
    const setCustomerAuth = useSetRecoilState(customerAuth)
    const setAuthVisible = useSetRecoilState(loginModal)

    const onSuccess = async (res): Promise<void> => {
        try {
            const user = await postIdToken(res.tokenId)
            setCustomerAuth(user)
            setAuthVisible(false)
        } catch (error) {
            Toast('Error signing in with Google', 'error')
        }
    }

    const onFailure = (res) => {
        Toast(res.error, 'error')
    }

    return (
        <GoogleLogin
            clientId={clientId}
            render={(renderProps) => (
                <Button onClick={renderProps.onClick}>
                    <Image
                        src="/icons/social/google.svg"
                        layout="fixed"
                        height={20}
                        width={20}
                        objectFit="contain"
                        alt="google"
                    />
                    Sign in with Google
                </Button>
            )}
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
        />
    )
}

export default GoogleButton
