import React, { memo } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { useWidgetCategory } from '../../../../../lib/hooks/useWidgetCategory'
import { checkTemplateExists } from '../../../../../lib/widgets/checkTemplateExists'
import { filterFormWidgets } from '../../../../../lib/widgets/filterFormWidgets'
import { useUpdateViewWidgets } from '../../../../../lib/widgets/useUpdateViewWidgets'
import { activeWidgetState } from '../../../../../stores/builder/activeWidget'
import { currentViewState } from '../../../../../stores/builder/currentViewState'
import { viewSectionState } from '../../../../../stores/builder/viewSectionState'
import { campaignViewState } from '../../../../../stores/campaign/edit/campaignState'
import Widget from '../../../../../types/Widget'
import { addAdditionalWidgets } from '../../../../../lib/widgets/addAdditionalWidgets'
import WidgetLoader from './WidgetLoader'
import { nanoid } from 'nanoid'

interface Props {
    currentLayer: null | number
    isPopup: boolean
    parentIndex?: number
    widget?: Widget
    PreviewComponent: any
}

const RenderPreview = ({ currentLayer, isPopup, parentIndex, widget, PreviewComponent }: Props) => {
    const views = useRecoilValue(campaignViewState)
    const setActiveWidget = useSetRecoilState(activeWidgetState)
    const [sectionState, setSectionState] = useRecoilState(viewSectionState)
    const changeWidgetCategory = useWidgetCategory()
    const currentViewIndex = useRecoilValue(currentViewState)
    let updateViewWidgets = useUpdateViewWidgets(currentViewIndex, currentLayer)
    let addItem = { type: '', insertIndex: 0, id: '' }

    let widgets = []
    if (currentLayer === null && !widget) {
        widgets = views[currentViewIndex].widgets
    }

    if (currentLayer !== null && !widget) {
        const layer: any = views[currentViewIndex].layers[currentLayer]
        widgets = layer.widgets || []
    }

    if (widget) {
        widgets = widget.widgets
    }

    const deleteWidget = (index: number): void => {
        if (sectionState === 'widgetEditSettings') {
            setSectionState('widgetPicker')
        }

        if (!widget) {
            updateViewWidgets.delete(index)
        } else {
            updateViewWidgets.deleteContainerWidget(parentIndex, index, isPopup)
        }
    }

    const updateActiveWidget = (index: number, parentIndex: number, widget = null): void => {
        setActiveWidget({
            index,
            currentViewIndex,
            currentLayer,
            parentIndex,
            widget,
        })
        setSectionState('widgetEditSettings')
    }

    const setState = (currentList: any[], _: any, { dragging }: any) => {
        if (
            dragging &&
            dragging.props &&
            JSON.stringify(dragging.props.list) !== JSON.stringify(currentList) &&
            _
        ) {
            // find added item by id and change it to avoid duplication
            const updatedList = currentList.map((widget) => {
                if (widget.id === addItem.id) {
                    return {
                        ...widget,
                        id: nanoid(),
                    }
                } else {
                    return widget
                }
            })

            const filteredWidgets = filterFormWidgets(updatedList, widget && widget.type)
            if (filteredWidgets.length !== updatedList.length) {
                return filteredWidgets
            }

            const templateExists = checkTemplateExists(updatedList)
            if (templateExists.length > 0) {
                return updateViewWidgets.updateView(templateExists[0].views)
            }

            let newList = addAdditionalWidgets(updatedList, addItem)

            if (!widget) {
                updateViewWidgets.update(newList)
            }

            if (widget) {
                updateViewWidgets.updateContainer(newList, parentIndex)
            }
        } else {
            addItem = {
                type: '',
                insertIndex: 0,
                id: '',
            }
        }
    }

    return (
        <ReactSortable
            tag={PreviewComponent}
            fallbackOnBody={true}
            swapThreshold={1}
            group={{ name: 'widgets' }}
            animation={50}
            swap={true}
            emptyInsertThreshold={10}
            fallbackTolerance={10}
            ghostClass="ghost-drag"
            chosenClass="ghost-drag"
            dragClass="ghost-drag"
            removeCloneOnHide={true}
            onRemove={changeWidgetCategory.set}
            onAdd={(evt) => {
                addItem = {
                    type: evt.item.getAttribute('data-type'),
                    id: evt.item.getAttribute('data-id'),
                    insertIndex: evt.newIndex,
                }
            }}
            list={widgets}
            setList={setState}
        >
            {widgets.length > 0 &&
                widgets.map((widget, index) => {
                    let widgetKey = ''
                    if (currentLayer === null) {
                        widgetKey = `${widget.id}-${index}-${currentViewIndex}`
                    } else {
                        widgetKey = `${widget.id}-${index}-${currentViewIndex}-${currentLayer}`
                    }
                    if (!widget.type) {
                        return <div key={widgetKey}></div>
                    }

                    return (
                        <WidgetLoader
                            key={widgetKey}
                            widget={widget}
                            index={index}
                            parentIndex={parentIndex}
                            updateActiveWidget={updateActiveWidget}
                            deleteWidget={deleteWidget}
                            currentLayer={currentLayer}
                        />
                    )
                })}
        </ReactSortable>
    )
}

export default RenderPreview
