import { motion } from 'framer-motion'
import React, { useRef, useState, useEffect, useCallback, memo } from 'react'
import { SetterOrUpdater } from 'recoil'
import { ISegment } from '../../../../models/Games/Segment'
import { CustomerAuth } from '../../../../stores/auth/customer/customerAuth'
import Modal from '../Modal'
import ModalContent from '../ModalContent'

declare global {
    interface Window {
        requestAnimFrame: (value: any) => void
        webkitRequestAnimationFrame: any
        mozRequestAnimationFrame: any
        oRequestAnimationFrame: any
        msRequestAnimaitonFrame: any
    }
}

const ScratchMe = ({
    width,
    height,
    backgroundImageSrc,
    foregroundImageSrc,
    strokeWidth,
    onProgress,
    onCompleted,
    reward,
    userData,
    widget,
    setReward,
    completedAt,
    borderTopLeftRadius,
    borderBottomLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    userId,
}: IScratchMe) => {
    const backgroundCanvasRef = useRef(null)
    const foregroundCanvasRef = useRef(null)

    const [isCompleted, setIsCompleted] = useState(completedAt === 0)
    const [isOpen, setIsOpen] = useState(false)

    const handleToggleModal = useCallback(() => {
        setIsOpen((prev) => !prev)
    }, [])

    useEffect(() => {
        if (width !== 0) {
            scratchMeInit()
        }
    }, [width, height])

    useEffect(() => {
        if (!backgroundImageSrc) {
            return
        }
        const backgroundCanvas = backgroundCanvasRef.current
        const backgroundContext = backgroundCanvas.getContext('2d')
        const backgroundImage = new Image()
        backgroundImage.crossOrigin = 'Anonymous'
        backgroundImage.onload = function () {
            backgroundContext.drawImage(this, 0, 0, width, height)
        }
        backgroundImage.src = backgroundImageSrc
        redrawCanvas()
    }, [backgroundImageSrc])

    useEffect(() => {
        if (foregroundImageSrc !== '/icons/widgets/foreground.svg' && reward) {
            redrawCanvas()
        }
    }, [foregroundImageSrc])

    const redrawCanvas = () => {
        const foregroundCanvas = foregroundCanvasRef.current
        foregroundCanvas.width = foregroundCanvas.width // some clear canvas hack
        const foregroundContext = foregroundCanvas.getContext('2d')
        const foregroundImage = new Image()
        foregroundImage.crossOrigin = 'Anonymous'
        foregroundImage.onload = function () {
            foregroundContext.drawImage(this, 0, 0, width, height)
            foregroundContext.globalCompositeOperation = 'destination-out'
            foregroundContext.lineWidth = strokeWidth
        }
        foregroundImage.src = foregroundImageSrc
    }

    const scratchMeInit = () => {
        // Set up both canvases and get the 2d contexts
        const foregroundCanvas = foregroundCanvasRef.current
        const foregroundContext = foregroundCanvas.getContext('2d')
        const backgroundCanvas = backgroundCanvasRef.current
        const backgroundContext = backgroundCanvas.getContext('2d')

        // Load foreground image and set the cursor to erase mode
        const foregroundImage = new Image()
        foregroundImage.crossOrigin = 'Anonymous'
        foregroundImage.onload = function () {
            foregroundContext.drawImage(this, 0, 0, width, height)
            foregroundContext.globalCompositeOperation = 'destination-out'
            foregroundContext.lineWidth = strokeWidth
        }
        foregroundImage.src = foregroundImageSrc
        // Set up mouse events for drawing
        var drawing = false
        var mousePos = {
            x: 0,
            y: 0,
        }
        var lastPos = mousePos

        // Mouse events on the canvas

        foregroundCanvas.addEventListener(
            'mousedown',
            (e) => {
                drawing = true
                lastPos = getMousePos(foregroundCanvas, e)
            },
            false
        )

        foregroundCanvas.addEventListener(
            'mouseup',
            () => {
                drawing = false
            },
            false
        )

        foregroundCanvas.addEventListener(
            'mousemove',
            (e) => {
                mousePos = getMousePos(foregroundCanvas, e)
            },
            false
        )

        // Get the position of the mouse relative to the canvas

        const getMousePos = (canvasDom, mouseEvent) => {
            const rect = canvasDom.getBoundingClientRect()
            return {
                x: mouseEvent.clientX - rect.left,
                y: mouseEvent.clientY - rect.top,
            }
        }

        // Get a regular interval for drawing to the screen

        window.requestAnimFrame = (function (callback) {
            return (
                window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimaitonFrame ||
                function (callback) {
                    window.setTimeout(callback, 1000 / 60)
                }
            )
        })()

        const getFilledInPixels = (stride) => {
            if (!stride || stride < 1) stride = 1
            const pixels = foregroundContext.getImageData(0, 0, width, height)
            const total = pixels.data.length / stride
            let count = 0
            for (let i = 0; i < pixels.data.length; i += stride) {
                if (parseInt(pixels.data[i], 10) === 0) count++
            }
            return Math.round((count / total) * 100)
        }

        // Draw to the canvas

        const renderCanvas = () => {
            if (drawing) {
                foregroundContext.moveTo(lastPos.x, lastPos.y)
                foregroundContext.lineTo(mousePos.x, mousePos.y)
                foregroundContext.stroke()
                lastPos = mousePos
                const percent = getFilledInPixels(32)
                onProgress(percent)
                if (percent >= completedAt && !isCompleted) {
                    setIsCompleted(true)
                    setIsOpen(true)
                    drawing = false
                }
            }
        }

        // Allow for animation

        ;(function drawLoop() {
            window.requestAnimFrame(drawLoop)
            renderCanvas()
        })()

        foregroundCanvas.addEventListener(
            'touchstart',
            (e) => {
                e.preventDefault()
                mousePos = getTouchPos(foregroundCanvas, e)
                const touch = e.touches[0]
                const mouseEvent = new MouseEvent('mousedown', {
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                })
                foregroundCanvas.dispatchEvent(mouseEvent)
            },
            false
        )

        foregroundCanvas.addEventListener(
            'touchend',
            (e) => {
                e.preventDefault()
                const mouseEvent = new MouseEvent('mouseup', {})
                foregroundCanvas.dispatchEvent(mouseEvent)
            },
            false
        )

        foregroundCanvas.addEventListener(
            'touchmove',
            (e) => {
                e.preventDefault()
                const touch = e.touches[0]
                const mouseEvent = new MouseEvent('mousemove', {
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                })
                foregroundCanvas.dispatchEvent(mouseEvent)
            },
            false
        )

        foregroundCanvas.addEventListener('touchcancel', (e) => {
            e.preventDefault()
        })

        // Get the position of a touch relative to the canvas

        const getTouchPos = (canvasDom, touchEvent) => {
            const rect = canvasDom.getBoundingClientRect()
            return {
                x: touchEvent.touches[0].clientX - rect.left,
                y: touchEvent.touches[0].clientY - rect.top,
            }
        }
    }

    const styles = {
        container: {
            position: 'relative',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'block',
            borderTopLeftRadius,
            borderBottomLeftRadius,
            borderTopRightRadius,
            borderBottomRightRadius,
        },
        foreground: {
            position: 'absolute',
            top: 0,
            left: 0,
            borderTopLeftRadius,
            borderBottomLeftRadius,
            borderTopRightRadius,
            borderBottomRightRadius,
        },
    } as const

    return (
        <>
            <motion.div
                style={{ width, height, ...styles.container }}
                animate={
                    userId
                        ? {
                              scale: [1, 1.1, 1.1, 1, 1],
                              rotate: [0, 0, 180, 180, 0],
                              borderRadius: ['0%', '0%', '50%', '50%', '0%'],
                          }
                        : {}
                }
                transition={
                    userId
                        ? {
                              duration: 2,
                              ease: 'easeInOut',
                              times: [0, 0.2, 0.5, 0.8, 1],
                          }
                        : {}
                }
            >
                <canvas
                    style={{ ...styles.background }}
                    ref={backgroundCanvasRef}
                    width={`${width}px`}
                    height={`${height}px`}
                />
                <canvas
                    className="foreground"
                    style={{ ...styles.foreground }}
                    ref={foregroundCanvasRef}
                    width={`${width}px`}
                    height={`${height}px`}
                />
            </motion.div>
            {isCompleted && (
                <Modal isOpen={isOpen}>
                    <ModalContent
                        reward={reward}
                        widget={widget}
                        setReward={setReward}
                        userData={userData}
                        handleToggleModal={handleToggleModal}
                        onCompleted={onCompleted}
                    />
                </Modal>
            )}
        </>
    )
}

interface IScratchMe {
    width: number
    height: number
    backgroundImageSrc: string
    foregroundImageSrc: string
    strokeWidth: number
    onProgress: (value: number) => void
    onCompleted: (rew: ISegment) => void
    completedAt: number
    reward: ISegment
    userData: CustomerAuth
    widget: any
    setReward: SetterOrUpdater<any>
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    userId: any
}

ScratchMe.defaultProps = {
    strokeWidth: 20,
    onProgress: () => {},
    onCompleted: () => {},
    completedAt: 50,
}

export default memo(ScratchMe)
