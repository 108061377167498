import React, { useRef, useEffect, useState, MouseEvent, memo } from 'react'
import Vimeo from '@u-wave/react-vimeo'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { IWidget } from '../../../../models/Widget'
import { useAnalytics } from '../../../../lib/analytics/useAnalytics'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget?: IWidget
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
}

const Video = ({ widget, isPreview = false, updateActiveWidget, index, parentIndex }: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    const iframeRef = useRef<HTMLIFrameElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)
    if (!widget?.appearanceSettings) {
        widget = {
            ...widget,
            appearanceSettings: {
                ...widget?.settings,
            },
        }
    }

    const analytics = useAnalytics('live')
    const [height, setHeight] = useState<number>(0)

    const videoContainer = useRef(null)
    const width = widget?.appearanceSettings?.width + '%'
    const paddingTop = widget?.appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = widget?.appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = widget?.appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = widget?.appearanceSettings?.paddingRight / 10 + 'em'
    const borderTopLeftRadius = widget?.appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderBottomLeftRadius = widget?.appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget?.appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomRightRadius = widget?.appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const src = widget?.settings.src
    const platform = widget?.settings.platform
    const thumbnail = widget?.settings.thumbnail

    const trackVideo = () => {
        analytics.track('play video', {
            url: widget.settings.src,
            platform: widget.settings.platform,
        })
    }

    const trackIframe = () => {
        if (document.activeElement === document.querySelector('iframe')) {
            trackVideo()
        }
    }

    useEffect(() => {
        if (videoContainer.current) {
            const videoContainerWidth = videoContainer.current.clientWidth
            setHeight((2 * videoContainerWidth) / 3)
        }
    }, [])

    useEffect(() => {
        if (iframeRef.current) {
            window.addEventListener('blur', trackIframe)
            return () => window.removeEventListener('blur', trackIframe)
        }
    }, [iframeRef])

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('play', trackVideo)
        }
    }, [videoRef])

    const styles = {
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        borderTopLeftRadius,
        borderBottomLeftRadius,
        borderTopRightRadius,
        borderBottomRightRadius,
    }

    return (
        <Wrapper ref={videoContainer} onClick={onEditMode}>
            {platform === 'youtube' && (
                <div
                    className={`w-full flex justify-center ${isPreview && 'pointer-events-none'}`}
                    style={styles}
                >
                    <Container
                        isActive={isActive}
                        isPreview={isPreview}
                        width={width}
                        paddingTop={paddingTop}
                        paddingBottom={paddingBottom}
                        paddingLeft={paddingLeft}
                        paddingRight={paddingRight}
                        borderTopLeftRadius={borderTopLeftRadius}
                        borderBottomLeftRadius={borderBottomLeftRadius}
                        borderTopRightRadius={borderTopRightRadius}
                        borderBottomRightRadius={borderBottomRightRadius}
                    >
                        <iframe
                            ref={iframeRef}
                            style={{ width: '100%' }}
                            height="100%"
                            src={src}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        {isPreview && typeof thumbnail === 'string' && thumbnail !== '' && (
                            <img
                                className="absolute inset-0 w-full h-full mx-auto "
                                src={thumbnail}
                                style={{ width, ...styles }}
                            />
                        )}
                        {isPreview && <EditingIndicator widgetName={widgetName} />}
                    </Container>
                </div>
            )}

            {platform === 'vimeo' && (
                <div
                    className={`w-full flex justify-center overflow-hidden  ${
                        isPreview && 'pointer-events-none'
                    } `}
                    style={styles}
                >
                    <Container
                        isActive={isActive}
                        isPreview={isPreview}
                        width={width}
                        paddingTop={paddingTop}
                        paddingBottom={paddingBottom}
                        paddingLeft={paddingLeft}
                        paddingRight={paddingRight}
                        borderTopLeftRadius={borderTopLeftRadius}
                        borderBottomLeftRadius={borderBottomLeftRadius}
                        borderTopRightRadius={borderTopRightRadius}
                        borderBottomRightRadius={borderBottomRightRadius}
                    >
                        <Vimeo
                            video={src}
                            style={{ width: '100%' }}
                            height="100%"
                            responsive={true}
                            autoplay
                            loop
                            muted
                            controls={true}
                        />
                        {isPreview && typeof thumbnail === 'string' && thumbnail !== '' && (
                            <img
                                className="absolute inset-0 w-full h-full mx-auto "
                                src={thumbnail}
                                style={{ width, ...styles }}
                            />
                        )}
                        {isPreview && <EditingIndicator widgetName={widgetName} />}
                    </Container>
                </div>
            )}

            {platform === '' && (
                <div
                    className={`w-full flex justify-center  ${isPreview && 'pointer-events-none'}`}
                    style={styles}
                >
                    <Container
                        isActive={isActive}
                        isPreview={isPreview}
                        width={width}
                        paddingTop={paddingTop}
                        paddingBottom={paddingBottom}
                        paddingLeft={paddingLeft}
                        paddingRight={paddingRight}
                        borderTopLeftRadius={borderTopLeftRadius}
                        borderBottomLeftRadius={borderBottomLeftRadius}
                        borderTopRightRadius={borderTopRightRadius}
                        borderBottomRightRadius={borderBottomRightRadius}
                    >
                        <video
                            ref={videoRef}
                            className="m-auto"
                            src={src}
                            controls
                            autoPlay={false}
                            muted
                            loop
                            width="100%"
                            height=""
                            playsInline
                            style={{
                                width: '100%',
                                borderTopLeftRadius,
                                borderBottomLeftRadius,
                                borderTopRightRadius,
                                borderBottomRightRadius,
                            }}
                        ></video>
                        {isPreview && typeof thumbnail === 'string' && thumbnail !== '' && (
                            <img
                                className="absolute inset-0 w-full h-full mx-auto "
                                src={thumbnail}
                                style={{ width, ...styles }}
                            />
                        )}
                        {isPreview && <EditingIndicator widgetName={widgetName} />}
                    </Container>
                </div>
            )}
        </Wrapper>
    )
}

export default memo(Video)

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
`

const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
}>`
    position: relative;
    height: 300px;
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
