import Widget from '../../types/Widget'
import Button from '../Button'
import ButtonSettings, { IButtonSettings } from '../ButtonSettings'
import FormEmail, { IFormEmail } from './FormEmail'
import FormSMS, { IFormSMS } from './FormSMS'

export interface ISubmitButton extends Widget {
    settings: SubmitButtonSettings
}

class SubmitButton extends Button implements ISubmitButton {
    type = 'submit'
    name = 'submit button'
    icon = '/icons/widgets/submit button.svg'
    appearanceSettings = {
        width: 90,
        height: 100,
        columns: 1,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 12,
        paddingRight: 12,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 0,
        marginRight: 0,
        align: 'center',
        font: {
            family: 'Poppins',
            size: 14,
            weight: '300',
        },
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        buttonSpacing: 5,
    }
    colourSettings = {
        'button colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'text colour': ['#ffffff', '#FFA7E1', '#0426FF'],
        'border colour': ['#ffffff', '#FFA7E1', '#0426FF'],
    }
    settings = {
        links: [new ButtonSettings('Submit Form', 'https://deploy.link', 'go to page', 0, 0)],
        actions: {
            showToast: true,
            isClaimPrize: false,
            isSendEmail: false,
            isSendSMS: false,
        },
        toastMessage: 'Form submitted successfully!',
        email: new FormEmail(),
        sms: new FormSMS(),
    }
}

export interface SubmitButtonSettings {
    links: IButtonSettings[]
    actions: {
        showToast: boolean
        isClaimPrize: boolean
        isSendEmail: boolean
        isSendSMS: boolean
    }
    toastMessage: string
    gameId?: string
    email: IFormEmail
    sms: IFormSMS
}

export default SubmitButton
