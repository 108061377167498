import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { IAnswer } from '../../../../models/General/Polls'
import { PollsTheme } from './Polls'

interface Props {
    question?: string
    answers: IAnswer[]
    theme?: PollsTheme
    isVoted?: boolean
    onVote: (answer: string) => void
}

export const DoublePoll: FC<Props> = ({ question, answers, theme, onVote, isVoted }) => {
    const [voted, setVoted] = useState<boolean>(false)
    const answersContainer = useRef<HTMLDivElement>(null)
    const answer0 = useRef<HTMLDivElement>(null)
    const answer1 = useRef<HTMLDivElement>(null)
    const allRefs: RefObject<HTMLDivElement>[] = [answer0, answer1, answersContainer]

    const calculatePercent = (votes: any, answers: any[]) => {
        const total = answers[0].votes + answers[1].votes
        const percent = Math.round((votes / total) * 100)

        if (Number.isInteger(percent)) {
            return percent
        } else {
            return 0
        }
    }

    const animateAnswers = useCallback(
        (index: number, answers: IAnswer[], refs: RefObject<HTMLDivElement>[]): void => {
            const answer: HTMLElement | null = refs[index].current
            const oppositeIndex: number = index === 0 ? 1 : 0
            const anotherAnswer: HTMLElement | null = refs[oppositeIndex].current

            const percentage: number | undefined = calculatePercent(answers[index].votes, answers)

            if (answer && anotherAnswer && percentage) {
                answer.animate(
                    [
                        { width: '50%', easing: 'ease-out' },
                        { width: `${percentage}%`, easing: 'ease-out' },
                    ],
                    500
                )
                anotherAnswer.animate(
                    [
                        { width: '50%', easing: 'ease-out' },
                        { width: `${100 - percentage}%`, easing: 'ease-out' },
                    ],
                    500
                )
                answer.style.width = `${percentage}%`
                answer.style.height = anotherAnswer.clientHeight + 'px'
                anotherAnswer.style.width = `${100 - percentage}%`

                answer.animate([{ backgroundColor: 'white' }, { backgroundColor: '#efefef' }], 200)
                answer.style.backgroundColor = '#66ec9e'

                const height: number = answer.offsetHeight

                answer.style.padding = '0'
                anotherAnswer.style.padding = '0'

                const inner: HTMLElement | null = refs[0].current
                if (inner) inner.style.height = `${height}px`
            }
        },
        []
    )

    useEffect(() => {
        if (isVoted) {
            animateAnswers(0, answers, allRefs)
            setVoted(true)
        }
    }, [animateAnswers, answers, isVoted])

    const handleClickAnswer = (index: number) => {
        if (!voted) {
            setVoted(true)
            animateAnswers(index, answers, allRefs)
            onVote(answers[index].option)
        }
    }

    return (
        <Container align={theme?.alignment}>
            {question && <h1 style={{ color: theme?.textColor }}>{question}</h1>}

            <AnswersContainer ref={answersContainer} background={theme?.backgroundColor}>
                {answers.map((answer: IAnswer, index: number) => {
                    if (index > 1) {
                        return null
                    } else {
                        return (
                            <Answer
                                ref={index === 0 ? answer0 : answer1}
                                role="button"
                                key={answer.id}
                                id="binAnswer0"
                                onClick={() => handleClickAnswer(index)}
                            >
                                <div className="answerContainer">
                                    <p>{answer.option}</p>
                                    {voted && (
                                        <span style={{ color: theme?.textColor }}>
                                            {calculatePercent(answer.votes, answers) + '%'}
                                        </span>
                                    )}
                                </div>
                            </Answer>
                        )
                    }
                })}
            </AnswersContainer>
        </Container>
    )
}

const Container = styled.article<{
    align: string
}>`
    color: #19181f;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border-bottom: 1px solid #d6d6d6;
        text-align: ${(props) => props.align};
    }
`

const AnswersContainer = styled.div<{
    background: string
}>`
    width: 95%;
    height: 80px;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    background-color: ${(props) => props.background};
`
const Answer = styled.div`
    height: 100%;
    width: 50%;
    padding: 5% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    transition: background-color 0.3s ease-out;

    &:hover {
        background-color: #efefef;
        cursor: pointer;
    }

    .answerContainer {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    &:first-child {
        border-right: 0.01rem solid #efefef;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }
    &:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
    .answerContainer p {
        font-weight: bold;
        font-size: 1.5rem;
        z-index: 100;
    }
    .answerContainer span {
        font-size: 2.25rem;
        z-index: 100;
    }
`
