import React from 'react'
import styled from 'styled-components'
import { CustomModal } from '../../Common/Modal'
import AppleButton from './AppleButton'
import FacebookButton from './FacebookButton'
import GoogleButton from './GoogleButton'

interface Props {
    visible: boolean
    toggleVisible: (value: boolean) => void
}

const AuthContainer = styled.div`
    width: 90%;
    max-width: 414px;
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    font-family: Matter;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 1rem;
    }
`

const SocialAuthSignin = ({ visible, toggleVisible }: Props) => {
    return (
        <CustomModal title="social sign in" visible={visible} toggleVisible={toggleVisible}>
            <AuthContainer>
                <h1>Login to access this content</h1>
                <GoogleButton />
                {/* <FacebookButton /> */}
                {/* <AppleButton /> */}
            </AuthContainer>
        </CustomModal>
    )
}

export default SocialAuthSignin
