import { useState, useEffect } from 'react'
import { GameState } from '../components/Widgets/Game/Puzzle/GameHelpers/state'

const usePuzzleGameHook = () => {
    const gameState = GameState.getInstance()

    const [state, setState] = useState(gameState.getState())

    const newGame = () => {
        gameState.startNewGame()
        setState(gameState.getState())
    }

    const move = (i: number) => {
        return function () {
            gameState.moveTile(i)
            setState(gameState.getState())
        }
    }

    const isTileRight = (i: number) => gameState.checkTileRight(i)

    useEffect(() => {
        function listeners(e: { key: string }) {
            if (e.key === 'ArrowLeft') gameState.moveInDirection('left')
            else if (e.key === 'ArrowUp') gameState.moveInDirection('up')
            else if (e.key === 'ArrowRight') gameState.moveInDirection('right')
            else if (e.key === 'ArrowDown') gameState.moveInDirection('down')

            setState(gameState.getState())
        }
        document.addEventListener('keyup', listeners)

        return () => document.removeEventListener('keyup', listeners)
    }, [gameState])

    return [state.board, state.moves, state.solved, isTileRight, newGame, move]
}

export { usePuzzleGameHook }
