import { nanoid } from 'nanoid'
import { useSetRecoilState } from 'recoil'
import { IWidget } from '../../models/Widget'
import { campaignViewState } from '../../stores/campaign/edit/campaignState'

export const useUpdateViewWidgets = (currentViewIndex: number, currentLayer: number): any => {
    const setViews = useSetRecoilState(campaignViewState)

    if (currentLayer == null) {
        return {
            addWidgets: (widgets, insertIndex: number): void => {
                setViews((prevState) => {
                    const viewWidgets = prevState[currentViewIndex].widgets
                    const updatedWidgets = [
                        ...viewWidgets.slice(0, insertIndex),
                        ...widgets,
                        ...viewWidgets.slice(insertIndex + 1),
                    ]
                    const newView = { ...prevState[currentViewIndex], widgets: updatedWidgets }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            duplicateWidgets: (indexToDuplicate: number, widget: IWidget) => {
                // deals with nested widgets - creates new unqiue widgets with new IDs
                let newWidgets = []
                let newWidget: IWidget = null
                if (widget.widgets?.length > 0) {
                    widget.widgets.forEach((widget) => {
                        newWidgets.push({ ...widget, id: nanoid(), name: `${widget.name} copy` })
                    })
                    newWidget = { ...widget, id: nanoid(), widgets: newWidgets }
                }

                // normally update the views with the new duplicated widget
                setViews((prevState) => {
                    const viewWidgets = prevState[currentViewIndex].widgets
                    let updatedWidgets: any
                    if (indexToDuplicate === 0) {
                        updatedWidgets = [
                            ...viewWidgets.slice(indexToDuplicate, 1),
                            {
                                ...(newWidget ? newWidget : widget),
                                id: nanoid(),
                                name: `${widget.name} copy`,
                            }, // add the new widget generated for nested widgets key
                            ...viewWidgets.slice(indexToDuplicate + 1),
                        ]
                    } else {
                        updatedWidgets = [
                            ...viewWidgets.slice(0, indexToDuplicate + 1),
                            {
                                ...(newWidget ? newWidget : widget),
                                id: nanoid(),
                                name: `${widget.name} copy`,
                            }, // add the new widget generated for nested widgets key
                            ...viewWidgets.slice(indexToDuplicate + 1),
                        ]
                    }
                    const newView = { ...prevState[currentViewIndex], widgets: updatedWidgets }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            addContainerWidget: (widgets: IWidget[], index: number): void => {
                setViews((prevState) => {
                    const container = {
                        ...prevState[currentViewIndex].widgets[index],
                        widgets: [
                            ...prevState[currentViewIndex].widgets[index].widgets,
                            ...widgets,
                        ],
                    }
                    const updatedWidgets = [
                        ...prevState[currentViewIndex].widgets.slice(0, index),
                        container,
                        ...prevState[currentViewIndex].widgets.slice(index + 1),
                    ]
                    const newView = { ...prevState[currentViewIndex], widgets: updatedWidgets }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            duplicateContainerWidgets: (
                index: number,
                indexToDuplicate: number,
                widget: IWidget
            ) => {
                setViews((prevState) => {
                    let container: any

                    if (indexToDuplicate === 0) {
                        container = {
                            ...prevState[currentViewIndex].widgets[index],
                            widgets: [
                                ...prevState[currentViewIndex].widgets[index].widgets.slice(
                                    indexToDuplicate,
                                    1
                                ),
                                {
                                    ...widget,
                                    id: nanoid(),
                                    name: `${widget.name} copy`,
                                },
                                ...prevState[currentViewIndex].widgets[index].widgets.slice(
                                    indexToDuplicate + 1
                                ),
                            ],
                        }
                    } else {
                        container = {
                            ...prevState[currentViewIndex].widgets[index],
                            widgets: [
                                ...prevState[currentViewIndex].widgets[index].widgets.slice(
                                    0,
                                    indexToDuplicate + 1
                                ),
                                {
                                    ...widget,
                                    id: nanoid(),
                                    name: `${widget.name} copy`,
                                },
                                ...prevState[currentViewIndex].widgets[index].widgets.slice(
                                    indexToDuplicate + 1
                                ),
                            ],
                        }
                    }

                    const updatedWidgets = [
                        ...prevState[currentViewIndex].widgets.slice(0, index),
                        container,
                        ...prevState[currentViewIndex].widgets.slice(index + 1),
                    ]
                    const newView = { ...prevState[currentViewIndex], widgets: updatedWidgets }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            update: (widgets: any[]): void => {
                setViews((prevState) => {
                    const newView = { ...prevState[currentViewIndex], widgets }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            updateContainer: (widgets: any[], index: number) => {
                setTimeout(() => {
                    setViews((prevState) => {
                        const container = { ...prevState[currentViewIndex].widgets[index], widgets }
                        const updatedWidgets = [
                            ...prevState[currentViewIndex].widgets.slice(0, index),
                            container,
                            ...prevState[currentViewIndex].widgets.slice(index + 1),
                        ]
                        const newView = { ...prevState[currentViewIndex], widgets: updatedWidgets }
                        const updatedViews = [
                            ...prevState.slice(0, currentViewIndex),
                            newView,
                            ...prevState.slice(currentViewIndex + 1),
                        ]

                        return updatedViews
                    })
                }, 0)
            },
            delete: async (index: number) => {
                setViews((prevState) => {
                    const widgets = prevState[currentViewIndex].widgets
                    const new_widgets = widgets.filter((widget, i) => {
                        return i !== index
                    })
                    const newView = { ...prevState[currentViewIndex], widgets: new_widgets }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            deleteContainerWidget: async (index: number, subIndex: number, isPopup: boolean) => {
                setViews((prevState) => {
                    const container = prevState[currentViewIndex].widgets[index]
                    const containerWidgets = container.widgets.filter(
                        (widget, ind) => ind !== subIndex
                    )
                    const updatedContainer = { ...container, widgets: containerWidgets }
                    const viewWidgets = [
                        ...prevState[currentViewIndex].widgets.slice(0, index),
                        updatedContainer,
                        ...prevState[currentViewIndex].widgets.slice(index + 1),
                    ]
                    const view = { ...prevState[currentViewIndex], widgets: viewWidgets }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        view,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            updateView: (views) => {
                setViews(views)
            },
        }
    } else {
        return {
            addWidgets: (widgets: IWidget[]): void => {
                setViews((prevState) => {
                    const updated_layer = {
                        ...prevState[currentViewIndex].layers[currentLayer],
                        widgets: [
                            ...prevState[currentViewIndex].layers[currentLayer].widgets,
                            ...widgets,
                        ],
                    }
                    const newView = {
                        ...prevState[currentViewIndex],
                        layers: [
                            ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                            updated_layer,
                            ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                        ],
                    }
                    const updatedView = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedView
                })
            },
            duplicateWidgets: (indexToDuplicate: number, widget: IWidget) => {
                setViews((prevState) => {
                    const updated_layer = {
                        ...prevState[currentViewIndex].layers[currentLayer],
                        widgets: [
                            ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                                0,
                                indexToDuplicate
                            ),
                            { ...widget, id: nanoid() },
                            ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                                indexToDuplicate
                            ),
                        ],
                    }
                    const newView = {
                        ...prevState[currentViewIndex],
                        layers: [
                            ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                            updated_layer,
                            ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                        ],
                    }
                    const updatedView = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedView
                })
            },
            addContainerWidget: (widgets: IWidget[], index: number): void => {
                setViews((prevState) => {
                    const container = {
                        ...prevState[currentViewIndex].layers[currentLayer].widgets[index],
                        widgets: [
                            ...prevState[currentViewIndex].layers[currentLayer].widgets[index]
                                .widgets,
                            ...widgets,
                        ],
                    }
                    const updatedWidgets = [
                        ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(0, index),
                        container,
                        ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                            index + 1
                        ),
                    ]
                    const updatedLayer = {
                        ...prevState[currentViewIndex].layers[currentLayer],
                        widgets: updatedWidgets,
                    }
                    const updatedLayers = [
                        ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                        updatedLayer,
                        ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                    ]
                    const updatedView = { ...prevState[currentViewIndex], layers: updatedLayers }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        updatedView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            duplicateContainerWidgets: (
                index: number,
                indexToDuplicate: number,
                widget: IWidget
            ) => {
                setViews((prevState) => {
                    const container = {
                        ...prevState[currentViewIndex].layers[currentLayer].widgets[index],
                        widgets: [
                            ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                                0,
                                indexToDuplicate
                            ),

                            { ...widget, id: nanoid() },
                            ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                                indexToDuplicate
                            ),
                        ],
                    }
                    const updatedWidgets = [
                        ...prevState[currentViewIndex].widgets.slice(0, index),
                        container,
                        ...prevState[currentViewIndex].widgets.slice(index + 1),
                    ]
                    const newView = { ...prevState[currentViewIndex], widgets: updatedWidgets }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            delete: (index: number): void => {
                setViews((prevState) => {
                    const layer = prevState[currentViewIndex].layers[currentLayer]
                    const filteredWidgets = layer.widgets.filter((widget, ind) => {
                        return ind !== index
                    })
                    const newLayer = { ...layer, widgets: filteredWidgets }
                    const newLayers = [
                        ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                        newLayer,
                        ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                    ]
                    const newView = { ...prevState[currentViewIndex], layers: newLayers }
                    const newViews = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return newViews
                })
            },
            update: (widgets): void => {
                setViews((prevState) => {
                    const updated_layer = {
                        ...prevState[currentViewIndex].layers[currentLayer],
                        widgets,
                    }
                    const newView = {
                        ...prevState[currentViewIndex],
                        layers: [
                            ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                            updated_layer,
                            ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                        ],
                    }
                    const updatedView = [
                        ...prevState.slice(0, currentViewIndex),
                        newView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedView
                })
            },
            updateContainer: (widgets: any[], index: number) => {
                setViews((prevState) => {
                    const container = {
                        ...prevState[currentViewIndex].layers[currentLayer].widgets[index],
                        widgets,
                    }
                    const updatedWidgets = [
                        ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(0, index),
                        container,
                        ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                            index + 1
                        ),
                    ]
                    const updatedLayer = {
                        ...prevState[currentViewIndex].layers[currentLayer],
                        widgets: updatedWidgets,
                    }
                    const updatedLayers = [
                        ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                        updatedLayer,
                        ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                    ]
                    const updatedView = { ...prevState[currentViewIndex], layers: updatedLayers }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        updatedView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            deleteContainerWidget: (index: number, subIndex: number, isPopup: boolean): void => {
                setViews((prevState) => {
                    const container =
                        prevState[currentViewIndex].layers[currentLayer].widgets[index]
                    const containerWidgets = container.widgets.filter(
                        (widget, ind) => ind !== subIndex
                    )
                    const updatedContainer = { ...container, widgets: containerWidgets }
                    const updatedLayerWidgets = [
                        ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(0, index),
                        updatedContainer,
                        ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                            index + 1
                        ),
                    ]
                    const updatedLayer = {
                        ...prevState[currentViewIndex].layers[currentLayer],
                        widgets: updatedLayerWidgets,
                    }
                    const updatedLayers = [
                        ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                        updatedLayer,
                        ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                    ]
                    const updatedView = { ...prevState[currentViewIndex], layers: updatedLayers }
                    const updatedViews = [
                        ...prevState.slice(0, currentViewIndex),
                        updatedView,
                        ...prevState.slice(currentViewIndex + 1),
                    ]
                    return updatedViews
                })
            },
            updateView: (views): void => {
                setViews(views)
            },
        }
    }
}
