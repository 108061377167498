export const validateSingleInput = (oInput, _validFileExtensions): boolean => {
    var sFileName = oInput.name
    if (sFileName.length > 0) {
        var blnValid = false
        for (var j = 0; j < _validFileExtensions.length; j++) {
            var sCurExtension = _validFileExtensions[j]
            if (
                sFileName
                    .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
                    .toLowerCase() == sCurExtension.toLowerCase()
            ) {
                blnValid = true
                break
            }
        }

        if (!blnValid) {
            //alert("Sorry, " + sFileName + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "));
            return false
        }
    }

    return true
}
