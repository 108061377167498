import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import styled, { keyframes } from 'styled-components'
import { CustomerAuth } from '../../../../stores/auth/customer/customerAuth'
import { gameRunningState } from '../../../../stores/widgets/game/state'
import ProgressBar from './ProgressBar'

interface Props {
    setStop: Dispatch<SetStateAction<boolean>>
    questionNumber: number
    timeLimit: number
    widget: any
    userData: CustomerAuth
    lastAttemptTime: number | null
}

export const Timer: FC<Props> = ({
    setStop,
    questionNumber,
    timeLimit,
    widget,
    userData,
    lastAttemptTime,
}) => {
    const [timer, setTimer] = useState(timeLimit)
    const startGame = useRecoilValue(gameRunningState)
    const progressBarColor: string = widget.colourSettings['progress colour'][0]
    const textColor: string = widget.colourSettings['text colour'][0]

    useEffect(() => {
        if (
            (!widget?.settings?.unSetPrizes &&
                userData.userId &&
                !lastAttemptTime &&
                questionNumber) ||
            startGame
        ) {
            if (timer === 0) return setStop(true)
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1)
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [lastAttemptTime, setStop, timer, userData, questionNumber, startGame])

    useEffect(() => {
        setTimer(timeLimit)
    }, [questionNumber, timeLimit])

    return (
        <ProgressContainer>
            <ProgressBar
                progress={timer}
                timeLimit={timeLimit}
                size={120}
                trackWidth={3}
                indicatorWidth={5}
                label="seconds"
                indicatorColor={progressBarColor}
                labelColor={textColor}
            />
        </ProgressContainer>
    )
}

const spinner = keyframes`
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(360deg)
    }
`

const ProgressContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
    svg {
        max-width: 100%;
        vertical-align: middle;
    }

    .svg-pi-wrapper {
        position: relative;
    }

    .svg-pi {
        transform: rotate(-90deg);
    }

    .svg-pi-indicator--spinner {
        animation: ${spinner} 0.75s linear infinite;
        transform-origin: center;
    }

    .svg-pi-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .svg-pi-label__loading {
        opacity: 0.5;
        font-size: 0.75em;
    }

    .svg-pi-label__progress {
        font-size: 1.5em;
        font-weight: bold;
    }

    .svg-pi-label__loading,
    .svg-pi-label__progress {
        display: block;
    }
`
