import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import {
    currentLayerState,
    currentViewState,
    liveCampaignState,
} from '../../../stores/campaign/live/state'
import { useAnalytics } from '../../analytics/useAnalytics'

export const useButtonAction = (action) => {
    const [currentView, setCurrentView] = useRecoilState(currentViewState)
    const setCurrentLayer = useSetRecoilState(currentLayerState)
    const campaign = useRecoilValue(liveCampaignState)
    const analytics = useAnalytics('live')

    let returnedAction = null

    if (action && Object.keys(action).length > 0) {
        switch (action.type) {
            case 'redirect':
                returnedAction = () => {
                    analytics.track('redirect', { url: action.linkUrl })
                    window.open(action.linkUrl)
                }
                break
            case 'toggle popup':
                returnedAction = () => {
                    setCurrentLayer(action.layerIndex)
                    analytics.track('open popup', {
                        layer:
                            campaign.views[currentView] &&
                            campaign.views[currentView].layers[action.layerIndex].name,
                    })
                }
                break
            case 'go to page':
                returnedAction = () => {
                    analytics.track('go to page', { view: campaign.views[action.viewIndex].name })
                    setCurrentView(action.viewIndex)
                    setCurrentLayer(null)
                }
            case 'redirect to view':
                returnedAction = () => {
                    analytics.track('go to page', { view: campaign.views[action.viewIndex].name })
                    setCurrentView(action.viewIndex)
                    setCurrentLayer(null)
                }
                break
            case 'redirect to popup':
                returnedAction = () => {
                    setCurrentLayer(action.layerIndex)
                    analytics.track('open popup', {
                        layer:
                            campaign.views[currentView] &&
                            campaign.views[currentView].layers[action.layerIndex].name,
                    })
                }
                break
            default:
                returnedAction = () => {}
                break
        }
    }

    return {
        action: returnedAction,
    }
}
