import { atom } from 'recoil'

export interface CustomerAuth {
    userId: string
    firstName: string
    lastName: string
    email: string
    platform?: string
    idToken?: string
}

export const customerAuth = atom<CustomerAuth>({
    key: 'customerAuth',
    default: {
        userId: null,
        firstName: null,
        lastName: null,
        email: null,
        platform: null,
        idToken: null,
    },
})
