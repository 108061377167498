import { nanoid } from 'nanoid'
import { ImageDirection } from './Spinwheel'
import { ISegment } from './Segment'
import { Reward } from './Reward'

export interface IWheelSegment extends ISegment {
    strokeStyle: string
    textFillStyle: string
    imageDirection: ImageDirection
    fillStyle: string
    reward?: Reward
    prize?: string | null
}

class WheelSegment implements IWheelSegment {
    id = nanoid()
    strokeStyle = '#7f8ff7'
    textFillStyle = '#6478F9'
    fillStyle = ''
    image = ''
    imageName = ''
    text = '1'
    prize = null
    imageDirection = ImageDirection.N
    reward = {
        _id: '',
        rewardType: '',
        name: '',
        allocatedQuantity: 0,
        quantity: 0,
        isReward: false,
        probability: 0,
        prize: null,
        digitalSettings: {
            file: null,
            fileName: '',
            fileUrl: '',
            type: '',
            url: '',
        },
    }
    postGame = {
        viewIndex: 0,
        layerIndex: -1,
        sendEmail: false,
        sendSMS: false,
    }

    constructor(text: string, textFillStyle: string, fillStyle: string, imageData: string) {
        this.textFillStyle = textFillStyle
        this.fillStyle = fillStyle
        this.text = text
        this.image = imageData
    }
}

export default WheelSegment
