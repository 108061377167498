import { IFormEmail } from "../../models/Forms/FormEmail"

export const sendFormEmailPost = async (email: IFormEmail):Promise<void> => {
    try {
        const res = await fetch('/api/email/formConfirmation',{
            method:"POST",
            body: JSON.stringify(email)
        })
    } catch (error){
        throw new Error(error.message)
    }
}