import React from 'react'
import Head from "next/head"


const HeadStyles = () => {
    return (
        <Head>
            <style>
                {
                    `
                    h1 {
                        font-size: 2.5em;
                    }
                    
                    h2 {
                        font-size: 2.25em;
                    }
                    
                    h3 {
                        font-size: 2em;
                    }
                    
                    h4 {
                        font-size: 1.75em;
                    }
                    
                    h5 {
                        font-size: 1.5em;
                    }
                    
                    h6 {
                        font-size: 1.25em;
                    }
                    
                    p {
                        font-size: 1em;
                    }
                    `
                }
            </style>

        </Head>
    )
}

export default HeadStyles
