import React, { memo, MouseEvent, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { Toast } from '../../../../helpers/Toast'
import { useAnalytics } from '../../../../lib/analytics/useAnalytics'
import { verifyAttempt } from '../../../../lib/reward/session/verifyAttempt'

import { IWidget } from '../../../../models/Widget'
import { customerAuth } from '../../../../stores/auth/customer/customerAuth'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { liveCampaignState } from '../../../../stores/campaign/live/state'
import { gameAttemptAvailable, rewardState } from '../../../../stores/widgets/game/state'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { countdownTimeCalculate } from '../../../../utils/countdownTime'
import { EditingIndicator } from '../../EditingIndicator'
import CountdownAttempt from '../CountdownAttempt'
import PuzzleGameComponent from './PuzzleGameComponent'

interface Props {
    widget: IWidget
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
}

const Puzzle = ({ widget, isPreview = false, updateActiveWidget, index, parentIndex }: Props) => {
    const sectionState = useRecoilValue(viewSectionState)
    const ativeWidget = useRecoilValue(activeWidgetState)
    const campaign = useRecoilValue(liveCampaignState)
    const setAttemptAvailable = useSetRecoilState(gameAttemptAvailable(widget?.id))
    const _customerAuth = useRecoilValue(customerAuth)
    const [loading, setLoading] = useState<boolean>(false)
    const [lastAttemptTime, setLastAttemptTime] = useState(null)
    const [reward, setReward] = useRecoilState(rewardState(widget?.id))
    const [attemptUpdated, setAttemptUpdated] = useState(false)
    const analytics = useAnalytics('live')

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    useEffect(() => {
        if (!_customerAuth.userId) {
            return
        }

        async function calculateReward(gameWidget: IWidget, campaignId: string) {
            setLoading(true)
            const res = await fetch('/api/reward/calculate', {
                method: 'POST',
                body: JSON.stringify({ campaignId, gameWidget }),
            })

            const result = await res.json()

            if (result.message === 'exceeded tries' && result.data.lastAttemptTime) {
                const timeToNextGame = countdownTimeCalculate(
                    gameWidget,
                    new Date(result.data.lastAttemptTime * 1000)
                )
                setLastAttemptTime(timeToNextGame)
            }

            if (result.code !== 200) {
                Toast('No more attempts for puzzle game', 'error')
                return
            }

            if (result.data.attempts) {
                setReward(result.data.reward)
            } else {
                setReward(result.data)
            }

            setAttemptAvailable(true)
            setLoading(false)
        }

        if (!isPreview && reward === null) {
            calculateReward(widget, campaign?.campaignId)
        }
    }, [_customerAuth.userId, campaign?.campaignId, reward])

    const trackProgress = async (): Promise<void> => {
        if (!attemptUpdated) {
            await verifyAttempt(
                campaign.campaignId,
                widget.id,
                widget.settings.attempts.count,
                widget.settings.attempts.period,
                widget.settings.attempts.totalAttempts
            )
            analytics.track('start game', { game: widget.name })
        }
    }

    const appearanceSettings = widget?.appearanceSettings
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')

    return (
        <Wrapper onClick={onEditMode}>
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                loading={loading}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
            >
                <PuzzleGameComponent
                    widget={widget}
                    reward={reward}
                    setReward={setReward}
                    playerData={_customerAuth}
                    trackProgress={trackProgress}
                />

                {lastAttemptTime && _customerAuth?.userId && (
                    <CountdownAttempt
                        lastAttemptTime={lastAttemptTime}
                        fontFamily="Poppins"
                        color="#2d3748"
                    />
                )}

                {isPreview && <EditingIndicator widgetName={capitalizeFirstLetter(widget?.name)} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Puzzle)

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Container = styled.div<{
    isActive: boolean
    isPreview: boolean
    loading: boolean
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    pointer-events: auto;
    position: relative;
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
