import React, { memo, MouseEvent, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { IWidget } from '../../../../models/Widget'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import SocialIcons from '../../../Templates/Custom/Icons/SocialIcons'
import { EditingIndicator } from '../../EditingIndicator'

interface Props {
    widget?: IWidget
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    isPreview?: boolean
}

const Social = ({ widget, updateActiveWidget, index, parentIndex, isPreview = false }: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    let appearanceSettings = widget?.appearanceSettings
    let colourSettings = widget?.colourSettings
    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget?.settings,
        }
    }
    if (!colourSettings) {
        colourSettings = {
            iconColour: widget?.settings?.iconColour,
        }
    }

    const width = appearanceSettings?.width + '%'
    const iconWidth = appearanceSettings?.iconWidth + 'px'
    const columns = appearanceSettings?.columns
    const iconColour = Array.isArray(colourSettings.iconColour)
        ? colourSettings.iconColour[0]
        : colourSettings.iconColour
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const socialLinks = widget?.settings?.links
    const iconSpacing = appearanceSettings?.iconSpacing / 10 + 'em'
    const isRow = appearanceSettings?.flex
    const useIcon = appearanceSettings?.useIcon

    const styles = {
        margin: iconSpacing,
    }

    const defaultLink = {
        linkedin: 'https://www.linkedin.com/company/deployableco',
        facebook: 'https://www.facebook.com/deployableco',
    }

    const renderSocial = useMemo(() => {
        const checkValues = Object.values(socialLinks).filter((str: string) =>
            str?.includes('http')
        )

        if (checkValues.length === 0) {
            return Object.keys(defaultLink).map((key) => {
                return (
                    <SocialIcons
                        key={key}
                        platform={key}
                        height={iconWidth}
                        width={iconWidth}
                        color={iconColour}
                        link={defaultLink[key]}
                        styles={styles}
                        isPreview={isPreview}
                        useIcon={useIcon}
                    />
                )
            })
        } else {
            return Object.keys(socialLinks).map((key) => {
                if (socialLinks[key] !== '' && typeof socialLinks[key] === 'string') {
                    return (
                        <SocialIcons
                            key={key}
                            platform={key}
                            height={iconWidth}
                            width={iconWidth}
                            color={iconColour}
                            link={socialLinks[key]}
                            styles={styles}
                            isPreview={isPreview}
                            useIcon={useIcon}
                        />
                    )
                }
            })
        }
    }, [iconColour, iconWidth, isPreview, socialLinks])

    return (
        <Wrapper>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                isRow={isRow}
                width={width}
                columns={columns}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                onClick={onEditMode}
            >
                {renderSocial}
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Social)

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
    isRow: boolean
    width: string
    columns: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    position: relative;
    display: ${(props) => (props.isRow ? 'flex' : 'grid')};
    justify-content: ${(props) => (props.isRow ? 'space-evenly' : 'center')};
    justify-items: center;
    align-items: center;
    grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
    cursor: pointer;
    width: ${(props) => props.width};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    .hover_block {
        display: none;
    }

    &:hover {
        .hover_block {
            display: block;
            position: absolute;
            background: none;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
