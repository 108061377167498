import React from 'react'
import Image from '../../../Widgets/General/Image/Image'
import Video from '../../../Widgets/General/Video/Video'
import Richtext from '../../../Widgets/General/Richtext/Richtext'
import Social from '../../../Widgets/General/Social/Social'
import Button from '../../../Widgets/General/Button/Button'
import Container from '../../../Widgets/General/Container/Container'
import Iframe from '../../../Widgets/General/Iframe/Iframe'
import FormContainer from '../../../Widgets/Form/FormContainer/FormContainer'
import InputBox from '../../../Widgets/Form/Inputbox/Inputbox'
import TextArea from '../../../Widgets/Form/Textarea/Textarea'
import Select from '../../../Widgets/Form/Select/Select'
import Radio from '../../../Widgets/Form/Radio/Radio'
import Checkbox from '../../../Widgets/Form/Checkbox/Checkbox'
import Upload from '../../../Widgets/Form/Upload/Upload'
import Rating from '../../../Widgets/Form/Rating/Rating'
import Datepicker from '../../../Widgets/Form/Datepicker/Datepicker'
import Spinwheel from '../../../Widgets/Game/Spinwheel/Spinwheel'
import Scratchcard from '../../../Widgets/Game/Scratchcard/Scratchcard'
import Linktext from '../../../Widgets/General/Linktext/Linktext'
import Slider from '../../../Widgets/General/Swiper/Swiper'
import Audio from '../../../Widgets/General/Audio/Audio'
import ProfileCard from '../../../Widgets/General/Profilecard/Profilecard'
import CustomDigitalCard from '../../../Widgets/General/CustomDigitalCard/CustomDigitalCard'
import Flipcard from '../../../Widgets/Game/Flipcard/Flipcard'
import Puzzle from '../../../Widgets/Game/Puzzle/Puzzle'
import Stepper from '../../../Widgets/General/Stepper/Stepper'
import Spotify from '../../../Widgets/General/Spotify/Spotify'
import Countdown from '../../../Widgets/General/Countdown/Countdown'
import Events from '../../../Widgets/General/Events/Events'
import Quiz from '../../../Widgets/Game/Quiz/Quiz'
import Polls from '../../../Widgets/General/Polls/Polls'

interface Props {
    id: string
    widgets: any
    parentIndex?: number
}

const RenderWidgets = ({ id, widgets, parentIndex }: Props) => {
    return (
        <div style={{ width: '100%' }}>
            {widgets?.map((widget, index) => {
                if (!widget) return null
                const widget_type = widget.type
                let widget_id = ''
                if (parentIndex) {
                    widget_id = `${parentIndex}-${index}`
                } else {
                    widget_id = `${id}-${index}`
                }

                switch (widget_type) {
                    case 'image':
                        return (
                            <Image
                                key={`${widget_id}-${index}`}
                                widget={widget}
                                isPreview={false}
                            />
                        )
                    case 'video':
                        return (
                            <Video
                                key={`${widget_id}-${index}`}
                                widget={widget}
                                isPreview={false}
                            />
                        )
                    case 'rich text':
                        return <Richtext key={`${widget_id}-${index}`} widget={widget} />
                    case 'link text':
                        return <Linktext key={`${widget_id}-${index}`} widget={widget} />
                    case 'profile card':
                        return <ProfileCard key={`${widget_id}-${index}`} widget={widget} />
                    case 'polls':
                        return <Polls key={`${widget_id}-${index}`} widget={widget} />
                    case 'events':
                        return <Events key={`${widget_id}-${index}`} widget={widget} />
                    case 'custom digital card':
                        return <CustomDigitalCard key={`${widget_id}-${index}`} widget={widget} />
                    case 'stepper':
                        return <Stepper key={`${widget_id}-${index}`} widget={widget} />
                    case 'button':
                    case 'gamebutton':
                        return (
                            <Button
                                key={`${widget_id}-${index}`}
                                widget={widget}
                                isPreview={false}
                            />
                        )
                    case 'social':
                        return <Social key={`${widget_id}-${index}`} widget={widget} />
                    case 'container':
                        return (
                            <Container
                                key={`${widget_id}-${index}`}
                                widget={widget}
                                parentIndex={index}
                            />
                        )
                    case 'swiper':
                        return <Slider key={`${widget_id}-${index}`} widget={widget} />
                    case 'audio':
                        return <Audio key={`${widget_id}-${index}`} widget={widget} />
                    case 'spotify':
                        return <Spotify key={`${widget_id}-${index}`} widget={widget} />
                    case 'iframe':
                        return (
                            <Iframe
                                key={`${widget_id}-${index}`}
                                widget={widget}
                                isPreview={false}
                            />
                        )
                    case 'form':
                        return (
                            <FormContainer
                                key={`${widget_id}-${index}`}
                                parentIndex={index}
                                widget={widget}
                            />
                        )
                    case 'submit':
                        return (
                            <Button
                                key={`${parentIndex}-${index}`}
                                widget={widget}
                                isPreview={false}
                            />
                        )
                    case 'input box':
                        return <InputBox key={`${parentIndex}-${index}`} widget={widget} />
                    case 'text area':
                        return <TextArea key={`${parentIndex}-${index}`} widget={widget} />
                    case 'select':
                        return <Select key={`${parentIndex}-${index}`} widget={widget} />
                    case 'radio':
                        return <Radio key={`${parentIndex}-${index}`} widget={widget} />
                    case 'checkbox':
                        return <Checkbox key={`${parentIndex}-${index}`} widget={widget} />
                    case 'upload':
                        return <Upload key={`${parentIndex}-${index}`} widget={widget} />
                    case 'rating':
                        return <Rating key={`${parentIndex}-${index}`} widget={widget} />
                    case 'datepicker':
                        return <Datepicker key={`${parentIndex}-${index}`} widget={widget} />
                    case 'spinwheel':
                        return <Spinwheel key={`${parentIndex}-${index}`} widget={widget} />
                    case 'flipcard':
                        return <Flipcard key={`${parentIndex}-${index}`} widget={widget} />
                    case 'puzzle':
                        return <Puzzle key={`${parentIndex}-${index}`} widget={widget} />
                    case 'quiz':
                        return <Quiz key={`${parentIndex}-${index}`} widget={widget} />
                    case 'scratchcard':
                        return (
                            <Scratchcard
                                key={`${parentIndex}-${index}`}
                                widget={widget}
                                isPreview={false}
                            />
                        )
                    case 'countdown':
                        return <Countdown key={`${widget_id}-${index}`} widget={widget} />
                    default:
                        return <React.Fragment key={index}></React.Fragment>
                }
            })}
        </div>
    )
}

export default RenderWidgets
