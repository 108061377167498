import { nanoid } from 'nanoid'
import ButtonSettings from './ButtonSettings'

class Button {
    name = 'button'
    type = 'button'
    icon = '/icons/widgets/button.svg'
    category = 'general'
    id = nanoid()
    appearanceSettings = {
        width: 90,
        height: 100,
        columns: 1,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 0,
        marginRight: 0,
        align: 'center',
        font: {
            family: 'Poppins',
            size: 14,
            weight: '400',
        },
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        buttonSpacing: 5,
    }
    colourSettings = {
        'button colour': ['#000', '#FFA7E1', '#0426FF'],
        'text colour': ['#fff', '#FFA7E1', '#0426FF'],
        'border colour': ['#fff', '#FFA7E1', '#0426FF'],
    }
    settings = {
        links: [new ButtonSettings('Button 1', 'https://deploy.link', 'redirect', 0, 0)],
    }
}

export default Button
