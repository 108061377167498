import React from 'react'
import SimpleBar from 'simplebar-react'
import { useSetRecoilState } from 'recoil'
import { currentLayerState } from '../../../../stores/campaign/live/state'
import RenderWidgets from './RenderWidgets'
import ContainerWrapper from './ContainerWrapper'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'

interface Props {
    layerData: any
}

const Header = styled.div`
    position: absolute;
    top: 3%;
    left: 3%;
    z-index: 3;

    .icon {
        cursor: pointer;
        filter: brightness(150%);
    }
`

const LayerTemplate = ({ layerData }: Props) => {
    const setCurrentLayer = useSetRecoilState(currentLayerState)

    const appearanceSettings = layerData.appearanceSettings || layerData.settings
    const colourSettings = layerData.colourSettings || layerData.settings

    const width = appearanceSettings.width + '%' || layerData.width + '%'
    const height = appearanceSettings.height + '%' || layerData.height + '%'
    const backgroundImage = appearanceSettings.backgroundImageUrl
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'

    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'

    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`
    const backgroundColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']

    return (
        <>
            <div
                className="w-full h-full fixed z-10 bg-black opacity-50 inset-0"
                onClick={() => setCurrentLayer(null)}
            ></div>
            <PopupContainer
                height={height}
                width={width}
                backgroundImage={`url(${backgroundImage || ''})`}
                backgroundColor={backgroundColor}
                borderTopWidth={borderTopWidth}
                borderBottomWidth={borderBottomWidth}
                borderLeftWidth={borderLeftWidth}
                borderRightWidth={borderRightWidth}
                padding={paddingTop && paddingRight && paddingBottom && paddingLeft}
                borderTopLeftRadius={borderTopLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                borderWidth={borderWidth}
                borderColor={borderColor}
            >
                <Header>
                    <CloseIcon
                        className="icon"
                        htmlColor={backgroundColor}
                        fontSize="small"
                        onClick={() => {
                            setCurrentLayer(null)
                        }}
                    />
                </Header>
                <SimpleBar style={{ maxHeight: '100vh' }}>
                    <ContainerWrapper>
                        <RenderWidgets id={layerData.id} widgets={layerData.widgets} />
                    </ContainerWrapper>
                </SimpleBar>
            </PopupContainer>
        </>
    )
}

export default LayerTemplate

const PopupContainer = styled.div<{
    backgroundImage: string
    backgroundColor: string
    height: string
    width: string
    padding: string
    borderTopLeftRadius: string
    borderTopRightRadius: string
    borderBottomLeftRadius: string
    borderBottomRightRadius: string
    borderColor?: string
    borderWidth: string
    borderTopWidth: string
    borderBottomWidth: string
    borderLeftWidth: string
    borderRightWidth: string
}>`
    position: absolute;
    cursor: pointer;
    z-index: 20;
    background-image: ${(props) => props.backgroundImage};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${(props) => props.backgroundColor};
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    min-height: 50vh;
    max-width: 500px;
    overflow: auto;
    padding: ${(props) => props.padding};
    border-bottom-width: ${(props) => props.borderBottomWidth};
    border-top-width: ${(props) => props.borderTopWidth};
    border-left-width: ${(props) => props.borderLeftWidth};
    border-right-width: ${(props) => props.borderRightWidth};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    border-color: ${(props) => props.borderColor || '#000'};
    border: ${(props) => `${props.borderWidth} solid ${props.borderColor}`};

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
`
