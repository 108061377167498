import { uploadFiles } from './uploadFiles'

export interface FilesToUpload {
    fieldName: string
    files: File[]
    fileType: string
}

export const formFileUpload = async (
    filesToUpload: FilesToUpload[],
    uid: string,
    campaignId: string,
    formId: string
): Promise<any[]> => {
    if (filesToUpload.length > 0) {
        const uploadData = await uploadFiles(filesToUpload, uid, campaignId, formId)
        if (!uploadData) {
            return []
        }
        return uploadData
    } else {
        return []
    }
}
