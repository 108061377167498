import React, { FC, MouseEvent } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { IWidget } from '../../../../models/Widget'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { EditingIndicator } from '../../EditingIndicator'
import { EventsComponent } from './EventsComponent'

interface Props {
    widget?: IWidget
    parentIndex?: number | null
    index?: number
    updateActiveWidget?: (
        index: number,
        parentIndex: number | null,
        widget: IWidget | undefined
    ) => void
    isPreview?: boolean
}

const Events: FC<Props> = ({
    widget,
    parentIndex = null,
    index = 0,
    isPreview = false,
    updateActiveWidget,
}) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'
    const fontFamily = widget?.appearanceSettings.font.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')

    const handleClickOn = (e: MouseEvent<HTMLElement>) => {
        if (isPreview && updateActiveWidget) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    return (
        <Wrapper>
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <Container isActive={isActive} isPreview={isPreview} onClick={handleClickOn}>
                <EventsComponent widget={widget} />
                {isPreview && widget && (
                    <EditingIndicator widgetName={capitalizeFirstLetter(widget?.name)} />
                )}
            </Container>
        </Wrapper>
    )
}

export default Events

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean

    isPreview: boolean
}>`
    position: relative;
    cursor: pointer;
    width: 100%;

    a {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        pointer-events: ${(props) => (props.isPreview ? 'none' : 'auto')};
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
