import React, { createContext, memo, useContext, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { widgetFormState } from '../../../../stores/widgets/forms/state'
import { useTemplate } from '../../../Templates/Custom/CustomTemplate'
import Container from '../../General/Container/Container'

interface Props {
    widget: any
    parentIndex: number
}

interface IFormContext {
    formId: string
}

const FormContext = createContext<IFormContext>({
    formId: '',
})

const FormContainer = ({ parentIndex, widget }: Props) => {
    const { campaignId } = useTemplate()
    const setFormState = useSetRecoilState(widgetFormState(widget.id))

    useEffect(() => {
        if (widget) {
            setFormState({
                campaignId,
                schemaVersion: 1,
                formId: widget.formId,
                formType: widget.type,
                formName: widget.name,
                fields: widget.fields,
            })
        }
    }, [widget])

    return (
        <FormContext.Provider value={{ formId: widget.id }}>
            <form style={{ outline: 'none' }} name={widget.name} className="w-full">
                <Container parentIndex={parentIndex} widget={widget} />
            </form>
        </FormContext.Provider>
    )
}

export default memo(FormContainer)

export const useFormContext = (): IFormContext => {
    return useContext(FormContext)
}
