import React, { memo, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { Toast } from '../../../../helpers/Toast'
import { useAnalytics } from '../../../../lib/analytics/useAnalytics'
import { verifyAttempt } from '../../../../lib/reward/session/verifyAttempt'
import { IWidget } from '../../../../models/Widget'
import { customerAuth } from '../../../../stores/auth/customer/customerAuth'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { liveCampaignState } from '../../../../stores/campaign/live/state'
import {
    gameAttemptAvailable,
    gameErrors,
    gameRunningState,
    rewardState,
} from '../../../../stores/widgets/game/state'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { countdownTimeCalculate } from '../../../../utils/countdownTime'
import { EditingIndicator } from '../../EditingIndicator'
import CountdownAttempt from '../CountdownAttempt'
import ErrorsModal from '../ErrorsModal'
import QuizGameComponent from './QuizGameComponent'

interface Props {
    widget: IWidget
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
}

const Quiz = ({ widget, isPreview = false, updateActiveWidget, index, parentIndex }: Props) => {
    const sectionState = useRecoilValue(viewSectionState)
    const ativeWidget = useRecoilValue(activeWidgetState)
    const _customerAuth = useRecoilValue(customerAuth)
    const campaign = useRecoilValue(liveCampaignState)
    const [loading, setLoading] = useState<boolean>(false)
    const [reward, setReward] = useRecoilState(rewardState(widget?.id))
    const [lastAttemptTime, setLastAttemptTime] = useState(null)
    const [error, setError] = useRecoilState(gameErrors)
    const setAttemptAvailable = useSetRecoilState(gameAttemptAvailable(widget?.id))
    const startGame = useRecoilValue(gameRunningState)
    const analytics = useAnalytics('live')

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    useEffect(() => {
        async function calculateReward(gameWidget: IWidget, campaignId: string) {
            setLoading(true)
            const res = await fetch('/api/reward/calculate', {
                method: 'POST',
                body: JSON.stringify({ campaignId, gameWidget }),
            })

            const result = await res.json()

            if (result.message === 'exceeded tries' && result.data.lastAttemptTime) {
                const timeToNextGame = countdownTimeCalculate(
                    gameWidget,
                    new Date(result.data.lastAttemptTime * 1000)
                )
                setLastAttemptTime(timeToNextGame)
            } else {
                setLastAttemptTime(null)
            }

            if (result.code !== 200) {
                Toast('No more attempts for quiz game', 'error')
                setError('No more attempts for quiz game')
                return
            }

            if (result.data.attempts) {
                setReward(result.data.reward)
            } else {
                setReward(result.data)
            }

            setAttemptAvailable(true)
            setLoading(false)
        }
        //This function for unauthorized users
        const calculateAttempt = async (gameWidget: IWidget, campaignId: string) => {
            try {
                setLoading(true)
                const res = await fetch('/api/game/attempt', {
                    method: 'POST',
                    body: JSON.stringify({ campaignId, gameWidget }),
                })
                const result = await res.json()

                if (result.code !== 200) {
                    Toast(result.message, 'error')
                    setError(result.message)
                }
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
                setError('Something went wrong')
            }
        }

        if (!widget?.settings?.unSetPrizes) {
            if (!_customerAuth.userId) {
                return
            }

            if (!isPreview && reward === null) {
                calculateReward(widget, campaign?.campaignId)
            }
        } else {
            // Uncomment this to give the player only one attempt
            // const isPlayedGame = localStorage.getItem(widget.name)

            // if (!isPreview && isPlayedGame === widget.id) {
            //     Toast('You have already played this game', 'error')
            //     setError('You have already played this game')
            //     return
            // }

            if (!isPreview) {
                calculateAttempt(widget, campaign?.campaignId)
            }
        }
    }, [_customerAuth.userId, campaign?.campaignId, reward, widget])

    const trackAttempt = async (): Promise<void> => {
        await verifyAttempt(
            campaign.campaignId,
            widget.id,
            widget.settings.attempts.count,
            widget.settings.attempts.period,
            widget.settings.attempts.totalAttempts
        )
        analytics.track('start game', { game: widget.name })
    }

    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const fontSize = appearanceSettings?.fontSize + 'px'
    const fontWeight = appearanceSettings?.font?.weight

    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = appearanceSettings?.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings?.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings?.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings?.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const color = colourSettings['text colour'][0]
    const borderColor = colourSettings['border colour'][0]
    const backgroundColor = colourSettings['background colour'][0]
    const border = `${borderWidth} solid ${borderColor}`
    const backgroundImage = widget?.settings?.backgroundImageUrl

    const checkStartGame = useCallback(() => {
        if (startGame) {
            return true
        }
        if (_customerAuth.userId) {
            return true
        }
        if (lastAttemptTime) {
            return true
        }
        return false
    }, [_customerAuth.userId, lastAttemptTime, startGame])

    return (
        <Wrapper onClick={onEditMode}>
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <Container
                isActive={isActive}
                isPreview={isPreview}
                loading={loading}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                border={border}
                backgroundColor={backgroundColor}
                fontFamily={fontFamily}
                fontSize={fontSize}
                fontWeight={fontWeight}
                backgroundImage={backgroundImage}
                startGame={checkStartGame()}
            >
                <QuizGameComponent
                    reward={reward}
                    widget={widget}
                    setReward={setReward}
                    userData={_customerAuth}
                    trackAttempt={trackAttempt}
                    isPreview={isPreview}
                    color={color}
                    lastAttemptTime={lastAttemptTime}
                    setError={setError}
                />

                {lastAttemptTime && _customerAuth?.userId && (
                    <CountdownAttempt
                        lastAttemptTime={lastAttemptTime}
                        fontFamily="Poppins"
                        color="#2d3748"
                    />
                )}

                {isPreview && <EditingIndicator widgetName={capitalizeFirstLetter(widget?.name)} />}
            </Container>
            <ErrorsModal isOpen={error !== ''} error={error} />
        </Wrapper>
    )
}

export default memo(Quiz)

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Container = styled.div<{
    isActive: boolean
    isPreview: boolean
    loading: boolean
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    border: string
    backgroundColor: string
    fontFamily: string
    fontSize: string
    fontWeight: number
    backgroundImage: string
    startGame: boolean
}>`
    pointer-events: ${(props) => (props.startGame ? 'auto' : 'none')};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => props.fontWeight};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    background-color: ${(props) => (props.backgroundImage ? '' : props.backgroundColor)};
    background-image: ${(props) => `url(${props.backgroundImage})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    border: ${(props) => props.border};

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
