import React, { ChangeEvent, memo, MouseEvent } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { widgetFormField } from '../../../../stores/widgets/forms/state'
import Widget from '../../../../types/Widget'
import { EditingIndicator } from '../../EditingIndicator'
import { useFormContext } from '../FormContainer/FormContainer'

interface Props {
    widget?: Widget
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: Widget) => void
}

const Radio = ({ widget, isPreview = false, updateActiveWidget, index, parentIndex }: Props) => {
    const { formId } = useFormContext()
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    const name = widget?.settings?.name
    const label = widget?.settings?.label
    const options: { label: string; value: string }[] = widget?.settings?.options

    const [value, setValue] = useRecoilState(widgetFormField({ formId, fieldName: name }))

    const handleSelectChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e.target.value)
    }

    const width = widget?.appearanceSettings?.width + '%'
    const font = widget?.appearanceSettings?.font || { family: 'Open Sans' }
    const modFontFamily = font.family?.replace(/\s/g, '+')
    const fontUrl = widget?.appearanceSettings?.font?.url
    const paddingTop = widget?.appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = widget?.appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = widget?.appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = widget?.appearanceSettings?.paddingRight / 10 + 'em'
    const marginTop = widget?.appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = widget?.appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = widget?.appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = widget?.appearanceSettings?.marginRight / 10 + 'em'

    const borderTopLeftRadius = widget?.appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget?.appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = widget?.appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = widget?.appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = widget?.appearanceSettings?.borderTopWidth / 10 + 'em'
    const borderBottomWidth = widget?.appearanceSettings?.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = widget?.appearanceSettings?.borderLeftWidth / 10 + 'em'
    const borderRightWidth = widget?.appearanceSettings?.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const alignment = widget?.appearanceSettings?.alignment
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'
    const spacing = widget?.appearanceSettings?.spacing / 10 + 'em'
    const columns = widget?.appearanceSettings?.columns

    const color = Array.isArray(widget.colourSettings['text colour'])
        ? widget.colourSettings['text colour'][0]
        : widget.colourSettings['text colour']
    const borderColor = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']
    const labelColour = Array.isArray(widget.colourSettings['label colour'])
        ? widget.colourSettings['label colour'][0]
        : widget.colourSettings['label colour']
    const backgroundColor = Array.isArray(widget.colourSettings['background colour'])
        ? widget.colourSettings['background colour'][0]
        : widget.colourSettings['background colour']
    const iconColour = Array.isArray(widget?.colourSettings['icon colour'])
        ? widget?.colourSettings['icon colour'][0]
        : widget?.colourSettings['icon colour']

    const weight = widget?.appearanceSettings?.font?.weight
    const border = `${borderWidth} solid ${borderColor}`
    const iconSize = widget?.appearanceSettings?.iconSize / 10 + 'em'
    const letterSpacing = widget?.appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = widget?.appearanceSettings?.font?.lineHeight || 1

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            fontFamily={font.family}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${font.family}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${font.family}';
                      }`}
            </style>
            <Container
                onClick={onEditMode}
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                fontSize={fontSize}
                weight={weight}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                border={border}
                backgroundColor={backgroundColor}
            >
                <label
                    style={{
                        width: '100%',
                        fontSize: fontSize,
                        marginBottom: '0.5em',
                        color: labelColour,
                        letterSpacing,
                        lineHeight,
                    }}
                >
                    {label}
                </label>

                <div
                    className={`grid grid-cols-${columns}`}
                    style={{
                        gridGap: spacing,
                        width: '100%',
                        color,
                    }}
                >
                    <style jsx>
                        {`
                            .radio {
                                display: grid;
                                grid-template-columns: min-content auto;
                                grid-gap: 0.5em;
                                align-items: center;
                            }
                            .radio__input input[type='radio'] {
                                opacity: 0;
                                width: 0;
                                height: 0;
                            }
                            .radio__control {
                                display: block;
                                width: ${iconSize};
                                height: ${iconSize};
                                border-radius: 50%;
                                border: 0.15em solid ${iconColour};
                                transform: translateY(-0.05em);
                            }
                            .radio__input {
                                display: flex;
                            }
                            input[type='radio']:checked + .radio__control {
                                background: radial-gradient(
                                    ${iconColour} 50%,
                                    rgba(255, 0, 0, 0) 60%
                                );
                            }
                        `}
                    </style>

                    {options.map((option, index) => {
                        return (
                            <label key={`${index}`} className="radio radio-gradient">
                                <span className="radio__input">
                                    <input
                                        type="radio"
                                        value={option.value}
                                        onChange={handleSelectChange}
                                        name={name}
                                        checked={option.value === value}
                                    />
                                    <span className="radio__control"></span>
                                </span>
                                <span
                                    className="radio__label"
                                    style={{ fontSize, letterSpacing, lineHeight }}
                                >
                                    {option.label}
                                </span>
                            </label>
                        )
                    })}
                </div>
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </Container>
        </Wrapper>
    )
}

export default memo(Radio)

const Wrapper = styled.div.attrs((props) => ({}))<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    fontFamily: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    justify-content: center;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    font-family: ${(props) => props.fontFamily};
`
const Container = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
    width: string
    fontSize: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    border: string
    backgroundColor: string
    weight: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: ${(props) => props.border};
    background-color: ${(props) => props.backgroundColor};
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => +props.weight};
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    input {
        width: 100%;
        border-radius: inherit;
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }
`
