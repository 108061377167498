import React, { useEffect, createContext, useContext, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import CustomCampaign from '../../../types/Templates/Custom'
import { ToastContainer } from 'react-toastify'
import RenderWidgets from './Live/RenderWidgets'
import LayerTemplate from './Live/LayerTemplate'
import {
    currentLayerState,
    layerSelector,
    viewSelector,
    windowSizeState,
} from '../../../stores/campaign/live/state'
import HeadStyles from './Live/HeadStyles'
import Background from './Live/Background'
import ContainerWrapper from './Live/ContainerWrapper'
import SocialAuthSignin from '../../Auth/Social/SocialAuthSignin'
import { loginModal } from '../../../stores/auth/customer/loginModal'
import { currentLayerIndexState } from '../../../stores/builder/currentLayerIndexState'
import LayerSelector from './Components/Layers/LayerSelector'

interface Props {
    data: CustomCampaign
}

const TemplateContext = createContext(null)

const CustomTemplate = ({ data }: Props) => {
    const campaignId = data.campaignId
    const uid = data.uid

    const [authVisible, setAuthVisible] = useRecoilState(loginModal)
    const [windowSize, setWindowSize] = useRecoilState(windowSizeState)
    const view = useRecoilValue(viewSelector)
    const layer = useRecoilValue(layerSelector)
    const setCurrentLayer = useSetRecoilState(currentLayerState)

    useEffect(() => {
        if (window) {
            const width = window.innerWidth
            const height = window.innerHeight
            setWindowSize({ ...windowSize, width, height })
        }
    }, [])

    useEffect(() => {
        // look for auto timer layers
        view.layers.forEach((layer, i) => {
            if (layer.settings.popupClosing === 'Automatic timer') {
                setTimeout(
                    () => {
                        setCurrentLayer(i)
                    },
                    layer.settings.autoTimer * 1000,
                    this
                )
            }
        })
    }, [])

    const backgroundColour = Array.isArray(view.backgroundColour)
        ? view.backgroundColour[0]
        : view.backgroundColour

    return (
        <TemplateContext.Provider value={{ campaignId, uid }}>
            <HeadStyles />
            <Background
                backgroundImageUrl={view.backgroundImageUrl || ''}
                backgroundColour={view.backgroundImageUrl ? '' : backgroundColour || '#FFFFFF'}
            />
            <SocialAuthSignin visible={authVisible} toggleVisible={setAuthVisible} />
            <ContainerWrapper>
                <RenderWidgets id={view.id} widgets={view.widgets} />
            </ContainerWrapper>

            {layer && <LayerTemplate layerData={layer} />}

            <ToastContainer
                className="toaster-container"
                position="bottom-center"
                autoClose={111111100}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TemplateContext.Provider>
    )
}

export default CustomTemplate

export const useTemplate = () => {
    return useContext(TemplateContext)
}
