import { IView } from '../models/View'
import { IWidget } from '../models/Widget'

export const checkGameWidget = (views: IView[]) => {
    let formState = []
    if (views) {
        formState = views
            .map((view) => {
                return view.widgets.filter((widget) => widget.type === 'form')
            })
            .flat()

        if (formState.length > 0) {
            for (let i = 0; i < formState.length; i++) {
                const checkGame = formState[i].widgets?.some(
                    (widget: IWidget) => widget.category === 'game'
                )
                if (checkGame) {
                    return true
                }
            }
        }
    }
}

export const getGameWidget = (views: IView[]) => {
    let formState = []
    if (views) {
        formState = views
            .map((view) => {
                return view.widgets.filter((widget) => widget.type === 'form')
            })
            .flat()

        if (formState.length > 0) {
            for (let i = 0; i < formState.length; i++) {
                const getGame = formState[i].widgets?.filter(
                    (widget: IWidget) => widget.category === 'game'
                )
                if (getGame.length > 0) {
                    return getGame
                }
            }
        }
    }
}

export const findGameParentIndex = (views: IView[]) => {
    if (views) {
        const gameViewIndex = views.findIndex((view) =>
            view.widgets.findIndex((widget) => widget.type === 'gamebutton')
        )

        if (gameViewIndex !== -1) {
            const gameIndex = views[gameViewIndex].widgets.findIndex(
                (widget) => widget.type === 'gamebutton'
            )
            if (gameIndex !== -1) {
                return gameIndex - 1
            }
        }
    }
}
