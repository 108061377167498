import { Stepper as StepperMUI, StepIcon } from '@material-ui/core'
import React, { MouseEvent, useCallback, useState } from 'react'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'

import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Typography from '@material-ui/core/Typography'
import { IWidget } from '../../../../models/Widget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { Button } from '../../../../stories/Button/Button'
import { EditingIndicator } from '../../EditingIndicator'
import { IStep } from '../../../../models/Step'

interface Props {
    widget?: IWidget
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    isPreview?: boolean
}

const Stepper = ({ widget, updateActiveWidget, index, parentIndex, isPreview = false }: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)
    const [activeStep, setActiveStep] = useState(0)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const stepIcon = useCallback((link, index) => {
        if (link !== '') {
            return (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                    style={{ width: '4em', height: '4em', borderRadius: '100%' }}
                    src={link}
                    alt="Step icon"
                />
            )
        } else {
            return <StepIcon icon={index} />
        }
    }, [])

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    let appearanceSettings = widget?.appearanceSettings
    let colourSettings = widget?.colourSettings
    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget?.settings,
        }
    }

    const width = appearanceSettings?.width + '%'
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size : 14
    const align = appearanceSettings?.alignment
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const weight = appearanceSettings?.font?.weight || 300

    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url
    const textColor = Array.isArray(colourSettings['text colour'])
        ? colourSettings['text colour'][0]
        : colourSettings['text colour']
    const stepColor = Array.isArray(colourSettings['step colour'])
        ? colourSettings['step colour'][0]
        : colourSettings['step colour']
    const backColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']

    return (
        <Wrapper>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <MainContainer
                isActive={isActive}
                isPreview={isPreview}
                width={width}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                fontFamily={fontFamily}
                fontSize={fontSize}
                weight={weight}
                align={align}
                backColor={backColor}
                textColor={textColor}
                stepColor={stepColor}
                onClick={onEditMode}
            >
                <StepperMUI activeStep={activeStep} orientation="vertical">
                    {widget?.settings?.steps?.map((step: IStep, index: number) => {
                        return (
                            <Step key={step.label}>
                                <StepLabel icon={stepIcon(step.image, index + 1)}>
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                    <Typography>{step.value}</Typography>
                                    <ButtonGroup
                                        index={index}
                                        fontFamily={fontFamily}
                                        textColor={textColor}
                                    >
                                        <Button
                                            variant="invert"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="invert"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            {activeStep === widget?.settings?.steps.length - 1
                                                ? 'Finish'
                                                : 'Next >'}
                                        </Button>
                                    </ButtonGroup>
                                </StepContent>
                            </Step>
                        )
                    })}
                </StepperMUI>
                {isPreview && <EditingIndicator widgetName={widgetName} />}
            </MainContainer>
        </Wrapper>
    )
}

export default Stepper

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const MainContainer = styled.div.attrs((props) => ({}))<{
    isActive: boolean
    isPreview: boolean
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    fontFamily: string
    fontSize: number
    weight: number
    backColor: string
    textColor: string
    stepColor: string
    align: string
}>`
    position: relative;
    cursor: pointer;

    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    & > .edit_mode {
        position: absolute;
        display: ${(props) => (props.isActive ? 'block' : 'none')};
        background: none;
        margin-left: ${(props) => props.paddingLeft};
        margin-top: ${(props) => props.paddingTop};
        margin-bottom: ${(props) => props.paddingBottom};
        margin-right: ${(props) => props.paddingRight};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: ${(props) => (props.isActive ? '1px solid rgb(58, 226, 155)' : 'none')};
    }

    &:hover {
        .hover_block {
            position: absolute;
            background: none;
            margin-left: ${(props) => props.paddingLeft};
            margin-top: ${(props) => props.paddingTop};
            margin-bottom: ${(props) => props.paddingBottom};
            margin-right: ${(props) => props.paddingRight};
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${(props) =>
                props.isPreview && !props.isActive && '1px solid rgb(58, 226, 155)'};
        }
    }

    .MuiTypography-body2,
    .MuiStepLabel-label.MuiStepLabel-active {
        font-size: ${(props) => (props.fontSize / 10) * 1.3 + 'em'};
        font-weight: ${(props) => props.weight};
        font-family: ${(props) => props.fontFamily};
        text-align: ${(props) => props.align};
        color: ${(props) => props.textColor};
    }

    .MuiTypography-body1 {
        font-family: ${(props) => props.fontFamily} !important;
        font-size: ${(props) => props.fontSize / 10 + 'em'};
        font-weight: ${(props) => props.weight};
        text-align: ${(props) => props.align};
        color: ${(props) => props.textColor};
    }

    .MuiPaper-root {
        background-color: ${(props) => props.backColor};
    }

    .MuiStepConnector-vertical,
    .MuiStepContent-root {
        margin-left: 20px;
    }

    .MuiSvgIcon-root {
        font-size: 2.5rem;
    }

    .MuiStepLabel-iconContainer {
        margin-right: 8px;
        border-radius: 100%;
        box-shadow: 0px 0px 10px 0px #000000;
        padding-right: 0;
    }

    .MuiStepIcon-root {
        color: ${(props) => props.stepColor};
    }

    .MuiStepContent-root {
        border-left: ${(props) => `1px solid ${props.stepColor}`};
    }

    .MuiStepConnector-line {
        border-color: ${(props) => props.stepColor};
    }
`
const ButtonGroup = styled.div<{
    index: number
    fontFamily: string
    textColor: string
}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > button:first-child {
        margin-right: 12px;
        opacity: ${(props) => (props.index === 0 ? 0 : 1)};
    }
    button {
        font-family: ${(props) => props.fontFamily};
        color: ${(props) => props.textColor};
    }
`
