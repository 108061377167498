import { atom, atomFamily, selector } from 'recoil'
import { getGameWidget } from '../../../utils/checkGameWidget'
import { campaignViewState } from '../../campaign/edit/campaignState'

export const gameListState = selector<any>({
    key: 'gameList',
    get: ({ get }) => {
        const views = get(campaignViewState)
        const gameWidgets = getGameWidget(views)
        return gameWidgets
    },
})

export const gameAttemptAvailable = atomFamily<boolean, string>({
    key: 'gameAttemptAvailable',
    default: false,
})

export const gameWidgetState = atomFamily<{ [key: string]: any }, string>({
    key: 'gameWidget',
    default: null,
})

export const gameRunningState = atom<boolean>({
    key: 'gameRunning',
    default: false,
})

export const rewardState = atomFamily<any, string>({
    key: 'reward',
    default: null,
})

export const gameErrors = atom<string>({
    key: 'gameErrors',
    default: '',
})
