import { Avatar, Button } from '@material-ui/core'
import React, { memo, MouseEvent, useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import vCardsJS from 'vcards-js'
import { QRCode } from 'react-qrcode-logo'
import ReactCardFlip from 'react-card-flip'

import { IWidget } from '../../../../models/Widget'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { EditingIndicator } from '../../EditingIndicator'
import {
    Container,
    TextContent,
    Username,
    UserRole,
    Wrapper,
    Back,
    QRButton,
    ShareButton,
    Logo,
} from './styles'
import { Marginer } from '../../../BrandSettings/styles'
import { ShareSocial } from './ShareSocial'
import { BurgerMenu } from './BurgerMenu'
import { SocialLinksBlock } from './SocialLinksBlock'
import { liveCampaignState } from '../../../../stores/campaign/live/state'
import { generatePass } from '../../../../lib/wallet/generatePass'
import { WalletButton } from './WalletButton'
import { Toast } from '../../../../helpers/Toast'

interface Props {
    widget?: IWidget
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
    isPreview?: boolean
}

const ProfileCard = ({
    widget,
    updateActiveWidget,
    index,
    parentIndex,
    isPreview = false,
}: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)
    const campaign = useRecoilValue(liveCampaignState)
    const [isFlipped, setFlipped] = useState(false)
    const [showSharringSocial, setShowSharringSocial] = useState(false)
    const [height, setHeight] = useState(0)
    const refComponent = useRef(null)

    useEffect(() => {
        setHeight(refComponent.current.clientHeight)
    }, [refComponent])

    const toggleFlipped = () => {
        setFlipped((prev) => !prev)
    }

    const isActive = ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e: MouseEvent<HTMLElement>) => {
        if (isPreview) {
            e.stopPropagation()
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    const socialLinks = widget?.settings?.socialUrls
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const isShadow = false

    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const fontSize = appearanceSettings?.font?.size
    const nameSize = fontSize * 2
    const fontWeight = appearanceSettings?.font?.weight
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url

    const backgroundImageUrl = appearanceSettings?.backgroundImageUrl
    const backgroundColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']
    const textColor = Array.isArray(colourSettings['text colour'])
        ? colourSettings['text colour'][0]
        : colourSettings['text colour']
    const iconColor = Array.isArray(colourSettings['icon colour'])
        ? colourSettings['icon colour'][0]
        : colourSettings['icon colour']
    const buttonColor = Array.isArray(colourSettings['button colour'])
        ? colourSettings['button colour'][0]
        : colourSettings['button colour']
    const buttonTextColor = Array.isArray(colourSettings['button text colour'])
        ? colourSettings['button text colour'][0]
        : colourSettings['button text colour']
    const shadowColor = Array.isArray(colourSettings['shadow colour'])
        ? colourSettings['shadow colour'][0]
        : colourSettings['shadow colour']
    const borderColour = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']

    const paddingTop = appearanceSettings?.paddingTop + 'px'
    const paddingBottom = appearanceSettings?.paddingBottom + 'px'
    const paddingLeft = appearanceSettings?.paddingLeft + 'px'
    const paddingRight = appearanceSettings?.paddingRight + 'px'
    const width = appearanceSettings?.width + '%'
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius + 'px'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius + 'px'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius + 'px'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius + 'px'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    const createCVF = () => {
        if (typeof window !== 'undefined' && widget) {
            let vCard = vCardsJS()
            for (const [name, value] of Object.entries(widget!.settings)) {
                if (name === 'photo' && value) {
                    // vCard.photo.attachFromUrl(value as string, 'JPEG')
                } else if (name === 'socialUrls' && widget?.settings?.socialUrls) {
                    for (const [key, link] of Object.entries(widget?.settings?.socialUrls)) {
                        if (link !== '' && typeof link === 'string') {
                            vCard.socialUrls[key] = link
                        }
                    }
                } else {
                    vCard[name] = value
                }
            }

            return vCard.getFormattedString()
        }
    }

    const download = (vCard: string | number | boolean) => {
        // build data url
        const url = 'data:text/vcard;charset=utf-8,' + encodeURIComponent(vCard)
        // ask the browser to download it
        document.location.href = url
    }

    const getVCFFile = () => {
        if (!isPreview) {
            download(createCVF())
        }
    }

    const onShowSharringSocial = () => {
        setShowSharringSocial((prev) => !prev)
    }

    const getPass = async () => {
        if (!isPreview) {
            const winRef = window.open('', '_blank')
            try {
                const pass = {
                    email: widget?.settings?.email,
                    firstName: widget?.settings?.firstName,
                    lastName: widget?.settings?.lastName,
                    mobile: widget?.settings?.workPhone,
                    profileImage: widget?.settings?.photo,
                    companyLink: window.location.href,
                }

                const passData: any = await generatePass(pass)
                    .then((data: any) => {
                        winRef.location = `https://www.pass2u.net/d/${data?.data?.passId}`
                    })
                    .catch((err) => {
                        Toast(err.message, 'error')
                    })
            } catch (error) {
                Toast(error.message, 'error')
            }
        } else {
            Toast('You cannot generate a pass in edit mode. Please deploy your page.', 'error')
        }
    }

    return (
        <Wrapper
            onClick={onEditMode}
            fontFamily={fontFamily}
            buttonColor={buttonColor}
            buttonTextColor={buttonTextColor}
            width={width}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <ReactCardFlip isFlipped={isFlipped}>
                <Container
                    id="card"
                    ref={refComponent}
                    className="CardFront"
                    width={width}
                    isPreview={isPreview}
                    isActive={isActive}
                    fontFamily={fontFamily}
                    paddingTop={paddingTop}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                    backgroundImage={`url(${backgroundImageUrl || ''})`}
                    backgroundColor={backgroundColor}
                    borderTopLeftRadius={borderTopLeftRadius}
                    borderTopRightRadius={borderTopRightRadius}
                    borderBottomLeftRadius={borderBottomLeftRadius}
                    borderBottomRightRadius={borderBottomRightRadius}
                    marginTop={marginTop}
                    marginBottom={marginBottom}
                    marginRight={marginRight}
                    marginLeft={marginLeft}
                    isShadow={isShadow}
                    shadowColor={shadowColor}
                    border={`${borderWidth} solid ${borderColour}`}
                >
                    <section>
                        <Avatar
                            src={widget?.settings?.photo}
                            style={{ width: '140px', height: '140px' }}
                        />
                    </section>
                    <Username
                        textColor={textColor}
                        fontSize={nameSize + 'px'}
                        fontWeight={fontWeight}
                        letterSpacing={letterSpacing}
                        lineHeight={lineHeight}
                    >
                        {widget?.settings?.firstName + ' ' + widget?.settings?.lastName}
                    </Username>
                    <UserRole
                        textColor={textColor}
                        fontSize={fontSize + 'px'}
                        fontWeight={fontWeight}
                        letterSpacing={letterSpacing}
                        lineHeight={lineHeight}
                    >
                        {widget?.settings?.title}
                    </UserRole>
                    <TextContent
                        letterSpacing={letterSpacing}
                        lineHeight={lineHeight}
                        textColor={textColor}
                        fontSize={fontSize + 'px'}
                    >
                        {widget?.settings?.note}
                    </TextContent>
                    <TextContent
                        letterSpacing={letterSpacing}
                        lineHeight={lineHeight}
                        textColor={textColor}
                        fontSize={fontSize + 'px'}
                    >
                        <a
                            style={{ color: buttonColor }}
                            href={`mailto:${widget?.settings?.email}`}
                        >
                            {widget?.settings?.email}
                        </a>
                        <a
                            style={{ color: buttonColor }}
                            href={`tel:${widget?.settings?.workPhone}`}
                        >
                            {widget?.settings?.workPhone}
                        </a>
                    </TextContent>
                    {widget?.addSocial && (
                        <SocialLinksBlock
                            socialLinks={socialLinks}
                            iconColor={iconColor}
                            textColor={textColor}
                            fontSize={fontSize + 'px'}
                        />
                    )}
                    <Marginer height="18px" />

                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={getVCFFile}
                    >
                        Save contact
                    </Button>
                    <WalletButton getPass={getPass} />

                    {/* remove feature of adding QR */}
                    {/* {widget?.addQR && (
                        <QRButton onClick={toggleFlipped} textColor={textColor}>
                            or scan QR
                        </QRButton>
                    )} */}

                    <BurgerMenu />
                    <ShareButton
                        showSharringSocial={showSharringSocial}
                        onClick={onShowSharringSocial}
                    >
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img width={30} height={30} src="/icons/share1.png" alt="share button" />
                    </ShareButton>
                    {showSharringSocial && (
                        <ShareSocial
                            campaign={campaign}
                            firstName={widget?.settings?.firstName}
                            lastName={widget?.settings?.lastName}
                            role={widget?.settings?.title}
                            description={widget?.settings?.note}
                            source={widget?.settings?.url}
                        />
                    )}
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <Logo src="/icons/builder/color_icon.svg" alt="deployable" />
                    {isPreview && <EditingIndicator widgetName={widgetName} />}
                </Container>
                <Container
                    onClick={toggleFlipped}
                    className="CardBack"
                    width="100%"
                    isActive={isActive}
                    isPreview={isPreview}
                    fontFamily={fontFamily}
                    borderTopLeftRadius={borderTopLeftRadius}
                    borderTopRightRadius={borderTopRightRadius}
                    borderBottomLeftRadius={borderBottomLeftRadius}
                    borderBottomRightRadius={borderBottomRightRadius}
                    isShadow={isShadow}
                    shadowColor={shadowColor}
                    border={`${borderWidth} solid ${borderColour}`}
                >
                    <Back id="QRmedia" height={height + 'px'}>
                        {widget?.addQR && (
                            <QRCode
                                value={createCVF()}
                                enableCORS={true}
                                size={1500}
                                quietZone={10}
                                bgColor="#fff"
                                fgColor="#000"
                                qrStyle="squares"
                            />
                        )}
                    </Back>
                </Container>
            </ReactCardFlip>
        </Wrapper>
    )
}

export default memo(ProfileCard)
