import React, { MouseEventHandler } from 'react'
import { useRecoilState } from 'recoil'

import DefaultButton from './DefaultButton'
import SubmitButton from './SubmitButton'
import { IWidget } from '../../../../models/Widget'
import { viewSectionState } from '../../../../stores/builder/viewSectionState'
import { activeWidgetState } from '../../../../stores/builder/activeWidget'
import StartGameButton from './StartGameButton'

interface Props {
    widget?: any
    isPreview?: boolean
    parentIndex?: number
    index?: number
    updateActiveWidget?: (index: number, parentIndex: number, widget: IWidget) => void
}

const Button = ({ widget, isPreview = false, updateActiveWidget, index, parentIndex }: Props) => {
    const [sectionState] = useRecoilState(viewSectionState)
    const [ativeWidget] = useRecoilState(activeWidgetState)

    const isActiveButton =
        ativeWidget?.widget?.id === widget?.id && sectionState === 'widgetEditSettings'

    const widgetName = widget?.name[0]?.toUpperCase() + widget?.name?.slice(1)

    const onEditMode = (e) => {
        e.stopPropagation()
        if (isPreview) {
            updateActiveWidget(index, parentIndex, widget)
        }
    }

    let appearanceSettings = widget.appearanceSettings
    if (!appearanceSettings) {
        appearanceSettings = {
            ...widget.settings,
        }
    }

    const type = widget.type
    const columns = appearanceSettings.columns
    const align = appearanceSettings.align
    const marginTop = appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings.marginRight / 10 + 'em'
    const buttonSpacing = appearanceSettings.buttonSpacing / 10 + 'em'
    const links = widget.settings.links

    return (
        <div
            className="card w-full flex relative"
            style={{
                justifyContent:
                    align === 'left' ? 'flex-start' : align === 'center' ? 'center' : 'flex-end',
                marginTop: marginTop,
                marginBottom: marginBottom,
                marginLeft: marginLeft,
                marginRight: marginRight,
            }}
        >
            {links?.length > 0 && (
                <div
                    className={`w-full grid grid-cols-${columns} items-center justify-items-center`}
                    tabIndex={0}
                    style={{
                        gap: buttonSpacing,
                        alignItems:
                            align === 'left'
                                ? 'flex-start'
                                : align === 'center'
                                ? 'center'
                                : 'flex-end',
                    }}
                >
                    {links.map((linkSettings, index) => {
                        if (isPreview) {
                            return (
                                <DefaultButton
                                    key={`button-${widget.id}-${index}`}
                                    widget={widget}
                                    isPreview={isPreview}
                                    action={linkSettings}
                                    widgetName={widgetName}
                                    onEditMode={onEditMode}
                                    isActiveButton={isActiveButton}
                                />
                            )
                        }
                        if (type === 'submit') {
                            return (
                                <SubmitButton
                                    key={`button-${widget.id}-${index}`}
                                    widget={widget}
                                    action={linkSettings}
                                    widgetName={widgetName}
                                    onEditMode={onEditMode}
                                    isActiveButton={isActiveButton}
                                    isPreview={isPreview}
                                />
                            )
                        } else if (type === 'gamebutton') {
                            return (
                                <StartGameButton
                                    key={`button-${widget.id}-${index}`}
                                    widget={widget}
                                    action={linkSettings}
                                    onEditMode={onEditMode}
                                    isActiveButton={isActiveButton}
                                    isPreview={isPreview}
                                />
                            )
                        } else {
                            return (
                                <DefaultButton
                                    key={`button-${widget.id}-${index}`}
                                    widget={widget}
                                    isPreview={isPreview}
                                    action={linkSettings}
                                    widgetName={widgetName}
                                    onEditMode={onEditMode}
                                    isActiveButton={isActiveButton}
                                />
                            )
                        }
                    })}
                </div>
            )}
        </div>
    )
}

export default Button
